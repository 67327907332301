import PropTypes from "prop-types";

const props = {
  text: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit"]),
  preset: PropTypes.oneOf([
    "blue",
    "lightBlue",
    "darkBlue",
    "lightGray",
    "white",
    "empty",
    "bordered",
  ]),
  size: PropTypes.oneOf(["default", "sm", "xs"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  orientation: PropTypes.oneOf(["textLeft", "textRight"]),
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  iconClassName: PropTypes.string,
  applySentenceCase: PropTypes.bool,
};

const defaults = {
  iconName: null,
  type: "button",
  preset: "blue",
  size: "default",
  onClick: undefined,
  disabled: false,
  orientation: "textRight",
  className: "",
  style: {},
  iconClassName: "w-7 h-7",
  applySentenceCase: true,
};

export default {
  props,
  defaults,
};
