// Note: need extra step of getting `tracker` because it's not available on the
// `ga` object by default when using Google Analytics via Google Tag Manager
// https://stackoverflow.com/questions/15744042/events-not-being-tracked-in-new-google-analytics-analytics-js-setup

const trackGAEvent = ({
  hitType = "event",
  eventCategory = "Downloads",
  eventAction = "DOCX",
  eventLabel,
  eventName = "download",
}) => {
  if (window.ga && window.ga.getAll && eventLabel) {
    const tracker = window.ga.getAll()[0];
    tracker.send({
      hitType,
      eventCategory,
      eventAction,
      eventLabel,
    });
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: eventName });
};

export default trackGAEvent;
