import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import SearchInput from "./SearchInput";
import { Select } from "@forms";
import { Text, Icon, Button } from "@ui";
import { t } from "@translations";

const SearchFilterBar = ({ showSearchIfTypeSearchable, initialTerm }) => {
  const [{ search }, dispatch] = useAppState();
  const { filters, options, view: _view, term } = search;
  const { filterOptions } = options;
  const { searchable, filters: typeFilters } = options[filters.type];

  const setFilters = (name, value) => {
    if (!filters[name].includes(value)) {
      dispatch({
        type: "setFilters",
        filters: {
          ...filters,
          [`${name}`]: value === "" ? [] : [value],
        },
      });
    }
  };

  // Get a list of filters that are turned on for the currently selected type
  const getFilterSelects = () => {
    // get a list of filters that are turned on for the current type
    // and also filter out type, because that's filtered using the main tabs
    const selects = Object.keys(typeFilters).filter(
      key => key !== "type" && key !== "corQuestion" && typeFilters[key]
    );

    return selects.map((key, idx) => ({
      key: idx,
      name: key,
      value: filters[key][0] || "",
      options: [
        {
          id: "",
          title: filterOptions[key].title,
        },
        ...filterOptions[key].items.map(({ id, title }) => ({
          id,
          title,
        })),
      ],
      onChange: event => setFilters(key, event.target.value),
    }));
  };

  const views = [t("cards"), t("list")];
  const changeView = view => {
    if (view !== _view) {
      dispatch({
        type: "changeView",
        view,
      });
    }
  };

  return (
    <div className="flex items-center pt-5 pb-3 border-b-2 border-gray-1">
      {showSearchIfTypeSearchable && searchable && (
        <>
          <SearchInput
            searchOnType="singles"
            initialTerm={term || initialTerm}
            activeClass="bg-gray-1"
            focusIfTerm
            className="text-gray mr-4"
            showSuggestions={false}
          />

          <Button
            preset="empty"
            text={t("clear")}
            className={classNames({
              "mr-4 transition": true,
              "opacity-0 pointer-events-none": !term,
              "opacity-100 pointer-events-auto": term,
            })}
            onClick={() => dispatch({ type: "clear" })}
          />
        </>
      )}

      <div className="inline-flex ml-auto items-center">
        {getFilterSelects().map(select => (
          <Select {...select} disabled={!!term} />
        ))}
      </div>
      <div className="hidden sm:inline-flex items-center">
        {views.map((view, idx) => (
          <button
            key={idx}
            type="button"
            className={classNames({
              "flex items-center outline-none focus:outline-none ml-4 h-10": true,
              "text-blue": view === _view,
              "text-gray": view !== _view,
            })}
            onClick={() => changeView(view)}
          >
            <Text
              preset="button"
              variant="default"
              tag="span"
              className="capitalize"
            >
              {view}
            </Text>
            <Icon name={view} className="w-4 h-4 ml-2" />
          </button>
        ))}
      </div>
    </div>
  );
};

SearchFilterBar.propTypes = {
  showSearchIfTypeSearchable: PropTypes.bool,
  initialTerm: PropTypes.string,
};

SearchFilterBar.defaultProps = {
  showSearchIfTypeSearchable: true,
  initialTerm: "",
};

export default SearchFilterBar;
