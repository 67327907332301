export { default as useHomePageData } from "./HomePageQuery";
export { default as useHomeIntroData } from "./HomeIntroQuery";
export { default as useHomeCurriculumData } from "./HomeCurriculumQuery";
export { default as useHomeTestimonialData } from "./HomeTestimonialQuery";
export { default as useHomeNewsData } from "./HomeNewsQuery";
export { default as useHomeBlogData } from "./HomeBlogQuery";
export { default as useHomeResearchData } from "./HomeResearchQuery";
export { default as useFooterData } from "./FooterQuery";
export { default as useCurriculumIndexData } from "./CurriculumIndexQuery";
export { default as useAllCurriculumData } from "./AllCurriculumQuery";
export { default as useRegisterCTAData } from "./RegisterCTAQuery";
export { default as useAllCollectionsData } from "./AllCollectionsQuery";
export { default as useAuthFormsData } from "./AuthFormsQuery";
export { default as useAnnouncementData } from "./AnnouncementQuery";
export { default as useHeaderData } from "./HeaderQuery";
export { default as useBlogIndexData } from "./BlogIndexQuery";
export { default as useAllBlogPostsData } from "./AllBlogPostsQuery";
export { default as useMetaData } from "./MetaDataQuery";
export { default as useNewsIndexData } from "./NewsIndexQuery";
export { default as useAllNewsArticlesData } from "./AllNewsArticlesQuery";
export { default as useVideoIndexData } from "./VideoIndexQuery";
export { default as useAllVideosData } from "./AllVideosQuery";
export { default as useCurriculumSortData } from "./CurriculumSortQuery";
export { default as useResearchIndexData } from "./ResearchIndexQuery";
export {
  default as useAllResearchArticlesData,
} from "./AllResearchArticlesQuery";
export { default as use404PageData } from "./404PageQuery";
export { default as useContactPageData } from "./ContactPageQuery";
export { default as useAboutPageData } from "./AboutPageQuery";
export { default as useAllContentTopicData } from "./AllContentTopicsQuery";
export { default as useDownloadsData } from "./DownloadsQuery";
export { default as useVideosPopoutData } from "./VideosPopoutQuery";
export { default as usePosterData } from "./PosterQuery";
