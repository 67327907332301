import React from "react";
import { useAppState } from "@state/state";
import classNames from "classnames";
import { Icon, Text, AuthButton } from "@ui";
import { useRegisterCTAData } from "@staticQueries";

const RegisterCTA = () => {
  const [{ user, search }] = useAppState();
  const { view } = search;
  const { registerPrompt, registerCtaText } = useRegisterCTAData();

  if (user.loggedIn) {
    return null;
  }

  return (
    <div
      className={classNames({
        "w-full my-4": view === "cards",
        "relative md:pl-6 md:w-1/3": view === "list",
      })}
    >
      <div
        className={classNames({
          "bg-white shadow-card": true,
          "flex items-center rounded-full p-4": view === "cards",
          "text-center sticky top-6 my-3 p-6 lg:px-12 lg:py-8": view === "list",
        })}
      >
        <Icon
          className={classNames("text-red-dig p-1", {
            "w-10 h-10 mr-4": view === "cards",
            "w-16 h-16 mb-4": view === "list",
          })}
          name="DIG"
        />
        <Text
          className={classNames({
            "font-sans font-bold": true,
            "flex-1 pr-4": view === "cards",
            "mb-4": view === "list",
          })}
          preset="p"
        >
          {registerPrompt}
        </Text>
        <AuthButton
          preset="blue"
          text={registerCtaText}
          modalName="signInModal"
        />
      </div>
    </div>
  );
};

export default RegisterCTA;
