import React from "react";
import classNames from "classnames";
import imageProps from "./propTypes";

// Low Quality Image Placeholder

const ImageLQIP = ({ image }) => {
  const { url, fx, fy, filter } = image;

  let src = url;
  src += "?w=50&h=50";
  if (filter) {
    const filterStr = encodeURIComponent(`${filter},FFFFFF`);
    src += `&duotone=${filterStr}&duotone-alpha=100`;
  }

  const positionX = fx ? fx.toLowerCase() : "center";
  const positionY = fy ? fy.toLowerCase() : "center";

  const lqipClassName = classNames({
    "absolute inset-0 z-10 -m-8 bg-cover transition-opacity transition-750": true,
  });

  return (
    <div
      style={{
        backgroundImage: `url(${src})`,
        filter: filter ? "none" : "blur(25px)",
        backgroundSize: "cover",
        backgroundPosition: `${positionX} ${positionY}`,
      }}
      className={lqipClassName}
    />
  );
};

ImageLQIP.propTypes = {
  image: imageProps.props.isRequired,
};

export default ImageLQIP;
