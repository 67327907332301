import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { isIE } from "react-device-detect";
import { useAppState } from "@state/state";
import useWindowSize from "@designbycosmic/cosmic-react-resize-hook";
import { Icon } from "@ui";
import ModalHeader from "./ModalHeader";
import ModalInner from "./ModalInner";

const Modal = () => {
  const [maxHeight, setMaxHeight] = useState(0);
  const [{ modal, breakpoints }, dispatch] = useAppState();
  const { name, tabs, context, showing } = modal;
  const { sm } = breakpoints;

  const closeModal = () => {
    dispatch({ type: "closeModal" });
    setTimeout(() => {
      dispatch({ type: "clearModal" });
    }, 300);
  };

  // TODO: This isn't working. Can't get `keydown` events to fire.
  // `keypress` events fire, but not for buttons on Mac touch bars
  const handleKeyDown = event => {
    if (showing && event.key === "ESC") {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return window.removeEventListener("keydown", handleKeyDown);
  }, []);

  // Need to set innerHeight to local state to trigger
  // re-render after hydration
  const { innerHeight } = useWindowSize();
  useEffect(() => {
    setMaxHeight(sm ? innerHeight - 72 : innerHeight);
  }, [innerHeight, sm]);

  let closeTransform;
  if (sm) {
    closeTransform = "translate(50%, -50%)";
  } else if (name === "videoEmbed") {
    closeTransform = "translate(-20%, -120%)";
  } else {
    closeTransform = "translate(-45%, 50%)";
  }

  return (
    <div
      className={classNames({
        "fixed inset-0 flex items-center justify-center transition-fast z-super-max": true,
        "opacity-0 pointer-events-none": !showing,
        "opacity-100 pointer-events-auto": showing,
      })}
    >
      {/* Darkened, clickable screen behind modal */}
      <div
        role="button"
        tabIndex={0}
        className="absolute inset-0 outline-none focus:outline-none bg-gray opacity-50 z-10 transition-fast"
        onClick={closeModal}
        onKeyDown={handleKeyDown}
        aria-label="Close modal"
      />

      {/* Modal Frame with Close X Icon */}
      <div className="relative w-full sm:max-w-sm md:max-w-md lg:max-w-lg z-20">
        <div
          className={classNames({
            "flex flex-col bg-white": true,
            "sm:rounded-lg": name !== "videoEmbed",
            "scale-90 transition-fast": !showing,
            "scale-100": showing,
          })}
          style={{
            height: isIE && name === "registerModal" ? maxHeight : "auto",
            maxHeight,
          }}
        >
          <button
            type="button"
            className="absolute top-0 right-0 w-10 h-10 sm:w-12 sm:h-12 z-20 outline-none focus:outline-none"
            style={{ transform: closeTransform }}
            onClick={closeModal}
          >
            <Icon
              name="close"
              className="w-full h-full text-white hover:text-gray-1 transition"
            />
          </button>

          {/* Show Modal Header if we have tabs */}
          {tabs.length > 0 && <ModalHeader />}

          {/* Load the modal inner content based on passed name prop */}
          <div
            id="ModalOverflowContainer"
            className={classNames({
              "flex-auto overflow-scroll scrolling-touch": true,
              "px-6 pt-6": tabs.length > 0,
            })}
          >
            <ModalInner modalName={name} modalContext={context} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Modal);
