// Note: no longer using the helper function below because I was getting
// massive performance issues, sometime crashing the browser.
// import { mergePrismicPreviewData } from "gatsby-source-prismic";

const hasWindow = typeof window !== "undefined";

const mergePreviewData = staticData => {
  const { __PRISMIC_PREVIEW_DATA__: previewData } = hasWindow ? window : {};

  // set up an array to capture any errors
  const errors = [];

  // Try merging the previewData, but if we get an error, log it to
  // the previewData on the window so we can display in the PreviewModeBug

  // Note: I'm doing a simple top-level merge of the previewData onto staticData.
  // I was having persistent, varied issues with the `mergePrismicPreviewData` function
  try {
    const staticKeys = Object.keys(staticData);
    const previewKey = previewData ? Object.keys(previewData)[0] : null;
    if (previewData && staticKeys.includes(previewKey)) {
      return {
        ...staticData,
        [previewKey]: previewData[previewKey],
      };
    }

    return staticData;
  } catch (error) {
    // First key of staticData will be the graphQL name of the
    // type we're unable to merge into, e.g, `prismicCollections`
    errors.push(Object.keys(staticData)[0]);
    previewData.errors = errors;
    return staticData;
  }
};

export default mergePreviewData;
