import { useAppState } from "@state/state";
import { getSearcher } from "@state/reducers/search";
import stopList from "./stopList";
import {
  useAllCurriculumData,
  useCurriculumIndexData,
  useCurriculumSortData,
} from "@staticQueries";
import { getDupes } from "@utils";
import { t } from "@translations";

// SearchConfig: this component returns nothing, and exists solely as a place to
// register a hook for getting all curriculum data and using it to init a
// client-side searcher

const hasWindow = typeof window !== "undefined";

const SearchConfig = () => {
  const [{ search }, dispatch] = useAppState();

  // if the search is null, then we need to create it and seed
  // it with the curriculum data
  if (hasWindow && search.searcher === null) {
    const { content } = useAllCurriculumData();
    const { sortOrder, collectionSortOrder } = useCurriculumSortData();

    // log any duplicate L&As (it's a very long list; mistakes happen)
    const dupes = getDupes(sortOrder.map(item => `${item.type}_${item.uid}`));
    if (dupes.length > 0) {
      // eslint-disable-next-line
      console.log(
        [
          "Duplicate entries in master sort!",
          ...dupes,
        ].join("\n")
      );
    }

    // get sort of all lessons and assessments
    const sortedMaterials = sortOrder.map(({ uid, type }) =>
      content.find(item => item.uid === uid && item.type === type)
    );

    // get sort of all collections
    const sortedCollections = collectionSortOrder.map(({ uid }) =>
      content.find(item => item.uid === uid && item.type === "collections")
    );

    // log any L&As that are missing from the master sort
    const missing = content.reduce((acc, cur) => {
      if (
        cur.type !== "collections" &&
        sortedMaterials.findIndex(
          item => item.uid === cur.uid && item.type === cur.type
        ) === -1
      ) {
        acc.push(cur);
      }
      if (
        cur.type === "collections" &&
        sortedCollections.findIndex(
          item => item.uid === cur.uid && item.type === cur.type
        ) === -1
      ) {
        acc.push(cur);
      }
      return acc;
    }, []);
    if (missing.length > 0) {
      // eslint-disable-next-line
      console.log(
        [
          "Missing entries in master sort!",
          ...missing.map(item => `${item.type} - ${item.title}`),
        ].join("\n")
      );
    }

    // create searchable index of the sorted materials and collections
    const index = [...sortedMaterials, ...sortedCollections].map(
      ({
        id,
        type,
        title,
        searchKeyWords,
        description = {},
        body = [],
        ...rest
      }) => ({
        id,
        type,
        title,
        description: description.text,
        text: body.reduce((acc, cur) => {
          if (cur.sliceType !== "copy") {
            return acc;
          }
          return `${acc} ${cur.text}`;
        }, ""),
        keyWords: searchKeyWords || "",
        ...rest,
      })
    );

    const searcher = getSearcher(index, stopList);

    // Get the user-defined filter settings, which includes the proper
    // order of the corQuestions filters.
    const { filter } = useCurriculumIndexData();
    const {
      lessonsAssessments,
      collections,
      corQuestions,
      disciplines,
    } = filter;

    const options = {
      singles: {
        searchable: lessonsAssessments[0].searchable === "Yes",
        filters: {
          type: ["lessons", "assessments"],
          corQuestion: lessonsAssessments[0].filterCorQuestion === "Yes",
          disciplines: lessonsAssessments[0].filterDisciplines === "Yes",
        },
      },
      lessons: {
        searchable: lessonsAssessments[0].searchable === "Yes",
        filters: {
          type: ["lessons"],
          corQuestion: lessonsAssessments[0].filterCorQuestion === "Yes",
          disciplines: lessonsAssessments[0].filterDisciplines === "Yes",
        },
      },
      assessments: {
        searchable: lessonsAssessments[0].searchable === "Yes",
        filters: {
          type: ["assessments"],
          corQuestion: lessonsAssessments[0].filterCorQuestion === "Yes",
          disciplines: lessonsAssessments[0].filterDisciplines === "Yes",
        },
      },
      collections: {
        searchable: collections[0].searchable === "Yes",
        filters: {
          type: ["collections"],
          corQuestion: collections[0].filterCorQuestion === "Yes",
          disciplines: collections[0].filterDisciplines === "Yes",
        },
      },
      filterOptions: {
        corQuestion: {
          title: t("COR question"),
          items: corQuestions,
        },
        disciplines: {
          title: t("Discipline"),
          items: disciplines.map(({ id, name }) => ({
            id,
            title: name,
          })),
        },
      },
    };

    dispatch({
      type: "initSearch",
      searcher,
      content: index,
      results: index,
      options,
    });
  }

  return null;
};

export default SearchConfig;
