import resolveImageData from "./resolveImageData";
import resolveEmbeddedDocument from "./resolveEmbeddedDocument";

const resolveEmbeddedVideo = video => {
  if (!video || !video.document) {
    return null;
  }

  const { thumbnail, ...rest } = resolveEmbeddedDocument(video);
  const { image = {} } = thumbnail[0] || {};

  return {
    ...rest,
    thumbnail: image.url ? resolveImageData(thumbnail[0]) : null,
  };
};

export default resolveEmbeddedVideo;
