import React, { useState } from "react";
import axios from "axios";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { useFooterData } from "@staticQueries";
import Input from "./Input";
import { Button } from "@ui";
import { t } from "@translations";

// NewsletterForm -- submit name/email to Mailchimp list

const NewsletterForm = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    listName: "newsletter",
  });
  const [, dispatch] = useAppState();

  const {
    inputPlaceholder,
    inputLabel,
    errorMessage,
    successMessage,
  } = useFooterData();

  const clearForm = () => setFormData({ email: "", listName: "newsletter" });

  const handleSuccess = () => {
    setLoading(false);
    clearForm();
    dispatch({
      type: "openMessage",
      copy: successMessage,
      bgColorClass: "bg-green",
    });
  };

  const handleError = error => {
    let { html } = errorMessage;
    if (error && error.response && error.response.data) {
      html = `
        ${html}
        <p>${error.response.data.message}</p>
      `;
    }

    setLoading(false);
    dispatch({
      type: "openMessage",
      copy: { html },
      bgColorClass: "bg-red",
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    axios
      .post("/.netlify/functions/mailchimp-subscribe", formData)
      .then(handleSuccess)
      .catch(handleError);
  };

  return (
    <form
      className={classNames({
        "flex items-end": true,
        "opacity-50": loading,
        "opacity-100": !loading,
      })}
      onSubmit={handleSubmit}
    >
      <Input
        name="email"
        label={inputLabel}
        placeholder={inputPlaceholder}
        value={formData.email}
        onChange={event =>
          setFormData({ ...formData, email: event.target.value })
        }
        className="flex-auto"
      />

      <Button
        type="submit"
        text={t("submit")}
        preset="white"
        className="ml-2"
      />
    </form>
  );
};

export default NewsletterForm;
