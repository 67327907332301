import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { useAppState } from "@state/state";

const hasWindow = typeof window !== "undefined";

const AppLink = ({ id, to, target, className, children, style, onClick }) => {
  const [{ layout, messages }, dispatch] = useAppState();

  const handleClick = () => {
    if (layout.showingMobileNav) {
      dispatch({ type: "setMobileNav", showingMobileNav: false });
    }

    messages.messages.forEach(({ id: messageId, onClose }) => {
      dispatch({
        type: "closeMessage",
        idx: messages.messages.findIndex(message => message.id === messageId),
      });

      if (onClose) {
        onClose();
      }

      setTimeout(() => {
        dispatch({
          type: "removeMessage",
          idx: messages.messages.findIndex(message => message.id === messageId),
        });
      }, 667);
    });

    if (onClick) {
      onClick();
    }
  };

  // use regular anchor if target is _blank, or url includes protocol, i.e,
  // it's an absolute path
  if (
    target === "_blank" ||
    to.includes("http://") ||
    to.includes("https://")
  ) {
    return (
      <a
        id={id}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        style={style}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      id={id}
      className={className}
      style={style}
      to={to}
      state={{ prevPath: hasWindow ? window.location.pathname : undefined }}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

AppLink.defaultProps = {
  id: undefined,
  className: "",
  style: {},
  target: "_self",
  onClick: null,
};

AppLink.propTypes = {
  id: PropTypes.string,
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  onClick: PropTypes.func,
};

export default AppLink;
