import { useStaticQuery, graphql } from "gatsby";
import mergePrismicPreview from "../mergePrismicPreview";
import { keysToCamel } from "@utils";

const query = graphql`
  query FooterQuery {
    prismicFooter {
      data {
        menu {
          link {
            url
            target
          }
          title
        }
        input_placeholder
        input_label
        cta_text
        cta_link {
          url
          target
        }
        newsletter_prompt {
          html
        }
        donation_prompt {
          html
        }
        success_message {
          html
        }
        error_message {
          html
        }
        legal_links {
          link_text
          link {
            url
            target
          }
        }
      }
    }
    prismicContactPage {
      data {
        info_social_media {
          platform
          link {
            url
            target
          }
        }
        info_email_address
      }
    }
  }
`;

const dataResolver = response => {
  const { prismicFooter, prismicContactPage } = response;
  const { menu, legal_links, ...rest } = prismicFooter.data;
  const { info_social_media, info_email_address } = prismicContactPage.data;

  return {
    ...keysToCamel(rest, { stripTrailingDigit: false }),
    menu: menu.map(({ title, link }, idx) => ({
      id: idx,
      title,
      url: link.url,
      target: link.target,
    })),
    emailAddress: info_email_address,
    socialMedia: info_social_media.map(({ platform, link }, idx) => ({
      id: idx,
      platform,
      url: link.url,
      target: link.target,
    })),
    legal: legal_links.map(({ link_text, link }, idx) => {
      return {
        id: idx,
        title: link_text,
        url: link.url,
        target: link.target,
      };
    }),
  };
};

const useFooterData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useFooterData;
