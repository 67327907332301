import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import classNames from "classnames";
import curriculumProps from "./propTypes";
import CurriculumCard from "./CurriculumCard";
import CurriculumListItem from "./CurriculumListItem";

const CurriculumGroup = ({ items, typeOverride, collectionUid }) => {
  const [{ search }] = useAppState();
  const { filters, view } = search;

  const Item = view === "cards" ? CurriculumCard : CurriculumListItem;

  const type = typeOverride || filters.type;

  return (
    <>
      {items.map(
        ({
          id,
          uid,
          title,
          type: _type,
          illustration,
          summary,
          tags,
          color,
        }) => (
          <Item
            key={id}
            uid={uid}
            title={title}
            type={_type}
            illustration={illustration}
            summary={summary}
            tags={tags}
            color={color}
            className={classNames({
              "w-full sm:w-1/2 md:w-1/3 lg:w-1/4":
                view === "cards" &&
                (type === "singles" ||
                  type === "lessons" ||
                  type === "assessments"),
              "w-full sm:w-1/2 md:w-1/3":
                view === "cards" && type === "collections",
            })}
            collectionUid={collectionUid}
            enableCardSelect
          />
        )
      )}
    </>
  );
};

CurriculumGroup.propTypes = {
  items: PropTypes.arrayOf(curriculumProps.props).isRequired,
  typeOverride: PropTypes.oneOf(["singles", "collections"]),
  collectionUid: PropTypes.string,
};

CurriculumGroup.defaultProps = {
  typeOverride: null,
  collectionUid: null,
};

export default CurriculumGroup;
