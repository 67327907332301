import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import resolveImageData from "../dataResolvers/resolveImageData";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query ContactPageQuery {
    prismicContactPage {
      data {
        meta_title
        meta_description
        meta_image {
          filter
          focal_point_x
          focal_point_y
          image {
            alt
            url
            dimensions {
              height
              width
            }
          }
        }
        hero_heading
        hero_description {
          html
        }
        info_heading
        info_email_address
        info_location
        info_social_media {
          platform
          link {
            url
            target
          }
        }
        press_heading
        press_description {
          html
        }
        press_cta_text
        press_cta_link {
          url
          target
        }
      }
    }
  }
`;

const dataResolver = ({ prismicContactPage }) => {
  const prefixes = ["meta_", "hero_", "info_", "press_"];

  const { prefixed, unPrefixed } = resolveByPrefix(
    prismicContactPage.data,
    prefixes
  );

  const { meta } = prefixed;

  const resolved = {
    ...prefixed,
    ...unPrefixed,
    meta: {
      ...meta,
      image: resolveImageData(meta.image)[0],
    },
  };

  return keysToCamel(resolved);
};

const useContactPageData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useContactPageData;
