import MiniSearch from "minisearch";

const initialFilters = {
  type: "singles",
  corQuestion: [],
  disciplines: [],
};

const initialState = {
  content: [],
  searcher: null,
  filters: initialFilters,
  page: 0,
  term: "",
  results: [],
  view: "cards",
  options: {
    singles: {
      searchable: true,
      filters: {
        type: ["lessons", "assessments"],
        corQuestion: true,
        disciplines: false,
      },
    },
    lessons: {
      searchable: true,
      filters: {
        type: ["lessons"],
        corQuestion: true,
        disciplines: false,
      },
    },
    assessments: {
      searchable: true,
      filters: {
        type: ["assessments"],
        corQuestion: true,
        disciplines: false,
      },
    },
    collections: {
      searchable: false,
      filters: {
        type: ["collections"],
        corQuestion: false,
        disciplines: false,
      },
    },
    filterOptions: {
      corQuestion: {
        title: "COR question",
        items: [],
      },
      disciplines: {
        title: "Discipline",
        items: [],
      },
    },
  },
};

export const getSearcher = (data, stopList) => {
  const searcher = new MiniSearch({
    fields: ["type", "keyWords", "title", "description", "text"],
    storeFields: Object.keys(initialFilters),
    searchOptions: {
      prefix: true,
      fuzzy: 0.25,
      boost: { type: 4, keyWords: 2 },
      processTerm: term =>
        stopList.includes(term) ? null : term.toLowerCase(),
    },
  });

  searcher.addAll(data);

  return searcher;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "initSearch":
      return {
        ...state,
        searcher: action.searcher,
        content: action.content,
        results: action.content,
        options: action.options,
      };
    case "setSearchResults":
      return {
        ...state,
        results: action.results,
        term: action.newTerm,
        page: 0,
        filters: {
          ...initialFilters,
        },
      };

    case "setFilters":
      return {
        ...state,
        filters: action.filters,
        page: 0,
      };

    case "incrementPage": {
      return {
        ...state,
        page: state.page + 1,
      };
    }

    case "changeView": {
      return {
        ...state,
        view: action.view,
      };
    }

    case "clear":
      return {
        ...state,
        page: 0,
        term: "",
        results: state.content,
      };

    case "clearSearchAndFilters":
      return {
        ...state,
        page: 0,
        term: "",
        results: state.content,
        filters: {
          ...Object.keys(state.filters).reduce(
            (acc, cur) => ({
              ...acc,
              [cur]: [],
            }),
            {}
          ),
          type: state.filters.type,
        },
      };

    default:
      return state;
  }
};

export default {
  reducer,
  initialState,
  getSearcher,
};
