import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Text, Icon, Section, Container } from "@ui";

const SearchCorQuestionFilter = ({ prompt }) => {
  const [{ search }, dispatch] = useAppState();
  const { filters, options, term } = search;
  const { filterOptions } = options;

  const [showing, setShowing] = useState(false);

  const setFilters = value => {
    const corQuestion = filters.corQuestion.includes(value) ? [] : [value];
    dispatch({
      type: "setFilters",
      filters: {
        ...filters,
        corQuestion,
      },
    });
  };

  useEffect(() => {
    if (term) {
      setShowing(true);
    } else {
      setShowing(false);
    }
  }, [term]);

  if (term) {
    return null;
  }

  return (
    <Section
      size="sm"
      hasPaddingBottom={false}
      className="transition"
      style={{ opacity: showing ? 1 : 0 }}
    >
      <Container className="p-4 pt-6 sm:px-6 sm:py-8 bg-gray-1 rounded-lg">
        <Text preset="p" tag="h3" className="mb-3 md:mb-6 text-center">
          {prompt}
        </Text>

        <div className="flex flex-col md:flex-row md:items-center md:-mx-2">
          {filterOptions.corQuestion.items.map(({ id, title, color }, idx) => (
            <div key={id} className="md:flex-1 py-1 md:py-0 md:px-2">
              <button
                type="button"
                className={classNames({
                  "w-full outline-none focus:outline-none": true,
                  "flex items-center p-2 md:p-3 border-t-4 shadow-sm hover:scale-103 transition-fast": true,
                  [`text-gray border-${color.toLowerCase()}`]: true,
                  "bg-white": filters.corQuestion !== id,
                  [`bg-${color.toLowerCase()}-2 border-${color.toLowerCase()}-4 scale-103`]: filters.corQuestion.includes(
                    id
                  ),
                })}
                onClick={() => setFilters(id)}
              >
                <Text
                  preset="button"
                  tag="span"
                  variant="default"
                  className="mr-2"
                >
                  {`${idx + 1}. ${title}`}
                </Text>

                <Icon
                  name={`${color}Accent`}
                  className={`w-8 h-6 ml-auto text-${color}`}
                />
              </button>
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

SearchCorQuestionFilter.propTypes = {
  prompt: PropTypes.string.isRequired,
};

export default SearchCorQuestionFilter;
