import resolveImageData from "./resolveImageData";
import resolveEmbeddedVideo from "./resolveEmbeddedVideo";
import { getTailwindUtility } from "@utils";
import { t } from "@translations";

const colors = getTailwindUtility("colors");

function resolveVideoSliceProps({ cta_text, video: _video }) {
  const video = resolveEmbeddedVideo(_video);
  const { title, description, youtube_video_id, thumbnail = {} } = video || {};

  return {
    title,
    description,
    videoThumbnail: {
      youtube_video_id,
      thumbnail: {
        ...thumbnail,
        filter: colors.blue["3"].replace("#", ""),
      },
    },
    buttons: [
      {
        id: "1",
        text: cta_text || t("watch the video"),
        iconName: "videoPlay",
      },
    ],
  };
}

function resolveEmbeddedMaterial({ url, document }) {
  if (!url || !document) {
    return null;
  }

  const { illustration, cor_question } = document.data;

  return {
    links: [
      {
        id: 1,
        url,
        target: "_self",
        text: t("learn more"),
      },
    ],
    illustration: {
      text: illustration.text,
      bgColor: cor_question ? cor_question.document.data.color : "blue",
    },
  };
}

const resolveSlices = body => {
  const resolved = body
    .filter(block => block.primary || block.items)
    .map(block => {
      const { id, slice_type, primary, items } = block;
      switch (slice_type) {
        case "copy":
          return {
            id,
            slice_type,
            html: primary.rich_text.html,
            text: primary.rich_text.text,
          };
        case "large_copy":
          return {
            id,
            slice_type,
            html: primary.rich_text.html,
          };
        case "images":
          return {
            id,
            slice_type,
            images: resolveImageData(items),
          };
        case "video":
          return {
            id,
            slice_type,
            ...resolveVideoSliceProps(primary),
          };
        case "parallel_assessment_cta":
          return {
            id,
            slice_type,
            title: primary.title,
            description: primary.description,
            iconName: "document",
            orientation: "textRight",
            links: [
              {
                ...primary.assessment_link,
                id: 1,
                text: t("view the assessment"),
              },
              {
                ...primary.rubric_link,
                id: 2,
                text: t("view rubric"),
              },
            ],
          };
        case "testimonial":
          return {
            id,
            slice_type,
            text: primary.text,
            person: primary.person,
            role: primary.role,
            photo: {
              url: primary.photo.url,
              alt: primary.photo.alt,
              ...primary.photo.dimensions,
            },
          };
        case "curriculum_cta":
          return {
            id,
            slice_type,
            orientation: "textLeft",
            title: primary.heading,
            description: primary.description,
            ...resolveEmbeddedMaterial(primary.material || {}),
          };
        case "general_cta":
          return {
            id,
            slice_type,
            ...primary,
          };
        case "video_embed":
          return {
            id,
            slice_type,
            ...(primary.video &&
              primary.video.document &&
              primary.video.document.data),
          };
        case "title_byline":
          return {
            id,
            slice_type,
            ...primary,
          };
        case "decorative_image":
          return {
            id,
            slice_type,
            image: resolveImageData({ image: primary.image }),
          };

        default:
          return null;
      }
    });

  return resolved;
};

export default resolveSlices;
