import { useStaticQuery, graphql } from "gatsby";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query CurriculumSortQuery {
    prismicCurriculumIndex {
      data {
        sort_order {
          item {
            uid
            type
          }
        }
        collection_sort_order {
          item {
            uid
            type
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicCurriculumIndex }) => {
  const { sort_order, collection_sort_order } = prismicCurriculumIndex.data;

  return {
    sortOrder: sort_order
      .filter(item => item.item.uid)
      .map(item => ({
        uid: item.item.uid,
        type: item.item.type,
      })),
    collectionSortOrder: collection_sort_order
      .filter(item => item.item.uid)
      .map(item => ({
        uid: item.item.uid,
        type: item.item.type,
      })),
  };
};

const useCurriculumSortData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useCurriculumSortData;
