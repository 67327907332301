const initialMessageState = {
  id: "",
  copy: undefined,
  bgColorClass: undefined,
  ctaLink: undefined,
  ctaText: undefined,
  showing: false,
  timeout: 0,
  onClose: undefined,
  height: 0,
  isStatic: false,
};

const initialState = {
  messages: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "openMessage":
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            ...initialMessageState,
            id: new Date().getTime(),
            copy: action.copy,
            bgColorClass: action.bgColorClass,
            ctaLink: action.ctaLink,
            ctaText: action.ctaText,
            timeout: action.timeout || 0,
            onClose: action.onClose,
            showing: true,
            isStatic: !!action.isStatic,
          },
        ],
      };
    case "setMessageHeight":
      return {
        ...state,
        messages: [
          ...state.messages.slice(0, action.idx),
          {
            ...state.messages[action.idx],
            height: action.height,
          },
          ...state.messages.slice(action.idx + 1),
        ],
      };
    case "closeMessage":
      return {
        ...state,
        messages: [
          ...state.messages.slice(0, action.idx),
          {
            ...state.messages[action.idx],
            showing: false,
          },
          ...state.messages.slice(action.idx + 1),
        ],
      };
    case "removeMessage":
      return {
        ...state,
        messages: [
          ...state.messages.slice(0, action.idx),
          ...state.messages.slice(action.idx + 1),
        ],
      };

    default:
      return state;
  }
};

export default {
  reducer,
  initialState,
};
