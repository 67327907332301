const reducer = (state, action) => {
  switch (action.type) {
    case "setBreakpoints":
      return {
        ...state,
        ...action.breakpoints,
      };

    default:
      return state;
  }
};

const initialState = {
  sm: null,
  md: null,
  lg: null,
  current: null,
};

export default {
  reducer,
  initialState,
};
