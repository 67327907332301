import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveImageData from "../dataResolvers/resolveImageData";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import resolveEmbeddedDocument from "../dataResolvers/resolveEmbeddedDocument";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query HomeNewsArticlesQuery {
    prismicHomePage {
      data {
        news_heading
        news_description {
          html
        }
        news_cta_text
        news_cta_link {
          url
          target
        }
        news_featured_articles {
          article {
            document {
              ... on PrismicNewsArticles {
                uid
                data {
                  title
                  display_date(formatString: "MMM DD, YYYY")
                  image {
                    url
                    dimensions {
                      height
                      width
                    }
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicHomePage }) => {
  const { news } = resolveByPrefix(prismicHomePage.data, ["news_"]).prefixed;
  const { featured_articles } = news;
  const articles = resolveEmbeddedDocument(featured_articles);

  const resolved = {
    ...news,
    posts: articles.slice(0, 3).map(article => {
      const { image, display_date } = article;
      return {
        ...article,
        date: display_date,
        image: resolveImageData({ image }),
      };
    }),
  };

  return keysToCamel(resolved);
};

const useHomeNewsData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useHomeNewsData;
