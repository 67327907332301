import { useEffect } from "react";
import { useAppState } from "@state/state";
import useBreakpoints from "@hooks/useBreakpoints";

// LayoutUpdater: this component returns nothing, and exists solely as a place to
// register hooks for scrolling and window resize listeners

const hasWindow = typeof window !== "undefined";

const LayoutUpdater = () => {
  const [{ layout, breakpoints: _breakpoints }, dispatch] = useAppState();
  const breakpoints = hasWindow ? useBreakpoints() : _breakpoints;

  useEffect(() => {
    dispatch({
      type: "setBreakpoints",
      breakpoints,
    });
  }, Object.values(breakpoints));

  useEffect(() => {
    if (!layout.rendered) {
      dispatch({
        type: "setRendered",
        rendered: true,
      });
    }
  }, []);

  return null;
};

export default LayoutUpdater;
