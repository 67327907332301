import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import { Text, RichText, Button, Carousel, Container } from "@ui";
import CurriculumCard from "./CurriculumCard";
import curriculumProps from "./propTypes";

const CollectionsPopout = ({
  heading,
  description,
  ctaText,
  featuredCollections,
}) => {
  const [{ search, breakpoints }, dispatch] = useAppState();
  const { sm } = breakpoints;

  const showCollections = () => {
    dispatch({
      type: "setFilters",
      filters: {
        ...search.filters,
        type: "collections",
      },
    });
    window.scrollTo(0, 0);
  };

  const getSlides = isCarousel =>
    featuredCollections.map(
      ({ id, uid, title, type, illustration, tags, color }) => (
        <CurriculumCard
          key={id}
          uid={uid}
          title={title}
          type={type}
          illustration={illustration}
          tags={tags}
          color={color}
          disableHoverStyle={isCarousel}
          className={`w-full sm:w-1/2 md:w-1/3 ${
            isCarousel ? "h-full" : "h-auto"
          }`}
        />
      )
    );

  return (
    <>
      <Container>
        <Text preset="h2" className="mb-4">
          {heading}
        </Text>
        <RichText html={description.html} className="mb-4" />

        <Button text={ctaText} preset="empty" onClick={showCollections} />
      </Container>

      {sm ? (
        <Container>
          <div className="flex flex-wrap mt-4 -mx-3 -mb-3">{getSlides()}</div>
        </Container>
      ) : (
        <Carousel
          showButtons={false}
          showDots={false}
          stagePadding={28}
          overflowVisible
          className="mt-4 -mb-3"
        >
          {getSlides(true)}
        </Carousel>
      )}
    </>
  );
};

CollectionsPopout.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  ctaText: PropTypes.string.isRequired,
  featuredCollections: PropTypes.arrayOf(curriculumProps.props).isRequired,
};

export default CollectionsPopout;
