import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveImageData from "../dataResolvers/resolveImageData";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import resolveEmbeddedDocument from "../dataResolvers/resolveEmbeddedDocument";
import mergePrismicPreview from "../mergePrismicPreview";
import homeIntroAnimation from "./json/homeIntroAnimation.json";

const query = graphql`
  query HomeIntroQuery {
    prismicHomePage {
      data {
        intro_publications_label
        intro_logos {
          image {
            url
            alt
            dimensions {
              height
              width
            }
          }
        }
        intro_video_cta_text
        intro_video {
          document {
            ... on PrismicVideos {
              id
              uid
              type
              data {
                youtube_video_id
                title
              }
            }
          }
        }
        intro_heading
        intro_description {
          html
        }
        intro_cta_text
        intro_cta_link {
          url
          target
        }
        intro_cta_curriculum_tab
      }
    }
  }
`;

const dataResolver = ({ prismicHomePage }) => {
  const { intro } = resolveByPrefix(prismicHomePage.data, ["intro_"]).prefixed;
  const { logos, video, cta_link, cta_curriculum_tab, ...rest } = intro;

  // add a tab query parameter if one was specified in Prismic
  let ctaUrl = cta_link.url;
  if (cta_curriculum_tab) {
    ctaUrl += `?tab=${cta_curriculum_tab.toLowerCase()}`;
  }

  const resolved = {
    logos: resolveImageData(logos),
    video: resolveEmbeddedDocument(video),
    animation: JSON.stringify(homeIntroAnimation),
    ctaLink: {
      ...cta_link,
      url: ctaUrl,
    },
    ...rest,
  };

  return keysToCamel(resolved);
};

const useHomeIntroData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useHomeIntroData;
