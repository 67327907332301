import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import { SingleCard } from "@modules/singles/index";
import { Text, Button, Carousel, Container, AppLink, HeadingAccent } from "@ui";
import { useVideosPopoutData } from "@staticQueries";

const VideosPopout = ({ preset }) => {
  const { heading, ctaText, videos } = useVideosPopoutData();
  const [{ breakpoints }] = useAppState();
  const { sm } = breakpoints;

  const getSlides = isCarousel =>
    videos
      .slice(0, 3)
      .map(({ uid, ...videoProps }) => (
        <SingleCard
          key={uid}
          {...videoProps}
          type="videos"
          slug={uid}
          ctaText="watch the video"
          forceSmall
          showBorder={preset === "dark"}
          className={`w-full sm:w-1/2 md:w-1/3 ${
            isCarousel ? "h-full" : "h-auto"
          }`}
        />
      ));

  const textColor = preset === "light" ? "text-white" : "text-gray-6";

  return (
    <>
      <Container className="flex flex-col items-center">
        <HeadingAccent />
        <Text preset="h2" className={`mb-4 ${textColor}`}>
          {heading}
        </Text>
      </Container>

      {sm ? (
        <Container>
          <div className="flex flex-wrap mt-4 -mx-3 -mb-3">{getSlides()}</div>
        </Container>
      ) : (
        <Carousel
          showButtons={false}
          showDots={false}
          stagePadding={28}
          overflowVisible
          className="mt-4 -mb-3"
        >
          {getSlides(true)}
        </Carousel>
      )}

      <Container className="flex justify-center mt-6">
        <AppLink to="/videos">
          <Button preset="white" text={ctaText} className="mt-6" />
        </AppLink>
      </Container>
    </>
  );
};

VideosPopout.propTypes = {
  preset: PropTypes.oneOf(["light", "dark"]),
};

VideosPopout.defaultProps = {
  preset: "dark",
};

export default VideosPopout;
