import React from "react";
import PropTypes from "prop-types";

// the base shape size
const baseShapeWidth = 50;

// create triangle
const Triangle = ({ x, y, rn }) => {
  const scalar = (rn() + 0.25) * 1.25;
  const width = baseShapeWidth * scalar;
  const height = (width / 2) * Math.sqrt(3);
  const pointOne = `${x - (1 / 2) * width}, ${y + height / 2}`;
  const pointTwo = `${x}, ${y - height / 2}`;
  const pointThree = `${x + (1 / 2) * width}, ${y + height / 2}`;
  // generate random rotation
  const rotation = () => {
    const random = rn();
    if (random < 0.33) {
      return 0;
    }
    if (random > 0.66) {
      return 90;
    }
    return 180;
  };
  // return the svg
  return (
    // this needs to be its own svg for % positioning
    <svg x={`${x}%`} y={`${y}%`} className="overflow-visible">
      <polygon
        points={`${pointOne} ${pointTwo} ${pointThree}`}
        fill="none"
        strokeWidth="4"
        transform={`rotate(${rotation()})`}
      />
    </svg>
  );
};

// create square
const Square = ({ x, y, rn }) => {
  const scalar = (rn() + 0.25) * 1.25;
  const width = baseShapeWidth * scalar;
  return (
    <rect
      x={`${Math.round(x)}%`}
      y={`${Math.round(y)}%`}
      width={width}
      height={width}
      fill="none"
      strokeWidth="4"
      strokeLinejoin="round"
    />
  );
};

// create circle
const Circle = ({ x, y, rn }) => {
  const scalar = (rn() + 0.25) * 1.25;
  return (
    <circle
      cx={`${Math.round(x)}%`}
      cy={`${Math.round(y)}%`}
      r={(baseShapeWidth / 2) * scalar}
      fill="none"
      strokeWidth="4"
    />
  );
};

const shapeProps = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  rn: PropTypes.func.isRequired,
};

Circle.propTypes = shapeProps;
Square.propTypes = shapeProps;
Triangle.propTypes = shapeProps;

const RandomShapes = ({ color, rn, numShapes }) => {
  return (
    <svg className={`w-full h-full stroke-${color} overflow-visible`}>
      {Array(numShapes)
        .fill()
        .map((value, index) => {
          // declare vars
          let x;
          let y;

          if (rn() < 0.25) {
            // randomly generate coordinates
            x = (index + 1) * (100 / (numShapes + 2)) * rn();
            y = (index + 1) * (100 / (numShapes + 2)) * rn();
          } else if (rn() < 0.5) {
            // invert for more randomness
            x = 100 - (index + 1) * (100 / (numShapes + 2)) * rn();
            y = (index + 1) * (100 / (numShapes + 2)) * rn();
          } else if (rn() < 0.75) {
            // invert for more randomness
            x = (index + 1) * (100 / (numShapes + 2)) * rn();
            y = 100 - (index + 1) * (100 / (numShapes + 2)) * rn();
          } else {
            // invert for more randomness
            x = 100 - (index + 1) * (100 / (numShapes + 2)) * rn();
            y = 100 - (index + 1) * (100 / (numShapes + 2)) * rn();
          }

          // determine shape
          const random = rn();
          if (random <= 0.25) {
            return <Circle key={index} x={x} y={y} rn={rn} />;
          }
          if (random >= 0.75) {
            return <Square key={index} x={x} y={y} rn={rn} />;
          }
          return <Triangle key={index} x={x} y={y} rn={rn} />;
        })}
    </svg>
  );
};

RandomShapes.defaultProps = {
  color: "white",
};

RandomShapes.propTypes = {
  rn: PropTypes.func.isRequired,
  numShapes: PropTypes.number.isRequired,
  color: PropTypes.string,
};

export default RandomShapes;
