import React, { useState, useRef, useEffect } from "react";
import Recaptcha from "react-recaptcha";
import { useAppState } from "@state/state";
import Form from "./RegisterForm";
import { useAuthFormsData } from "@staticQueries";

const initialFormData = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  role: "",
  subject: "",
  level: "",
  country: "",
  state: "",
  city: "",
  subscribedToNewsletter: true,
};

const RegisterFormWrap = () => {
  const [{ messages }, dispatch] = useAppState();
  const { login } = useAuthFormsData();

  const [formData, setFormData] = useState(initialFormData);
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const captcha = useRef();

  const executeCaptcha = event => {
    event.preventDefault();
    captcha.current.execute();
  };

  const handleError = async response => {
    setLoading(false);

    const body = await response.json();
    dispatch({
      type: "openMessage",
      copy: { html: `<p>${body.message}</p>` },
      bgColorClass: "bg-red",
    });
  };

  const handleSuccess = async response => {
    if (!response.ok) {
      handleError(response);
      return;
    }

    setLoading(false);
    setFormData(initialFormData);

    dispatch({
      type: "toggleLogin",
      ticket: "registration-login",
      loggedIn: true,
    });
    dispatch({
      type: "openMessage",
      copy: { html: login.message.html },
      bgColorClass: "bg-blue-5",
    });
    dispatch({ type: "closeModal" });
    setTimeout(() => {
      dispatch({ type: "clearModal" });
    }, 300);
  };

  const doRegister = _captchaResponse => {
    messages.messages.forEach(({ id: messageId, onClose }) => {
      dispatch({
        type: "closeMessage",
        idx: messages.messages.findIndex(message => message.id === messageId),
      });

      if (onClose) {
        onClose();
      }

      setTimeout(() => {
        dispatch({
          type: "removeMessage",
          idx: messages.messages.findIndex(message => message.id === messageId),
        });
      }, 667);
    });

    setLoading(true);

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...formData,
        captchaResponse: _captchaResponse,
      }),
    };

    fetch("/.netlify/functions/rest-register", options)
      .then(handleSuccess)
      .catch(handleError);
  };

  useEffect(() => {
    if (captchaResponse) {
      doRegister(captchaResponse);
    }
  }, [captchaResponse]);

  return (
    <>
      <Form
        onSubmit={executeCaptcha}
        formData={formData}
        setFormData={setFormData}
        loading={loading}
      />
      <Recaptcha
        ref={captcha}
        sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY_V3}
        size="invisible"
        verifyCallback={response => setCaptchaResponse(response)}
      />
    </>
  );
};

export default RegisterFormWrap;
