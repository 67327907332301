import React from "react";
import { useAppState } from "@state/state";
import LockupSmall from "@svg/lockup-small.svg";
import { Tabs, RandomShapes } from "@ui";

const ModalHeader = () => {
  const [{ modal }, dispatch] = useAppState();
  const { name, tabs } = modal;

  const tabItems = tabs.map((tab, idx) => ({
    id: idx,
    title: tab.title,
    onClick: () =>
      dispatch({
        type: "openModal",
        name: tab.name,
        tabs,
      }),
    isSelected: tab.name === name,
  }));

  return (
    <div className="relative bg-blue pt-6 md:pt-12 px-6 border-1/2rem border-white rounded-t-lg">
      <RandomShapes />
      <div
        className="hidden md:block absolute z-10 w right-0 pos-center-y mr-6 lockup-blue-light"
        style={{ width: "110px", height: "38px" }}
      >
        <LockupSmall />
      </div>
      <div className="relative z-10">
        <Tabs items={tabItems} inline color="white" />
      </div>
    </div>
  );
};

export default ModalHeader;
