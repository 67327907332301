import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Arrow from "@svg/triangle-small.svg";
import { Text } from "@ui";
import { capitalizeText } from "@utils";

// Select -- form select layout + change/blur handling

const Select = ({
  name,
  value,
  options,
  label,
  isRequired,
  onChange,
  onFocus,
  onBlur,
  onPressEnter,
  hasError,
  disabled,
  inline,
  className: _className,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const select = useRef(null);

  const toggleFocus = () => {
    if (!isFocused && onFocus) {
      onFocus();
    } else if (isFocused && onBlur) {
      onBlur();
    }
    setIsFocused(!isFocused);
  };

  const handleChange = event => {
    if (onChange) {
      onChange(event);
    }
  };

  const onKeyDown = event => {
    if (onPressEnter && event.key === "Enter") {
      onPressEnter();
      select.current.blur();
    }
  };

  const className = classNames(
    {
      relative: true,
      "inline-flex items-center h-10": inline,
      "text-gray": !hasError,
      "text-red": hasError,
      "opacity-50": disabled,
    },
    _className
  );

  return (
    <div className={`Select ${className}`}>
      {label && (
        <Text
          preset="label"
          className="block font-bold text-current sentence-case mb-1"
        >
          {label}
        </Text>
      )}

      <div className="relative">
        <select
          className={classNames({
            "w-full font-sans font-bold outline-none active:outline-none bg-transparent appearance-none text-sm capitalize leading-loose pr-8 transition": true,
            "cursor-pointer": !disabled,
            "h-10 relative bg-gray-1 pl-4": !inline,
            "border-none": !inline,
            "rounded-none border-b-1 border-solid border-gray-2": inline,
            "h-full": inline,
          })}
          ref={select}
          name={name}
          value={value}
          required={isRequired}
          onChange={handleChange}
          onFocus={toggleFocus}
          onBlur={toggleFocus}
          onKeyDown={onKeyDown}
          disabled={disabled}
        >
          {options.map(({ id, title }) => (
            <option key={id} value={id}>
              {capitalizeText(title)}
            </option>
          ))}
        </select>

        <span className="absolute right-0 mr-2 pos-center-y w-3 h-2 text-gray pointer-events-none">
          <Arrow />
        </span>
      </div>
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onPressEnter: PropTypes.func,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  className: PropTypes.string,
};

Select.defaultProps = {
  label: null,
  isRequired: true,
  onChange: null,
  onFocus: null,
  onBlur: null,
  onPressEnter: null,
  hasError: false,
  disabled: false,
  inline: true,
  className: "",
};

export default Select;
