import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icon from "../Icon";

const Nav = ({
  currentSlide,
  totalSlides,
  showDots,
  showButtons,
  infinite,
  slideTo,
  slidePrev,
  slideNext,
}) => {
  return (
    <>
      {showDots && (
        <div className="absolute bottom-6 pos-center-x">
          {Array(totalSlides)
            .fill()
            .map((item, idx) => (
              <button
                key={idx}
                type="button"
                className={classNames({
                  "outline-none focus:outline-none h-3 w-3 rounded-full transition mx-1": true,
                  "bg-blue": idx === currentSlide,
                  "bg-white": idx !== currentSlide,
                })}
                onClick={() => slideTo(idx)}
              >
                <span className="hidden">{idx}</span>
              </button>
            ))}
        </div>
      )}

      {showButtons && (
        <>
          <button
            type="button"
            className={classNames({
              "outline-none focus:outline-none absolute left-4 pos-center-y transition": true,
              "opacity-100 pointer-events-auto": currentSlide !== 0 || infinite,
              "opacity-25 pointer-events-none": currentSlide === 0 && !infinite,
            })}
            onClick={() => slidePrev()}
          >
            <Icon
              name="navArrow"
              className="w-12 h-12"
              style={{ transform: "rotate(180deg)" }}
            />
          </button>
          <button
            type="button"
            className={classNames({
              "outline-none focus:outline-none absolute right-4 pos-center-y transition": true,
              "opacity-100 pointer-events-auto":
                currentSlide < totalSlides - 1 || infinite,
              "opacity-25 pointer-events-none":
                currentSlide === totalSlides - 1 && !infinite,
            })}
            onClick={() => slideNext()}
          >
            <Icon name="navArrow" className="w-12 h-12" />
          </button>
        </>
      )}
    </>
  );
};

Nav.propTypes = {
  currentSlide: PropTypes.number.isRequired,
  totalSlides: PropTypes.number.isRequired,
  showButtons: PropTypes.bool.isRequired,
  showDots: PropTypes.bool.isRequired,
  infinite: PropTypes.bool.isRequired,
  slideTo: PropTypes.func,
  slidePrev: PropTypes.func,
  slideNext: PropTypes.func,
};

Nav.defaultProps = {
  slideTo: null,
  slidePrev: null,
  slideNext: null,
};

export default Nav;
