import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { CurriculumGroup } from "@modules/curriculum/index";
import { RichText, RandomShapes, Container, Button } from "@ui";
import { applyFilters } from "@utils";
import { Paginator, RegisterCTA } from "@common";
import { t } from "@translations";

const SearchResults = ({ nullResultsMessage }) => {
  const [{ search, breakpoints }, dispatch] = useAppState();
  const { filters, results: _results, content, page, options } = search;

  const { sm, md, current: currentBreakpoint } = breakpoints;
  const limits = {
    sm: 12,
    md: 15,
    lg: 16,
    xl: 16,
  };
  const limit = limits[currentBreakpoint] || 9;

  // if the currently selected type isn't searchable, then
  // use the full list of content
  const arr = options[filters.type].searchable ? _results : content;

  // apply filters
  const filteredResults = arr.filter(applyFilters(filters, options));

  // Set list view on mobile
  const { view } = search;
  useEffect(() => {
    if (sm === false && view !== "list") {
      dispatch({
        type: "changeView",
        view: "list",
      });
    }
  }, [sm]);

  // define a results render function to pass to the Paginator component
  const paginatedResults = items => {
    const results = items.map(({ uid, type }) =>
      filteredResults.find(item => item.uid === uid && item.type === type)
    );

    if (results.length > 0) {
      return (
        <Container
          className={classNames({
            "relative w-full": true,
            "md:flex": view === "list",
          })}
        >
          <div
            className={classNames({
              "flex flex-wrap -mx-3": view === "cards",
              "md:w-2/3": view === "list",
            })}
          >
            <CurriculumGroup items={results.slice(0, 4)} />

            {(view === "cards" || !md) && <RegisterCTA />}

            {results.length > 4 && (
              <CurriculumGroup items={results.slice(4, results.length)} />
            )}
          </div>

          {md && view === "list" && <RegisterCTA />}
        </Container>
      );
    }

    return (
      <Container>
        <div className="relative bg-gray-1 flex flex-col justify-center items-center py-24 overflow-hidden">
          <RandomShapes color="blue-2" />
          <RichText
            html={nullResultsMessage}
            className="RichText RichText--null-results relative z-10 text-center max-w-xs"
          />
          <div className="relative z-10 flex justify-center mt-8">
            <Button
              preset="empty"
              text={t("clear results")}
              className="text-blue"
              onClick={() => dispatch({ type: "clearSearchAndFilters" })}
            />
          </div>
        </div>
      </Container>
    );
  };

  return (
    <Paginator
      items={filteredResults.map(({ uid, type }) => ({ uid, type }))}
      page={page}
      limit={limit}
      loadMore={() => dispatch({ type: "incrementPage" })}
      render={paginatedResults}
    />
  );
};

SearchResults.propTypes = {
  nullResultsMessage: PropTypes.string.isRequired,
};

export default SearchResults;
