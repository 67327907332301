import React, { useState, useEffect, useRef } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import classNames from "classnames";
import LockupLarge from "@svg/lockup-large.svg";
import LockupSmall from "@svg/lockup-small.svg";
import { useAppState } from "@state/state";
import { SearchInput } from "@modules/search/index";
import { Button, AuthButton, AppLink, Container } from "@ui";
import Nav from "./Nav";
import { t } from "@translations";

// scroll velocity that will trigger showing/hiding the header
const velocity = 50;
// threshold in px beyond which to allow showing the header
const threshold = 400;
// var to store last scrollPosition
let lastScrollY = 0;

const Header = ({ scrollY, isScroller, currentPath }) => {
  const [{ layout, messages, theme, user }, dispatch] = useAppState();
  const [showing, setShowing] = useState(!isScroller);
  const lastPath = useRef({});

  // Get the height of all static messages on top of the page
  const scrollerTop = messages.messages.reduce((acc, cur) => {
    const add = cur.isStatic && cur.showing ? cur.height : 0;
    return acc + add;
  }, 0);

  if (isScroller) {
    useEffect(() => {
      const deltaY = scrollY - lastScrollY;
      const show = deltaY < velocity * -1 && scrollY > threshold;
      const hide =
        deltaY > velocity ||
        scrollY <= scrollerTop ||
        currentPath !== lastPath.current;

      if (hide && showing) {
        setShowing(false);
      } else if (show && !showing) {
        setShowing(true);
      }

      if (layout.showingMobileNav) {
        dispatch({ type: "setMobileNav", showingMobileNav: false });
      }

      lastScrollY = scrollY;
      lastPath.current = currentPath;
    }, [scrollY, currentPath]);
  }

  // set some color/position classes based on the current theme
  const { bgColor: _bgColor, lockup } = theme.header;
  const bgColor = _bgColor === "transparent" ? "blue" : _bgColor;
  const textColor =
    bgColor === "white" || bgColor === "yellow" ? "text-gray" : "text-white";

  const className = classNames({
    Header: true,
    [`flex items-center top-0 left-0 right-0 z-max ${textColor} transition-opacity`]: true,
    "transition-bg": _bgColor === "transparent",
    "Header--static relative bg-transparent": !isScroller,
    [`Header--scroller fixed lg:mx-2 lg:rounded-b-lg lg:border-t-1/2rem lg:border-white bg-${bgColor}`]: isScroller,
    "transition shadow-header": isScroller && lastScrollY > scrollerTop,
    "Header--hide": isScroller && !showing,
  });

  const onSearch = () => {
    navigate("curriculum#search-top");
  };

  const key = user.loggedIn ? "logged-in" : "logged-out";

  return (
    <header key={key} className={className}>
      <Container className="flex items-center justify-center h-full">
        <Nav
          posTop={scrollY > scrollerTop ? 0 : scrollerTop - scrollY}
          isScroller={isScroller}
          currentPath={currentPath}
        />
        <AuthButton
          text={t("Sign in/up")}
          className="relative z-max lg:hidden"
          preset="bordered"
          size="sm"
          modalName="signInModal"
          showLogout
        />

        <div className="relative z-max lg:flex lg:flex-1 items-center ml-auto justify-end">
          {theme.header.showSearch && (
            <SearchInput
              searchOnType="singles"
              onSearch={onSearch}
              activeClass={`bg-${bgColor}-4`}
              className={`hidden lg:inline-flex ${textColor} mr-8`}
              showSuggestions={false}
            />
          )}

          <AuthButton
            text={t("Sign in")}
            preset="empty"
            className={`hidden lg:inline-flex ${textColor} mr-4`}
            modalName="signInModal"
          />
          <AuthButton
            text={t("Sign up")}
            preset="white"
            className="hidden lg:inline-flex"
            modalName="registerModal"
            showLogout
          />

          <Button
            text={layout.showingMobileNav ? t("close") : t("menu")}
            className="lg:hidden"
            iconName={layout.showingMobileNav ? "x" : "hamburger"}
            iconClassName="w-4 h-3"
            preset="bordered"
            size="sm"
            onClick={() =>
              dispatch({
                type: "setMobileNav",
                showingMobileNav: !layout.showingMobileNav,
              })
            }
          />
        </div>

        <AppLink
          to="/"
          className={classNames({
            "Header__lockup__wrapper top-0": true,
            "absolute top-0 pos-center-x z-max block px-4 h-12 lg:h-auto rounded-b-lg bg-white flex items-center justify-center": true,
            "shadow-header":
              (isScroller && lastScrollY > 0 && bgColor !== "white") ||
              (!isScroller && bgColor !== "transparent"),
            [`lockup-${lockup.color}`]: true,
          })}
        >
          <div className="Header__lockup-large hidden lg:block transition-color">
            <LockupLarge />
          </div>
          <div className="Header__lockup-small block lg:hidden transition-color">
            <LockupSmall />
          </div>
        </AppLink>
      </Container>
    </header>
  );
};

Header.propTypes = {
  scrollY: PropTypes.number.isRequired,
  isScroller: PropTypes.bool,
  currentPath: PropTypes.string,
};

Header.defaultProps = {
  isScroller: false,
  currentPath: "",
};

export default Header;
