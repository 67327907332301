import { useStaticQuery, graphql } from "gatsby";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveImageData from "../dataResolvers/resolveImageData";
import resolveEmbeddedDocument from "../dataResolvers/resolveEmbeddedDocument";

const query = graphql`
  query VideosPopoutQuery {
    prismicGlobals {
      data {
        videos_popout_heading
        videos_popout_cta_text
        videos_popout_videos {
          video {
            document {
              ... on PrismicVideos {
                id
                uid
                data {
                  title
                  display_date(formatString: "MMM DD, YYYY")
                  description {
                    html
                  }
                  categories {
                    category
                  }
                  topic {
                    uid
                  }
                  thumbnail {
                    focal_point_x
                    focal_point_y
                    image {
                      url
                      dimensions {
                        width
                        height
                      }
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = response => {
  const { prismicGlobals } = response;
  const {
    videos_popout_heading,
    videos_popout_cta_text,
    videos_popout_videos,
  } = prismicGlobals.data;

  return {
    heading: videos_popout_heading,
    ctaText: videos_popout_cta_text,
    videos: resolveEmbeddedDocument(videos_popout_videos).map(
      ({ thumbnail, categories, display_date, topic, ...rest }) => ({
        ...rest,
        date: display_date,
        categories: categories.map(({ category }) => ({
          title: category.toLowerCase(),
          id: category
            .toLowerCase()
            .split(" ")
            .join("-"),
        })),
        topic: topic.uid,
        image: resolveImageData(thumbnail[0]),
      })
    ),
  };
};

const useVideosPopoutData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useVideosPopoutData;
