import React, { useEffect } from "react";
import { useAppState } from "@state/state";
import { Image, imageProps, RandomShapes, Section } from "@ui";

const SingleHero = ({ image }) => {
  const [{ breakpoints }, dispatch] = useAppState();

  useEffect(() => {
    dispatch({
      type: "setHeaderTheme",
      headerTheme: {
        bgColor: image ? "transparent" : "blue",
        showSearch: true,
        lockup: {
          size: "small",
          color: "blue",
        },
      },
    });
  }, []);

  const { lg } = breakpoints;
  const height = lg ? 108 : 68;

  return (
    <>
      {image ? (
        <Section hasPaddingTop={false} hasPaddingBottom={false}>
          <div
            className="absolute inset-0 z-20"
            style={{
              background: "linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,0))",
            }}
          />
          <div
            className="relative h-128 z-10"
            style={{ height: "55vh", maxHeight: "500px" }}
          >
            <Image image={image} />
          </div>
        </Section>
      ) : (
        <Section
          hasPaddingTop={false}
          hasPaddingBottom={false}
          className="bg-blue"
        >
          <div className="relative -mt-2" style={{ height: `${height}px` }}>
            <RandomShapes />
          </div>
        </Section>
      )}
    </>
  );
};

SingleHero.propTypes = {
  image: imageProps.props,
};

SingleHero.defaultProps = {
  image: null,
};

export default SingleHero;
