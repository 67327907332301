import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import classNames from "classnames";
import { Icon, AuthButton } from "@ui";
import { t } from "@translations";
import { emailIsValid } from "../../utils/index";
import Input from "./Input";

// ValidatedEmailInput: verify valid email address and run server-side validation on change

const ErrorMessage = ({ error }) => {
  return (
    <div className="RichText RichText--sans RichText--xs text-white">
      {error === "emailInvalid" ? (
        <p>{t("Please enter a valid email address")}.</p>
      ) : (
        <p className="inline">
          {t("An account has already been created with this email address")}.{" "}
          <AuthButton
            text={t("Click here to login")}
            preset="empty"
            className="text-white"
            modalName="signInModal"
            applySentenceCase={false}
            size="xs"
            style={{
              overflow: "hidden",
              display: "inline",
              flexDirection: "unset",
              fontWeight: "normal",
            }}
          />
          .
        </p>
      )}
    </div>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.string,
};

ErrorMessage.defaultProps = {
  error: null,
};

const ValidatedEmailInput = ({
  isValid,
  setIsValid,
  onChange,
  className,
  ...inputProps
}) => {
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const timeout = useRef();

  const checkEmail = email => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      // First check if email format is valid
      if (!emailIsValid(email)) {
        setError("emailInvalid");
        return;
      }

      // Then show loading indicator and check if email already belongs to a user
      setLoading(true);

      axios
        .post("/.netlify/functions/oauth-validate-email", { email, token })
        .then((response = {}) => {
          setLoading(false);

          const { userExists, bearerToken } = response.data || {};
          // set token to state so we can reuse it on subsequent requests
          setToken(bearerToken);
          // If user exists show error, else setIsValid true
          if (userExists) {
            setIsValid(false);
            setError("emailAlreadyExists");
          } else {
            setError(null);
            setIsValid(true);
          }
        })
        .catch(() => {
          // If for some reason we can't process the request, setIsValid true
          // so user can continue registering
          setLoading(false);
          setIsValid(true);
        });
    }, 667);
  };

  const handleChange = event => {
    setError(null);
    setIsValid(false);
    checkEmail(event.currentTarget.value);
    onChange(event);
  };

  return (
    <div className="relative">
      <div className="relative z-10">
        <Input
          onChange={handleChange}
          hasError={!!error}
          bgColor="bg-white"
          {...inputProps}
        />

        <div className="absolute right-2" style={{ bottom: ".5rem" }}>
          {loading && (
            <Icon name="loading" className="w-4 h-4 text-gray-2 animate-spin" />
          )}

          {isValid && !loading && (
            <Icon
              name="validated"
              className="w-5 h-5 text-green"
              style={{ transform: "translateY(.1rem)" }}
            />
          )}
        </div>
      </div>

      <div
        className={classNames({
          "absolute left-0 bottom-2 right-0 translate-y-100 bg-red pt-2 px-2 rounded-sm": true,
          "opacity-0 pointer-events-none": !error,
          "opacity-100 pointer-events-auto": !!error,
        })}
      >
        <ErrorMessage error={error} />
      </div>
    </div>
  );
};

ValidatedEmailInput.propTypes = {
  isValid: PropTypes.bool.isRequired,
  setIsValid: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ValidatedEmailInput.defaultProps = {
  className: "",
};

export default ValidatedEmailInput;
