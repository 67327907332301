import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveDocumentEdges from "../dataResolvers/resolveDocumentEdges";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import resolveImageData from "../dataResolvers/resolveImageData";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query HomeBlogQuery {
    prismicHomePage {
      data {
        blog_heading
        blog_description {
          html
        }
        blog_cta_text
        blog_cta_link {
          url
          target
        }
      }
    }
    allPrismicBlogPosts(
      sort: { fields: data___display_date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          uid
          first_publication_date(formatString: "MMM DD YYYY")
          data {
            title
            display_date(formatString: "MMM DD, YYYY")
            categories {
              category
            }
            image_group {
              focal_point_x
              focal_point_y
              image {
                url
                dimensions {
                  width
                  height
                }
                url
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicHomePage, allPrismicBlogPosts }) => {
  const { blog } = resolveByPrefix(prismicHomePage.data, ["blog_"]).prefixed;

  const posts = resolveDocumentEdges(allPrismicBlogPosts.edges);

  const resolved = {
    ...blog,
    posts: posts.map(({ image_group, categories, display_date, ...rest }) => ({
      ...rest,
      date: display_date,
      categories: categories.map(({ category }) => ({
        title: category.toLowerCase(),
        id: category
          .toLowerCase()
          .split(" ")
          .join("-"),
      })),
      image: resolveImageData(image_group[0]),
    })),
  };

  return keysToCamel(resolved);
};

const useHomeBlogData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useHomeBlogData;
