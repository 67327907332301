const reducer = (state, action) => {
  switch (action.type) {
    case "setCategory":
      return {
        ...state,
        [`${action.indexName}`]: {
          category: action.category,
          topic: action.topic,
          page: 0,
        },
      };
    case "setTopic":
      return {
        ...state,
        [`${action.indexName}`]: {
          topic: action.topic,
          category: action.category,
          page: 0,
        },
      };
    case "incrementIndexPage": {
      return {
        ...state,
        [`${action.indexName}`]: {
          category: state[`${action.indexName}`].category,
          topic: state[`${action.indexName}`].topic,
          page: state[`${action.indexName}`].page + 1,
        },
      };
    }

    default:
      return state;
  }
};

const initialState = {
  blog: {
    category: "",
    topic: "",
    page: 0,
  },
  news: {
    category: "",
    topic: "",
    page: 0,
  },
  videos: {
    category: "",
    topic: "",
    page: 0,
  },
  research: {
    category: "",
    topic: "",
    page: 0,
  },
};

export default {
  reducer,
  initialState,
};
