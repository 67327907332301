export const countries = [
  {
    id: "AF",
    title: "Afghanistan",
  },
  {
    id: "AX",
    title: "Åland Islands",
  },
  {
    id: "AL",
    title: "Albania",
  },
  {
    id: "DZ",
    title: "Algeria",
  },
  {
    id: "AS",
    title: "American Samoa",
  },
  {
    id: "AD",
    title: "Andorra",
  },
  {
    id: "AO",
    title: "Angola",
  },
  {
    id: "AI",
    title: "Anguilla",
  },
  {
    id: "AQ",
    title: "Antarctica",
  },
  {
    id: "AG",
    title: "Antigua & Barbuda",
  },
  {
    id: "AR",
    title: "Argentina",
  },
  {
    id: "AM",
    title: "Armenia",
  },
  {
    id: "AW",
    title: "Aruba",
  },
  {
    id: "AC",
    title: "Ascension Island",
  },
  {
    id: "AU",
    title: "Australia",
  },
  {
    id: "AT",
    title: "Austria",
  },
  {
    id: "AZ",
    title: "Azerbaijan",
  },
  {
    id: "BS",
    title: "Bahamas",
  },
  {
    id: "BH",
    title: "Bahrain",
  },
  {
    id: "BD",
    title: "Bangladesh",
  },
  {
    id: "BB",
    title: "Barbados",
  },
  {
    id: "BY",
    title: "Belarus",
  },
  {
    id: "BE",
    title: "Belgium",
  },
  {
    id: "BZ",
    title: "Belize",
  },
  {
    id: "BJ",
    title: "Benin",
  },
  {
    id: "BM",
    title: "Bermuda",
  },
  {
    id: "BT",
    title: "Bhutan",
  },
  {
    id: "BO",
    title: "Bolivia",
  },
  {
    id: "BA",
    title: "Bosnia & Herzegovina",
  },
  {
    id: "BW",
    title: "Botswana",
  },
  {
    id: "BV",
    title: "Bouvet Island",
  },
  {
    id: "BR",
    title: "Brazil",
  },
  {
    id: "IO",
    title: "British Indian Ocean Territory",
  },
  {
    id: "VG",
    title: "British Virgin Islands",
  },
  {
    id: "BN",
    title: "Brunei",
  },
  {
    id: "BG",
    title: "Bulgaria",
  },
  {
    id: "BF",
    title: "Burkina Faso",
  },
  {
    id: "BI",
    title: "Burundi",
  },
  {
    id: "KH",
    title: "Cambodia",
  },
  {
    id: "CM",
    title: "Cameroon",
  },
  {
    id: "CA",
    title: "Canada",
  },
  {
    id: "IC",
    title: "Canary Islands",
  },
  {
    id: "CV",
    title: "Cape Verde",
  },
  {
    id: "BQ",
    title: "Caribbean Netherlands",
  },
  {
    id: "KY",
    title: "Cayman Islands",
  },
  {
    id: "CF",
    title: "Central African Republic",
  },
  {
    id: "EA",
    title: "Ceuta & Melilla",
  },
  {
    id: "TD",
    title: "Chad",
  },
  {
    id: "CL",
    title: "Chile",
  },
  {
    id: "CN",
    title: "China",
  },
  {
    id: "CX",
    title: "Christmas Island",
  },
  {
    id: "CP",
    title: "Clipperton Island",
  },
  {
    id: "CC",
    title: "Cocos (Keeling) Islands",
  },
  {
    id: "CO",
    title: "Colombia",
  },
  {
    id: "KM",
    title: "Comoros",
  },
  {
    id: "CG",
    title: "Congo - Brazzaville",
  },
  {
    id: "CD",
    title: "Congo - Kinshasa",
  },
  {
    id: "CK",
    title: "Cook Islands",
  },
  {
    id: "CR",
    title: "Costa Rica",
  },
  {
    id: "CI",
    title: "Côte d’Ivoire",
  },
  {
    id: "HR",
    title: "Croatia",
  },
  {
    id: "CU",
    title: "Cuba",
  },
  {
    id: "CW",
    title: "Curaçao",
  },
  {
    id: "CY",
    title: "Cyprus",
  },
  {
    id: "CZ",
    title: "Czechia",
  },
  {
    id: "DK",
    title: "Denmark",
  },
  {
    id: "DG",
    title: "Diego Garcia",
  },
  {
    id: "DJ",
    title: "Djibouti",
  },
  {
    id: "DM",
    title: "Dominica",
  },
  {
    id: "DO",
    title: "Dominican Republic",
  },
  {
    id: "EC",
    title: "Ecuador",
  },
  {
    id: "EG",
    title: "Egypt",
  },
  {
    id: "SV",
    title: "El Salvador",
  },
  {
    id: "GQ",
    title: "Equatorial Guinea",
  },
  {
    id: "ER",
    title: "Eritrea",
  },
  {
    id: "EE",
    title: "Estonia",
  },
  {
    id: "ET",
    title: "Ethiopia",
  },
  {
    id: "FK",
    title: "Falkland Islands",
  },
  {
    id: "FO",
    title: "Faroe Islands",
  },
  {
    id: "FJ",
    title: "Fiji",
  },
  {
    id: "FI",
    title: "Finland",
  },
  {
    id: "FR",
    title: "France",
  },
  {
    id: "GF",
    title: "French Guiana",
  },
  {
    id: "PF",
    title: "French Polynesia",
  },
  {
    id: "TF",
    title: "French Southern Territories",
  },
  {
    id: "GA",
    title: "Gabon",
  },
  {
    id: "GM",
    title: "Gambia",
  },
  {
    id: "GE",
    title: "Georgia",
  },
  {
    id: "DE",
    title: "Germany",
  },
  {
    id: "GH",
    title: "Ghana",
  },
  {
    id: "GI",
    title: "Gibraltar",
  },
  {
    id: "GR",
    title: "Greece",
  },
  {
    id: "GL",
    title: "Greenland",
  },
  {
    id: "GD",
    title: "Grenada",
  },
  {
    id: "GP",
    title: "Guadeloupe",
  },
  {
    id: "GU",
    title: "Guam",
  },
  {
    id: "GT",
    title: "Guatemala",
  },
  {
    id: "GG",
    title: "Guernsey",
  },
  {
    id: "GN",
    title: "Guinea",
  },
  {
    id: "GW",
    title: "Guinea-Bissau",
  },
  {
    id: "GY",
    title: "Guyana",
  },
  {
    id: "HT",
    title: "Haiti",
  },
  {
    id: "HM",
    title: "Heard & McDonald Islands",
  },
  {
    id: "HN",
    title: "Honduras",
  },
  {
    id: "HK",
    title: "Hong Kong SAR China",
  },
  {
    id: "HU",
    title: "Hungary",
  },
  {
    id: "IS",
    title: "Iceland",
  },
  {
    id: "IN",
    title: "India",
  },
  {
    id: "ID",
    title: "Indonesia",
  },
  {
    id: "IR",
    title: "Iran",
  },
  {
    id: "IQ",
    title: "Iraq",
  },
  {
    id: "IE",
    title: "Ireland",
  },
  {
    id: "IM",
    title: "Isle of Man",
  },
  {
    id: "IL",
    title: "Israel",
  },
  {
    id: "IT",
    title: "Italy",
  },
  {
    id: "JM",
    title: "Jamaica",
  },
  {
    id: "JP",
    title: "Japan",
  },
  {
    id: "JE",
    title: "Jersey",
  },
  {
    id: "JO",
    title: "Jordan",
  },
  {
    id: "KZ",
    title: "Kazakhstan",
  },
  {
    id: "KE",
    title: "Kenya",
  },
  {
    id: "KI",
    title: "Kiribati",
  },
  {
    id: "XK",
    title: "Kosovo",
  },
  {
    id: "KW",
    title: "Kuwait",
  },
  {
    id: "KG",
    title: "Kyrgyzstan",
  },
  {
    id: "LA",
    title: "Laos",
  },
  {
    id: "LV",
    title: "Latvia",
  },
  {
    id: "LB",
    title: "Lebanon",
  },
  {
    id: "LS",
    title: "Lesotho",
  },
  {
    id: "LR",
    title: "Liberia",
  },
  {
    id: "LY",
    title: "Libya",
  },
  {
    id: "LI",
    title: "Liechtenstein",
  },
  {
    id: "LT",
    title: "Lithuania",
  },
  {
    id: "LU",
    title: "Luxembourg",
  },
  {
    id: "MO",
    title: "Macau SAR China",
  },
  {
    id: "MK",
    title: "Macedonia",
  },
  {
    id: "MG",
    title: "Madagascar",
  },
  {
    id: "MW",
    title: "Malawi",
  },
  {
    id: "MY",
    title: "Malaysia",
  },
  {
    id: "MV",
    title: "Maldives",
  },
  {
    id: "ML",
    title: "Mali",
  },
  {
    id: "MT",
    title: "Malta",
  },
  {
    id: "MH",
    title: "Marshall Islands",
  },
  {
    id: "MQ",
    title: "Martinique",
  },
  {
    id: "MR",
    title: "Mauritania",
  },
  {
    id: "MU",
    title: "Mauritius",
  },
  {
    id: "YT",
    title: "Mayotte",
  },
  {
    id: "MX",
    title: "Mexico",
  },
  {
    id: "FM",
    title: "Micronesia",
  },
  {
    id: "MD",
    title: "Moldova",
  },
  {
    id: "MC",
    title: "Monaco",
  },
  {
    id: "MN",
    title: "Mongolia",
  },
  {
    id: "ME",
    title: "Montenegro",
  },
  {
    id: "MS",
    title: "Montserrat",
  },
  {
    id: "MA",
    title: "Morocco",
  },
  {
    id: "MZ",
    title: "Mozambique",
  },
  {
    id: "MM",
    title: "Myanmar (Burma)",
  },
  {
    id: "NA",
    title: "Namibia",
  },
  {
    id: "NR",
    title: "Nauru",
  },
  {
    id: "NP",
    title: "Nepal",
  },
  {
    id: "NL",
    title: "Netherlands",
  },
  {
    id: "NC",
    title: "New Caledonia",
  },
  {
    id: "NZ",
    title: "New Zealand",
  },
  {
    id: "NI",
    title: "Nicaragua",
  },
  {
    id: "NE",
    title: "Niger",
  },
  {
    id: "NG",
    title: "Nigeria",
  },
  {
    id: "NU",
    title: "Niue",
  },
  {
    id: "NF",
    title: "Norfolk Island",
  },
  {
    id: "KP",
    title: "North Korea",
  },
  {
    id: "MP",
    title: "Northern Mariana Islands",
  },
  {
    id: "NO",
    title: "Norway",
  },
  {
    id: "OM",
    title: "Oman",
  },
  {
    id: "PK",
    title: "Pakistan",
  },
  {
    id: "PW",
    title: "Palau",
  },
  {
    id: "PS",
    title: "Palestinian Territories",
  },
  {
    id: "PA",
    title: "Panama",
  },
  {
    id: "PG",
    title: "Papua New Guinea",
  },
  {
    id: "PY",
    title: "Paraguay",
  },
  {
    id: "PE",
    title: "Peru",
  },
  {
    id: "PH",
    title: "Philippines",
  },
  {
    id: "PN",
    title: "Pitcairn Islands",
  },
  {
    id: "PL",
    title: "Poland",
  },
  {
    id: "PT",
    title: "Portugal",
  },
  {
    id: "PR",
    title: "Puerto Rico",
  },
  {
    id: "QA",
    title: "Qatar",
  },
  {
    id: "RE",
    title: "Réunion",
  },
  {
    id: "RO",
    title: "Romania",
  },
  {
    id: "RU",
    title: "Russia",
  },
  {
    id: "RW",
    title: "Rwanda",
  },
  {
    id: "WS",
    title: "Samoa",
  },
  {
    id: "SM",
    title: "San Marino",
  },
  {
    id: "ST",
    title: "São Tomé & Príncipe",
  },
  {
    id: "SA",
    title: "Saudi Arabia",
  },
  {
    id: "SN",
    title: "Senegal",
  },
  {
    id: "RS",
    title: "Serbia",
  },
  {
    id: "SC",
    title: "Seychelles",
  },
  {
    id: "SL",
    title: "Sierra Leone",
  },
  {
    id: "SG",
    title: "Singapore",
  },
  {
    id: "SX",
    title: "Sint Maarten",
  },
  {
    id: "SK",
    title: "Slovakia",
  },
  {
    id: "SI",
    title: "Slovenia",
  },
  {
    id: "SB",
    title: "Solomon Islands",
  },
  {
    id: "SO",
    title: "Somalia",
  },
  {
    id: "ZA",
    title: "South Africa",
  },
  {
    id: "GS",
    title: "South Georgia & South Sandwich Islands",
  },
  {
    id: "KR",
    title: "South Korea",
  },
  {
    id: "SS",
    title: "South Sudan",
  },
  {
    id: "ES",
    title: "Spain",
  },
  {
    id: "LK",
    title: "Sri Lanka",
  },
  {
    id: "BL",
    title: "St. Barthélemy",
  },
  {
    id: "SH",
    title: "St. Helena",
  },
  {
    id: "KN",
    title: "St. Kitts & Nevis",
  },
  {
    id: "LC",
    title: "St. Lucia",
  },
  {
    id: "MF",
    title: "St. Martin",
  },
  {
    id: "PM",
    title: "St. Pierre & Miquelon",
  },
  {
    id: "VC",
    title: "St. Vincent & Grenadines",
  },
  {
    id: "SD",
    title: "Sudan",
  },
  {
    id: "SR",
    title: "Suriname",
  },
  {
    id: "SJ",
    title: "Svalbard & Jan Mayen",
  },
  {
    id: "SZ",
    title: "Swaziland",
  },
  {
    id: "SE",
    title: "Sweden",
  },
  {
    id: "CH",
    title: "Switzerland",
  },
  {
    id: "SY",
    title: "Syria",
  },
  {
    id: "TW",
    title: "Taiwan",
  },
  {
    id: "TJ",
    title: "Tajikistan",
  },
  {
    id: "TZ",
    title: "Tanzania",
  },
  {
    id: "TH",
    title: "Thailand",
  },
  {
    id: "TL",
    title: "Timor-Leste",
  },
  {
    id: "TG",
    title: "Togo",
  },
  {
    id: "TK",
    title: "Tokelau",
  },
  {
    id: "TO",
    title: "Tonga",
  },
  {
    id: "TT",
    title: "Trinidad & Tobago",
  },
  {
    id: "TA",
    title: "Tristan da Cunha",
  },
  {
    id: "TN",
    title: "Tunisia",
  },
  {
    id: "TR",
    title: "Turkey",
  },
  {
    id: "TM",
    title: "Turkmenistan",
  },
  {
    id: "TC",
    title: "Turks & Caicos Islands",
  },
  {
    id: "TV",
    title: "Tuvalu",
  },
  {
    id: "UM",
    title: "U.S. Outlying Islands",
  },
  {
    id: "VI",
    title: "U.S. Virgin Islands",
  },
  {
    id: "UG",
    title: "Uganda",
  },
  {
    id: "UA",
    title: "Ukraine",
  },
  {
    id: "AE",
    title: "United Arab Emirates",
  },
  {
    id: "GB",
    title: "United Kingdom",
  },
  {
    id: "US",
    title: "United States",
  },
  {
    id: "UY",
    title: "Uruguay",
  },
  {
    id: "UZ",
    title: "Uzbekistan",
  },
  {
    id: "VU",
    title: "Vanuatu",
  },
  {
    id: "VA",
    title: "Vatican City",
  },
  {
    id: "VE",
    title: "Venezuela",
  },
  {
    id: "VN",
    title: "Vietnam",
  },
  {
    id: "WF",
    title: "Wallis & Futuna",
  },
  {
    id: "EH",
    title: "Western Sahara",
  },
  {
    id: "YE",
    title: "Yemen",
  },
  {
    id: "ZM",
    title: "Zambia",
  },
  {
    id: "ZW",
    title: "Zimbabwe",
  },
];

export const states = [
  {
    id: "AL",
    title: "Alabama",
  },
  {
    id: "AK",
    title: "Alaska",
  },
  {
    id: "AS",
    title: "American Samoa",
  },
  {
    id: "AZ",
    title: "Arizona",
  },
  {
    id: "AR",
    title: "Arkansas",
  },
  {
    id: "AA",
    title: "Armed Forces (AA)",
  },
  {
    id: "AE",
    title: "Armed Forces (AE)",
  },
  {
    id: "AP",
    title: "Armed Forces (AP)",
  },
  {
    id: "CA",
    title: "California",
  },
  {
    id: "CO",
    title: "Colorado",
  },
  {
    id: "CT",
    title: "Connecticut",
  },
  {
    id: "DE",
    title: "Delaware",
  },
  {
    id: "DC",
    title: "District of Columbia",
  },
  {
    id: "FL",
    title: "Florida",
  },
  {
    id: "GA",
    title: "Georgia",
  },
  {
    id: "GU",
    title: "Guam",
  },
  {
    id: "HI",
    title: "Hawaii",
  },
  {
    id: "ID",
    title: "Idaho",
  },
  {
    id: "IL",
    title: "Illinois",
  },
  {
    id: "IN",
    title: "Indiana",
  },
  {
    id: "IA",
    title: "Iowa",
  },
  {
    id: "KS",
    title: "Kansas",
  },
  {
    id: "KY",
    title: "Kentucky",
  },
  {
    id: "LA",
    title: "Louisiana",
  },
  {
    id: "ME",
    title: "Maine",
  },
  {
    id: "MH",
    title: "Marshall Islands",
  },
  {
    id: "MD",
    title: "Maryland",
  },
  {
    id: "MA",
    title: "Massachusetts",
  },
  {
    id: "MI",
    title: "Michigan",
  },
  {
    id: "FM",
    title: "Micronesia",
  },
  {
    id: "MN",
    title: "Minnesota",
  },
  {
    id: "MS",
    title: "Mississippi",
  },
  {
    id: "MO",
    title: "Missouri",
  },
  {
    id: "MT",
    title: "Montana",
  },
  {
    id: "NE",
    title: "Nebraska",
  },
  {
    id: "NV",
    title: "Nevada",
  },
  {
    id: "NH",
    title: "New Hampshire",
  },
  {
    id: "NJ",
    title: "New Jersey",
  },
  {
    id: "NM",
    title: "New Mexico",
  },
  {
    id: "NY",
    title: "New York",
  },
  {
    id: "NC",
    title: "North Carolina",
  },
  {
    id: "ND",
    title: "North Dakota",
  },
  {
    id: "MP",
    title: "Northern Mariana Islands",
  },
  {
    id: "OH",
    title: "Ohio",
  },
  {
    id: "OK",
    title: "Oklahoma",
  },
  {
    id: "OR",
    title: "Oregon",
  },
  {
    id: "PW",
    title: "Palau",
  },
  {
    id: "PA",
    title: "Pennsylvania",
  },
  {
    id: "PR",
    title: "Puerto Rico",
  },
  {
    id: "RI",
    title: "Rhode Island",
  },
  {
    id: "SC",
    title: "South Carolina",
  },
  {
    id: "SD",
    title: "South Dakota",
  },
  {
    id: "TN",
    title: "Tennessee",
  },
  {
    id: "TX",
    title: "Texas",
  },
  {
    id: "UT",
    title: "Utah",
  },
  {
    id: "VT",
    title: "Vermont",
  },
  {
    id: "VI",
    title: "Virgin Islands",
  },
  {
    id: "VA",
    title: "Virginia",
  },
  {
    id: "WA",
    title: "Washington",
  },
  {
    id: "WV",
    title: "West Virginia",
  },
  {
    id: "WI",
    title: "Wisconsin",
  },
  {
    id: "WY",
    title: "Wyoming",
  },
];

// Handle countries that have a specific set of cities that user must choose from.
// Pass empty array, if city field should not be used
export const cityOverrides = {
  AD: [
    {
      id: "Parròquia d'Andorra la Vella",
      title: "Parròquia d'Andorra la Vella",
    },
    {
      id: "Canillo",
      title: "Canillo",
    },
    {
      id: "Encamp",
      title: "Encamp",
    },
    {
      id: "Escaldes-Engordany",
      title: "Escaldes-Engordany",
    },
    {
      id: "La Massana",
      title: "La Massana",
    },
    {
      id: "Ordino",
      title: "Ordino",
    },
    {
      id: "Sant Julià de Lòria",
      title: "Sant Julià de Lòria",
    },
  ],
  SG: [],
};

export default {
  states,
  countries,
  cityOverrides,
};
