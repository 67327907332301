import React from "react";
import PropTypes from "prop-types";
import {
  Image,
  imageProps,
  Text,
  RichText,
  HeadingAccent,
  Button,
  AppLink,
  Section,
  Container,
} from "@ui";

// Container is used primarily to give content a max-width
// using the tailwind `container` class

const PreFooter = ({
  heading,
  ctaText,
  ctaLink,
  description,
  backgroundImage,
  className,
}) => {
  // Don't render if we don't have at least some content
  if (!heading && !description.html && !ctaLink.url && !backgroundImage) {
    return null;
  }

  return (
    <Section size="sm" className={`relative ${className}`}>
      <Container>
        <div className="absolute inset-0 z-10">
          <Image image={backgroundImage} />
        </div>

        <div className="relative flex flex-col items-center justify-center text-center max-w-xs mx-auto z-20">
          <HeadingAccent />
          <Text preset="h3" className="text-2xl mb-6">
            {heading}
          </Text>
          <RichText html={description.html} className="mb-6" />

          {ctaText && !!ctaText.length && ctaLink.url && !!ctaLink.url.length && (
            <AppLink to={ctaLink.url}>
              <Button text={ctaText} preset="white" />
            </AppLink>
          )}
        </div>
      </Container>
    </Section>
  );
};

PreFooter.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.shape({
    html: PropTypes.string,
  }),
  ctaText: PropTypes.string,
  ctaLink: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.string,
  }),
  backgroundImage: imageProps.props,
  className: PropTypes.string,
};

PreFooter.defaultProps = {
  heading: "",
  description: {},
  ctaText: null,
  ctaLink: {},
  backgroundImage: null,
  className: "",
};

export default PreFooter;
