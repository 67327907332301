import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Text, imageProps, Button, Image, AppLink } from "@ui";
import { t } from "@translations";

const SingleCardAlt = ({
  slug,
  type,
  title,
  image,
  ctaText,
  className: _className,
}) => {
  const [rendered, setRendered] = useState();
  useEffect(() => setRendered(true), []);

  return (
    <div
      id={slug}
      className={`p-3 transition-opacity ${_className}`}
      style={{ opacity: rendered ? 1 : 0 }}
    >
      <AppLink
        to={type === "news" ? `/in-the-news/${slug}` : `/${type}/${slug}`}
        className="flex items-start sm:items-center rounded p-4 md:p-6 border-1 border-gray-1"
      >
        {image && (
          <div className="w-24 sm:w-36 flex items-center justify-center pr-4 md:pr-6">
            <div className="w-full">
              <Image image={image} objectFit="contain" showLQIP={false} debug />
            </div>
          </div>
        )}

        <div className="flex-1">
          <Text preset="h3" className="flex-1">
            {title}
          </Text>

          <div className="mt-4">
            <Button preset="empty" text={t(ctaText)} className="text-blue" />
          </div>
        </div>
      </AppLink>
    </div>
  );
};

SingleCardAlt.propTypes = {
  slug: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["blog", "news", "videos", "research"]).isRequired,
  title: PropTypes.string.isRequired,
  image: imageProps.props,
  ctaText: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SingleCardAlt.defaultProps = {
  image: null,
  className: "",
};

export default SingleCardAlt;
