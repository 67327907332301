import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Text from "./Text";

const Tabs = ({ items, color, inline }) => {
  return (
    <>
      {items.map(({ id, title, onClick, isSelected }) => (
        <button
          key={id}
          type="button"
          className={classNames({
            "flex-1 flex outline-none focus:outline-none cursor-pointer justify-center": true,
            "font-sans font-bold text-sm text-white p-3 rounded mb-2 md:mb-0 md:rounded-none md:rounded-t": true,
            flex: !inline,
            "inline-flex mr-4": inline,
            "text-white": color === "white" && !isSelected,
            "text-gray": color === "gray",
            "bg-white text-gray": color === "white" && isSelected,
            "bg-gray-1": color === "gray" && isSelected,
          })}
          onClick={onClick}
        >
          <Text preset="button" tag="span" className="capitalize">
            {title}
          </Text>
        </button>
      ))}
    </>
  );
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      isSelected: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  color: PropTypes.string,
  inline: PropTypes.bool,
};

Tabs.defaultProps = {
  color: "white",
  inline: false,
};

export default Tabs;
