import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel, getTailwindUtility } from "@utils";
import resolveImageData from "../dataResolvers/resolveImageData";
import resolveEmbeddedDocument from "../dataResolvers/resolveEmbeddedDocument";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query HomeResearchQuery {
    prismicHomePage {
      data {
        research_heading
        research_cta_text
        research_cta_link {
          url
          target
        }
        research_description {
          html
        }
        research_cor_questions_heading
        research_cor_questions {
          cor_question {
            document {
              ... on PrismicCorQuestions {
                id
                type
                data {
                  title
                  color
                }
              }
            }
          }
        }
        research_visualization_images {
          type
          image {
            url
            alt
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicHomePage }) => {
  const { research } = resolveByPrefix(prismicHomePage.data, [
    "research_",
  ]).prefixed;
  const { visualization_images, cor_questions, ...rest } = research;
  const corQuestions = resolveEmbeddedDocument(cor_questions);

  // use Tailwind config to get color hex values
  // that map to dropdown selections in Prismic
  const colors = getTailwindUtility("colors");
  const colorHexMap = {
    Yellow: colors.yellow["3"],
    Red: colors.red["3"],
    Green: colors.green["3"],
  };

  const resolved = {
    ...rest,
    visualizationImages: visualization_images.map(item => ({
      type: item.type,
      image: resolveImageData({ image: item.image }),
    })),
    corQuestions: corQuestions.map(({ id, title, color }) => ({
      id,
      title,
      color: colorHexMap[color],
    })),
  };

  return keysToCamel(resolved);
};

const useHomeResearchData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useHomeResearchData;
