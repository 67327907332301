const resolveDocumentEdges = edges => {
  return edges.map(edge => {
    const { data, ...rest } = edge.node;
    return {
      ...rest,
      ...data,
    };
  });
};

export default resolveDocumentEdges;
