import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isIE } from "react-device-detect";
import { useAppState } from "@state/state";
import { useAuthFormsData } from "@staticQueries";
import Button from "./Button";
import buttonProps from "./propTypes";
import { OauthPopup } from "@utils";
import { t } from "@translations";

const AuthButton = ({ showLogout, modalName, ...rest }) => {
  const [{ user, breakpoints }, dispatch] = useAppState();
  const { login } = useAuthFormsData();
  const { loggedIn } = user;
  const { text, ...sansText } = rest;
  const { md } = breakpoints;
  const canDoPopup = md && !isIE;

  const openMessage = (html, color) => {
    dispatch({
      type: "openMessage",
      copy: { html },
      bgColorClass: color,
      timeout: 5000,
    });
  };

  const onLogin = ({ ticket, error }) => {
    if (ticket) {
      // If the ticket equals `showRegistrationForm` then the user has opted
      // to register instead of log-in. In that case we should pop open
      // the registration modal.
      if (ticket === "showRegistrationForm") {
        dispatch({
          type: "openModal",
          name: "registerModal",
          tabs: [{ title: t("Create new account"), name: "registerModal" }],
        });
      } else {
        dispatch({
          type: "toggleLogin",
          ticket,
          loggedIn: true,
        });
        openMessage(login.message.html, "bg-blue-5");
      }
    } else if (error) {
      openMessage(`<p>${error}</p>`, "bg-red");
    }
  };

  // We're handling logins by redirecting to the DIG drupal site.
  // Users login on DIG, and then are redirected back to COR
  // with a `ticket` query parameter that we can use to verify the user.
  const doLogin = url => {
    if (!canDoPopup) {
      // On small screens, redirect in the same window to the DIG
      // login screen. Users are redirected back to COR and the ticket
      // is verified in AuthenticationCheck.
      window.open(url, "_self");
    } else {
      // On larger screens, open a separate window to the DIG login screen.
      // DIG redirects back to COR in the separate window, which sets the ticket
      // `window.value`. OauthPopup polls for that value on an interval, and when
      // it reads it, calls the function passed to `popup.show` with that value.
      const popup = new OauthPopup(url);
      popup.show(onLogin);
    }
  };

  // The logout
  const doLogout = url => {
    dispatch({
      type: "toggleLogin",
      loggedIn: false,
    });

    if (!canDoPopup) {
      window.open(url, "_self");
    } else {
      const popup = new OauthPopup(url);
      popup.show();
    }
  };

  const handleClick = () => {
    // Close modal if open
    dispatch({ type: "closeModal" });

    // Netlify overrides local env vars so have to run a bit of gross logic here
    // to get the localhost siteUrl if we're in development
    const siteUrl =
      window.location.origin === "http://localhost:3000"
        ? "http://localhost:3000"
        : process.env.GATSBY_SITE_URL;

    const redirectUrl = canDoPopup
      ? `${siteUrl}/AuthCallback/`
      : `${siteUrl}${window.location.pathname}`;

    if (loggedIn) {
      doLogout(`${process.env.GATSBY_DRUPAL_SITE_URL}/cas/logout`);
    } else if (modalName === "signInModal") {
      doLogin(
        `${process.env.GATSBY_DRUPAL_SITE_URL}/cas/login?service=${redirectUrl}`
      );
    } else {
      dispatch({
        type: "openModal",
        name: "registerModal",
        tabs: [{ title: t("Create new account"), name: "registerModal" }],
      });
    }
  };

  // re-hydration fix :(
  // Note: before hydration occurs, I'm returning a hidden button
  // so that the DOM doesn't jitter when the actual button is loaded in
  const [rendered, setRendered] = useState();
  useEffect(() => setRendered(true));
  if (!rendered) {
    return <Button {...sansText} text="" onClick={null} />;
  }

  if (loggedIn && !showLogout) {
    return null;
  }

  return (
    <Button
      {...sansText}
      text={loggedIn && showLogout ? t("logout") : text}
      onClick={handleClick}
    />
  );
};

AuthButton.propTypes = {
  ...buttonProps.props,
  modalName: PropTypes.string.isRequired,
  showLogout: PropTypes.bool,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

AuthButton.defaultProps = {
  ...buttonProps.defaults,
  showLogout: false,
  style: {},
};

export default AuthButton;
