import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Display wysiwyg html copy
/* eslint react/no-danger: 0 */

const RichText = ({ html, className: _className }) => {
  const className = classNames(
    {
      RichText: true,
      "list-reset m-0 p-0": true,
    },
    _className
  );

  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
  );
};

RichText.propTypes = {
  html: PropTypes.string.isRequired,
  className: PropTypes.string,
};

RichText.defaultProps = {
  className: "",
};

export default RichText;
