import React from "react";
import classNames from "classnames";
import Text from "../Text";
import Icon from "../Icon";
import buttonProps from "./propTypes";

const Button = ({
  text,
  iconName,
  type,
  preset,
  size,
  onClick,
  disabled,
  orientation,
  className: _className,
  style,
  iconClassName,
  applySentenceCase,
}) => {
  // Main wrapper styles
  const className = classNames(
    {
      "inline-flex flex-col outline-none focus:outline-none rounded-full items-center justify-center transition-fast": true,
      "h-10": size === "default",
      "h-6": size === "sm",
      "h-5": size === "xs",
      "px-2": (size === "sm" || size === "xs") && preset !== "empty",
      "px-4 shadow-card": preset !== "empty" && size === "default",
      "bg-blue hover:bg-blue-5 text-white": preset === "blue",
      "bg-white hover:bg-gray-2 text-blue": preset === "white",
      "bg-blue-1 hover:bg-blue-2 text-gray": preset === "lightBlue",
      "bg-blue-4 hover:bg-blue-5 text-white": preset === "darkBlue",
      "bg-gray-1 hover:bg-gray-2 text-gray-5 hover:text-gray":
        preset === "lightGray",
      "group bg-transparent p-0": preset === "empty",
      "border-1 border-current": preset === "bordered",
      "opacity-50 pointer-events-none": disabled,
    },
    _className
  );

  // Click-handler
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const acronyms = ["cor"];

  const makeSentenceCase = _text => {
    // first make whole string lowercase
    let str = _text.toLowerCase();

    // then make any acronyms uppercase
    str = str
      .split(" ")
      .map(word => (acronyms.includes(word) ? word.toUpperCase() : word))
      .join(" ");

    // then capitalize first word
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    // eslint doesn't allow dynamically setting the type
    // eslint-disable-next-line react/button-has-type
    <button
      type={type}
      className={className}
      onClick={handleClick}
      disabled={disabled}
      style={style}
    >
      <div
        className={classNames({
          "flex items-center": true,
          "flex-row-reverse": orientation === "textLeft",
          capitalize: !applySentenceCase,
        })}
      >
        {iconName && (
          <Icon
            name={iconName}
            className={`${iconClassName} ${
              orientation === "textRight" ? "mr-2" : "ml-2"
            }`}
          />
        )}
        <Text preset="button" variant={size} tag="span" className="group">
          {applySentenceCase ? makeSentenceCase(text) : text}
        </Text>
      </div>
      {preset === "empty" && (
        <div className="w-full h-2px opacity-50 bg-current group-hover:opacity-100 transition-fast" />
      )}
    </button>
  );
};

Button.propTypes = buttonProps.props;

Button.defaultProps = buttonProps.defaults;

export default Button;
