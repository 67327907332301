import React, { useState } from "react";
import { useAppState } from "@state/state";
import { Text, Icon, Button, RichText } from "@ui";
import { useAuthFormsData } from "@staticQueries";
import Input from "./Input";
import { t } from "@translations";

const SignInForm = () => {
  const { signInHeading, footerNote } = useAuthFormsData();
  const [{ modal }, dispatch] = useAppState();
  const [formData, setFormData] = useState({
    username: "test@example.com",
    password: "password",
  });
  const [loading, setLoading] = useState(false);

  const handleFormSubmission = event => {
    event.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);

    setTimeout(() => {
      dispatch({
        type: "toggleLogin",
        loggedIn: true,
        token: "test_token",
      });
      dispatch({ type: "closeModal" });
      setTimeout(() => {
        dispatch({ type: "clearModal" });
      }, 300);
      dispatch({
        type: "openMessage",
        copy: { html: "You have been successfully logged-in." },
        bgColorClass: "bg-green",
        timeout: 5000,
      });
    }, 1000);
  };

  return (
    <form id="signin-form" onSubmit={handleFormSubmission}>
      <div className="flex items-center mb-6">
        <Icon className="w-12 h-12 text-red-dig mr-4 p-1" name="DIG" />
        <Text preset="h2" className="flex-1">
          {signInHeading}
        </Text>
      </div>

      <div className="max-w-sm mb-8">
        <Input
          name="email"
          type="email"
          label={t("email or username")}
          placeholder="text"
          isRequired
          helpText={t("Enter your DIG email or username")}
          value={formData.username}
          onChange={event =>
            setFormData({ ...formData, username: event.target.value })
          }
          preset="gray"
          className="mb-4"
        />

        <Input
          name="password"
          type="password"
          label={t("password")}
          placeholder="text"
          isRequired
          helpText={t("Enter your DIG password")}
          value={formData.password}
          onChange={event =>
            setFormData({ ...formData, password: event.target.value })
          }
          preset="gray"
          className="mb-4"
        />
      </div>

      <div className="flex mb-8">
        <Button
          type="submit"
          preset="blue"
          text={t("Sign in")}
          className="w-32"
          disabled={loading}
        />
        <Button
          preset="empty"
          text={t("reset password")}
          className="text-blue ml-8"
          onClick={() =>
            dispatch({
              type: "openModal",
              name: "resetPasswordForm",
              tabs: modal.tabs,
            })
          }
        />
      </div>

      <RichText
        html={footerNote.html}
        className="RichText RichText--legaleze max-w-sm"
      />
    </form>
  );
};

export default SignInForm;
