const reducer = (state, action) => {
  switch (action.type) {
    case "setHeaderTheme":
      return {
        ...state,
        header: action.headerTheme,
      };
    default:
      return state;
  }
};

const initialState = {
  header: {
    bgColor: "blue",
    showSearch: true,
    lockup: {
      size: "large",
      color: "blue",
    },
  },
};

export default {
  reducer,
  initialState,
};
