import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveImageData from "../dataResolvers/resolveImageData";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query HomeTestimonialQuery {
    prismicHomePage {
      data {
        partners_testimonial {
          text {
            html
          }
          person
          photo {
            alt
            dimensions {
              height
              width
            }
            url
          }
          role
          image {
            alt
            dimensions {
              height
              width
            }
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicHomePage }) => {
  const { partners } = resolveByPrefix(prismicHomePage.data, [
    "partners_",
  ]).prefixed;
  const { photo, image, ...rest } = partners.testimonial[0];

  const resolved = {
    photo: resolveImageData({ image: photo }),
    image: resolveImageData({ image }),
    ...rest,
  };

  return keysToCamel(resolved);
};

const useHomeTestimonialData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useHomeTestimonialData;
