import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import PropTypes from "prop-types";
import RichText from "../RichText";
import CarouselNav from "./CarouselNav";

const Carousel = ({
  children,
  infinite,
  stagePadding,
  showButtons,
  showDots,
  overflowVisible,
  captions,
  className,
}) => {
  const [methods, setMethods] = useState({});
  const [height, setHeight] = useState("auto");
  const [currentSlide, setCurrentSlide] = useState(0);
  const el = useRef({});

  const slides = React.Children.map(children, child =>
    React.cloneElement(child, {
      ...child.props,
      // any other handlers/props for each slide goes here
    })
  );

  const hasCaptions = captions.filter(caption => caption).length > 0;
  const longestCaption =
    hasCaptions && captions.reduce((a, b) => (a.length > b.length ? a : b));

  useEffect(() => {
    const { slideTo, slideNext, slidePrev } = el.current;
    setMethods({
      slideTo,
      slideNext,
      slidePrev,
    });
  }, []);

  const doSetHeight = () => {
    // this is a hack to set the height in px of the carousel
    // need timeout for carousel to render
    setTimeout(() => {
      if (el.current && el.current.rootComponent) {
        setHeight(`${el.current.rootComponent.clientHeight}px`);
      }
    }, 33);
  };

  return (
    <>
      <div
        className={classNames(
          {
            "Carousel relative": true,
            "Carousel--overflow-visible": overflowVisible,
          },
          className
        )}
        style={{
          paddingLeft: stagePadding,
          paddingRight: stagePadding,
          height,
        }}
      >
        <AliceCarousel
          ref={el}
          mouseDragEnabled
          dotsDisabled
          buttonsDisabled
          infinite={infinite}
          onInitialized={doSetHeight}
          onResized={doSetHeight}
          onSlideChanged={e => setCurrentSlide(e.item)}
        >
          {slides}
        </AliceCarousel>

        <CarouselNav
          showButtons={showButtons}
          showDots={showDots}
          currentSlide={currentSlide}
          totalSlides={slides.length}
          infinite={infinite}
          slideTo={methods.slideTo}
          slideNext={methods.slideNext}
          slidePrev={methods.slidePrev}
        />
      </div>

      {hasCaptions && (
        <div className="relative mt-3 px-4 lg:px-0">
          {/* Add an empty p with the longest caption so the DOM flow has the correct height */}
          <p className="opacity-0">{longestCaption}</p>

          {captions.map((caption, idx) => (
            <RichText
              key={idx}
              html={caption}
              className={classNames({
                "absolute top-0 left-0 italic text-xs text-gray px-4 lg:px-0 transition": true,
                "opacity-0": idx !== currentSlide,
                "opacity-100": idx === currentSlide,
              })}
            />
          ))}
        </div>
      )}
    </>
  );
};

Carousel.propTypes = {
  children: PropTypes.node,
  infinite: PropTypes.bool,
  stagePadding: PropTypes.number,
  showButtons: PropTypes.bool,
  showDots: PropTypes.bool,
  overflowVisible: PropTypes.bool,
  captions: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

Carousel.defaultProps = {
  children: [],
  infinite: true,
  stagePadding: 0,
  showButtons: true,
  showDots: true,
  overflowVisible: false,
  captions: [],
  className: "",
};

export default Carousel;
