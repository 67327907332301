import React from "react";
import { useAppState } from "@state/state";
import CORLogo from "@svg/logo-footer.svg";
import DIGLogo from "@svg/dig-logo-white.svg";
import DonationHand from "@svg/donation-hand.svg";
import { useFooterData } from "@staticQueries";
import {
  Text,
  RichText,
  Button,
  AuthButton,
  Icon,
  AppLink,
  Container,
} from "@ui";
import { NewsletterForm } from "@forms";
import { t } from "@translations";

const Footer = () => {
  const {
    menu,
    socialMedia,
    emailAddress,
    newsletterPrompt,
    donationPrompt,
    ctaLink,
    legal,
    ctaText,
  } = useFooterData();

  const [{ user }] = useAppState();

  // split the menu into two columns
  const menu1 = menu.slice(0, Math.ceil(menu.length / 2));
  const menu2 = menu.slice(menu1.length);

  return (
    <footer className="bg-blue pt-16 pb-6 text-white md:border-t-1/2rem md:rounded-b-lg">
      <Container className="flex flex-col items-center lg:flex-row">
        <div className="lg:w-1/4 lg:pr-16 flex flex-col items-start">
          <div className="flex flex-col flex-auto items-center">
            <div>
              <CORLogo />
            </div>

            <div className="bg-white rounded-full my-6 px-2 py-1">
              <Text
                preset="label"
                tag="span"
                className="font-bold text-gray leading-none"
              >
                {t("Developed by")}
              </Text>
            </div>

            <div>
              <DIGLogo className="w-full max-w max-w-xxs fill-current p-4" />
            </div>

            <div className="flex flex-auto justify-center items-end pt-4">
              {socialMedia.map(({ id, platform, url }) => (
                <AppLink
                  key={id}
                  to={url}
                  target="_blank"
                  className="block w-8 h-8 mx-1"
                >
                  <Icon name={platform} className="w-8 h-8" />
                </AppLink>
              ))}
            </div>
          </div>
        </div>

        <div className="lg:w-3/4 flex flex-col">
          <div className="lg:flex">
            <div className="lg:w-1/2 flex items-start lg:pr-6 text-center lg:text-left mt-8 mb-4 lg:mt-0 lg:mb-0">
              <ul className="w-1/2 list-reset">
                {menu1.map(({ id, url, title }) => (
                  <React.Fragment key={id}>
                    <li className="w-full mb-3">
                      <AppLink to={url} className="inline-block">
                        <span className="block font-sans font-bold text-sm border-b-2 border-transparent hover:border-current transition">
                          {title}
                        </span>
                      </AppLink>
                    </li>
                  </React.Fragment>
                ))}
              </ul>
              <ul className="w-1/2 list-reset">
                {menu2.map(({ id, url, title }) => (
                  <React.Fragment key={id}>
                    <li className="w-full mb-3">
                      <AppLink to={url} className="inline-block">
                        <span className="block font-sans font-bold text-sm border-b-2 border-transparent hover:border-current transition">
                          {title}
                        </span>
                      </AppLink>
                    </li>
                  </React.Fragment>
                ))}
                {!user.loggedIn && (
                  <li className="w-full mb-3">
                    <AuthButton
                      text={`${t("Sign in")} / ${t("Sign up")}`}
                      preset="empty"
                      className="text-white -my-3"
                      modalName="signInModal"
                      applySentenceCase={false}
                    />
                  </li>
                )}
              </ul>
            </div>

            <div className="max-w-xs mx-auto lg:max-w-none lg:mx-0 lg:w-1/2 lg:pl-6">
              <RichText
                html={newsletterPrompt.html}
                className="font-sans text-white text-sm mb-4 lg:mb-6"
              />

              <NewsletterForm />
            </div>
          </div>

          <div className="max-w-xs mx-auto lg:mx-0 lg:max-w-none lg:flex-auto flex items-center my-8 lg:mt-6 lg:mb-10">
            <div className="w-full border-2 border-gray rounded-full flex items-center p-2 overflow-hidden">
              <div className="w-36 lg:w-16 -ml-3 mr-4 lg:mr-6 h-12">
                <DonationHand />
              </div>
              <RichText
                html={donationPrompt.html}
                className="text-white text-sm mr-4"
              />
              <a
                href={ctaLink.url}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-auto"
              >
                <Button preset="white" text={ctaText} />
              </a>
            </div>
          </div>

          <div className="flex flex-col items-center md:flex-row">
            <div>
              <Text preset="p" tag="span" className="leading-none text-sm mr-1">
                {t("Email us at")}
              </Text>
              <a
                href={`mailto:${emailAddress}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text
                  preset="p"
                  tag="span"
                  className="font-sans font-bold leading-none text-sm"
                >
                  {emailAddress}
                </Text>
              </a>
            </div>

            <div className="mt-4 ml-3 flex flex-col md:flex-row w-full md:w-auto md:mt-0 md:ml-auto md:pl-3 text-right items-center">
              <ul className="flex flex-col md:flex-row">
                {legal &&
                  legal.map(({ id, url, title }) => (
                    <React.Fragment key={id}>
                      <li className="w-full mr-3 my-3">
                        <AppLink to={url} className="inline-block">
                          <span className="block font-sans font-bold text-sm border-b-2 border-transparent hover:border-current transition">
                            {title}
                          </span>
                        </AppLink>
                      </li>
                    </React.Fragment>
                  ))}
              </ul>
              <Text preset="p" tag="span" className="text-sm leading-none">
                &copy; Digital Inquiry Group
              </Text>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
