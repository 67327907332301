import PropTypes from "prop-types";

const props = PropTypes.shape({
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  fx: PropTypes.string,
  fy: PropTypes.string,
  filter: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  caption: PropTypes.shape({
    html: PropTypes.string,
  }),
});

const defaults = {
  alt: "",
  fx: "center",
  fy: "center",
  filter: null,
  width: 0,
  height: 0,
  caption: null,
};

export default {
  props,
  defaults,
};
