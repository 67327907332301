const reducer = (state, action) => {
  switch (action.type) {
    case "selectCard":
      return {
        ...state,
        selectedCard: action.cardId,
      };
    case "deSelectCard":
      return {
        ...state,
        selectedCard: null,
      };

    default:
      return state;
  }
};

const initialState = {
  selectedCard: null,
};

export default {
  reducer,
  initialState,
};
