import React from "react";
import PropTypes from "prop-types";
import Image from "./Image/ImageWrap";

// PDFThumbnail - use Imgix to render a screenshot of a particular page within a PDF

const PDFThumbnail = ({ url, aspectRatio, alt, page, className }) => {
  const image = {
    url: `${url}?page=${page}`,
    alt,
    dimensions: {
      width: 0,
      height: 0,
    },
  };

  return (
    <div className={className}>
      <div
        className="relative w-full"
        style={{ paddingBottom: `${100 / aspectRatio}%` }}
      >
        <div className="absolute inset-0">
          <Image image={image} />
        </div>
      </div>
    </div>
  );
};

PDFThumbnail.propTypes = {
  url: PropTypes.string.isRequired,
  aspectRatio: PropTypes.number,
  alt: PropTypes.string,
  page: PropTypes.number,
  className: PropTypes.string,
};

PDFThumbnail.defaultProps = {
  aspectRatio: 8.5 / 11,
  alt: "pdf",
  page: 1,
  className: "",
};

export default PDFThumbnail;
