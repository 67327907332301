/* eslint-disable global-require */
module.exports = {
  theme: {
    fontSize: {
      xxs: ".667rem",
      xs: ".778rem",
      sm: ".889rem",
      base: "1rem",
      lg: "1.122rem",
      xl: "1.333rem",
      "2xl": "1.778rem",
      "3xl": "2.22rem",
      "4xl": "2.889rem",
    },
    screens: {
      xs: { max: "400px" }, // target small screens only
      sm: "552px", // small card * 2
      cb: "742px", // content-builder
      md: "840px", // small card * 3
      "cb-lg": "1030px",
      lg: "1128px", // small card * 4; main container width
      xl: "1700px", // larger than 15" macbook pro
      full: "100%",
    },
    fontFamily: {
      sans: ["Source Sans Pro", "sans-serif"],
      serif: ["Spectral", "serif"],
    },
    extend: {
      fill: theme => theme("colors"),
      stroke: theme => theme("colors"),
      spacing: {
        100: "100%",
      },
      letterSpacing: {
        // calculated to px value of # @ 18px
        tight: "-0.055rem",
      },
      lineHeight: {
        none: 1,
        clamped: 1.2,
        tight: 1.2727,
        firm: 1.333,
        wiggly: 1.42,
        loose: 1.75,
        gaping: 2,
      },
      minWidth: theme => {
        return {
          0: 0,
          4: "1rem",
          ...theme("screens"),
        };
      },
      maxWidth: theme => {
        // build max-width values from the screens definition,
        // but exclude the xs screen which won't map properly.
        // Plus, we have a different xs value for maxWidth
        const { xs, ...screens } = theme("screens");
        return {
          none: "none",
          xxs: "200px",
          xs: "360px", // note: using different xs value than defined in screens
          ...screens,
        };
      },
      minHeight: {
        "100vh": "100vh",
      },
      width: {
        7: "1.75rem",
        36: "9rem",
        "1/4": "25%",
        "1/3": "33.333%",
        "1/2": "50%",
        "card-offset": "calc(33.333% - 1rem)",
      },
      height: {
        "2px": "2px",
        "1/2": "50%",
      },
      colors: {
        current: "currentColor",
        white: {
          default: "#FFFFFF",
        },
        gray: {
          default: "#232426",
          1: "#EFF0F4",
          2: "#B9BDCC",
          "2-20": "#B9BDCC33",
          3: "#A2A6B3",
          4: "#737680",
          5: "#4C4E54",
          6: "#232426",
        },
        blue: {
          default: "#0D49FF",
          "0": "#DAE3FF",
          1: "#B4C7FF",
          "1-20": "#B4C7FF33",
          2: "#5983FF",
          3: "#0D49FF",
          4: "#173799",
          "4-50": "#17379980",
          5: "#04164D",
          screen: "rgba(9, 22, 140, 0.4)",
          "screen-full": "rgba(9, 22, 140, 0.9)",
        },
        red: {
          default: "#FF585D",
          1: "#FFD6D7",
          2: "#FFA6A8",
          3: "#FF585D",
          4: "#CC464A",
          5: "#73282A",
          dig: "#FF464A",
        },
        green: {
          default: "#29CB7E",
          1: "#CBF2E0",
          2: "#8AE6BA",
          3: "#29CB7E",
          4: "#1F995F",
          5: "#33664E",
        },
        yellow: {
          default: "#FFCD00",
          1: "#FFF4C4",
          2: "#FFE98C",
          3: "#FFCD00",
          4: "#CCA400",
          5: "#99842E",
        },
      },
      opacity: {
        10: ".1",
        25: ".25",
        40: ".4",
        50: ".5",
        90: ".9",
      },
      borderWidth: {
        1: "1px",
        rem: "1",
        "1/2rem": ".5rem",
      },
      borderRadius: {
        default: ".67rem",
        "8px": "8px",
        sm: ".33rem",
        lg: "1.33rem",
      },
      translate: {
        "-100": "-100%",
        "100": "100%",
        "-50": "-50%",
        "50": "50%",
        "25": "25%",
        "-25": "-25%",
        "header-hide": "calc(-100% - 1rem)",
        center: ["-50%", "-50%"],
      },
      transitionDuration: {
        0: "0s",
      },
      scale: {
        0: 0,
        50: 0.5,
        80: 0.8,
        90: 0.9,
        100: 1,
        102: 1.02,
        103: 1.03,
        105: 1.05,
        110: 1.1,
        125: 1.25,
      },
      boxShadow: {
        header: "0 3px 3px rgba(0,0,0,.07)",
        card: "0 2px 13px 0 rgba(0,0,0,0.12)",
        "card-lg": "0 2px 16px 0 rgba(0,0,0,0.12)",
      },
      inset: {
        1: ".25rem",
        2: ".5rem",
        4: "1rem",
        6: "1.5rem",
        8: "2rem",
        "1/2": "50%",
        "-16": "-4rem",
      },
      zIndex: {
        penult: 9998,
        max: 9999,
        "super-max": 99999,
      },
    },
  },
  variants: {
    opacity: ["hover", "group-hover", "responsive"],
    textColor: ["hover", "group-hover", "responsive"],
    maxWidth: ["responsive"],
    scale: ["hover", "group-hover", "responsive"],
    flex: ["responsive"],
  },
  plugins: [
    require("tailwindcss-transition")({
      standard: "all .333s ease-in-out",
      transitions: {
        fast: "all .166s ease-in-out",
        slow: "all .666s ease-in-out",
        mollasses: "all 1s ease-in-out",
        opacity: "opacity .333s ease-in-out",
        expo: "all .333s cubic-bezier(0.19, 1, 0.22, 1)",
        "slow-expo": "all 1s cubic-bezier(0.19, 1, 0.22, 1)",
        "md-expo": ".667s cubic-bezier(0.19, 1, 0.22, 1)",
        delayed: "all .33s ease-in-out .15s",
        "research-viz":
          "opacity .667s ease-in-out, transform 1s ease-in-out .667s",
        "home-animation-cta":
          "opacity .67s ease-in-out 1s, transform .67s cubic-bezier(0.175, 0.885, 0.32, 2) 1s",
      },
    }),
    require("tailwindcss-transforms")({
      "3d": false, // defaults to false
    }),
  ],
};
