import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import useWindowSize from "@designbycosmic/cosmic-react-resize-hook";
import { RichText, Button, Icon, AppLink, RandomShapes, Container } from "@ui";

const Message = ({
  id,
  copy,
  bgColorClass,
  ctaLink,
  ctaText,
  timeout,
  showing,
  onClose,
  height,
  isStatic,
}) => {
  const [{ breakpoints, messages }, dispatch] = useAppState();

  const el = useRef({});
  const _timeout = useRef({});
  const { innerWidth } = useWindowSize();

  const closeMessage = () => {
    if (_timeout.current) {
      clearTimeout(_timeout.current);
    }

    dispatch({
      type: "closeMessage",
      idx: messages.messages.findIndex(message => message.id === id),
    });

    if (onClose) {
      onClose();
    }

    setTimeout(() => {
      dispatch({
        type: "removeMessage",
        idx: messages.messages.findIndex(message => message.id === id),
      });
    }, 667);
  };

  useEffect(() => {
    if (timeout > 0 && showing) {
      _timeout.current = setTimeout(closeMessage, timeout);
    }
  }, [timeout, showing]);

  useEffect(() => {
    dispatch({
      type: "setMessageHeight",
      idx: messages.messages.findIndex(message => message.id === id),
      height: el.current.scrollHeight,
    });
  }, [innerWidth, showing]);

  const className = classNames({
    "overflow-hidden": true,
    "relative transition-slow z-max": isStatic,
    "fixed top-0 left-0 right-0 transition-expo z-super-max": !isStatic,
    "pointer-events-none": !showing,
    "pointer-events-auto": showing,
    [`${bgColorClass}`]: true,
    "text-white": !bgColorClass.split("-").includes("yellow"),
    "text-gray": bgColorClass.split("-").includes("yellow"),
  });

  return (
    <div
      ref={el}
      className={className}
      style={{ maxHeight: showing ? `${height}px` : 0 }}
    >
      <RandomShapes
        seed={600}
        numShapes={breakpoints.sm ? 9 : 9}
        opacity={bgColorClass === "bg-blue-4" ? 0.05 : undefined}
      />
      <Container className="relative z-10 flex flex-col lg:flex-row lg:items-center py-4 lg:py-6 pr-8">
        {copy.html && (
          <RichText
            html={copy.html}
            className="RichText RichText--sans RichText--message"
          />
        )}

        {ctaText && ctaLink.url && (
          <AppLink
            to={ctaLink.url}
            target={ctaLink.target}
            className="flex-0 lg:ml-12"
          >
            {ctaText.html ? (
              <RichText
                html={ctaText.html}
                className="RichText--button text-white"
              />
            ) : (
              <Button preset="empty" text={ctaText} />
            )}
          </AppLink>
        )}
      </Container>
      <button
        type="button"
        className="outline-none focus:outline-none absolute right-6 pos-center-y w-4 h-4 z-10"
        onClick={closeMessage}
      >
        <Icon name="x" />
      </button>
    </div>
  );
};

Message.propTypes = {
  id: PropTypes.number.isRequired,
  showing: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  isStatic: PropTypes.bool.isRequired,
  copy: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }),
  bgColorClass: PropTypes.string,
  ctaLink: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.string,
  }),
  ctaText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      html: PropTypes.string,
    }),
  ]),
  timeout: PropTypes.number,
  onClose: PropTypes.func,
};

Message.defaultProps = {
  copy: {},
  bgColorClass: "bg-blue-4",
  ctaLink: {},
  ctaText: "",
  timeout: 0,
  onClose: null,
};

export default React.memo(Message);
