import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Text, imageProps, Button, RichText, AppLink } from "@ui";
import SingleCardImage from "./SingleCardImage";
import { useMetaData } from "@staticQueries";
import { t } from "@translations";
import { getTailwindUtility } from "@utils";

const SingleCard = ({
  slug,
  type,
  title,
  summary,
  date,
  image,
  categories,
  ctaText,
  forceSmall,
  hideImage,
  showBorder,
  className: _className,
}) => {
  const [rendered, setRendered] = useState();
  const { defaultMetaImage: defaultImage } = useMetaData();
  const [{ breakpoints }] = useAppState();
  const { sm, md } = breakpoints;

  const colors = getTailwindUtility("colors");
  const filter =
    type === "blog" || type === "videos"
      ? colors.blue["3"].replace("#", "")
      : null;
  const small = !sm || forceSmall;

  useEffect(() => setRendered(true), []);

  return (
    <div
      id={slug}
      className={`p-3 transition-opacity ${_className}`}
      style={{ opacity: rendered ? 1 : 0 }}
    >
      <AppLink
        to={type === "news" ? `/in-the-news/${slug}` : `/${type}/${slug}`}
        className={classNames({
          "flex h-full overflow-hidden": true,
          "flex-col": small,
          "flex-row": !small,
          "rounded-lg": !hideImage,
          "border-1 border-gray-1": !hideImage && showBorder,
          "border-t-4 border-blue": hideImage && showBorder,
        })}
      >
        {!hideImage && (
          <div
            className={classNames({
              "overflow-hidden": true,
              "w-full rounded-t-lg image-overflow-mask ": small,
              "sm:w-1/2 md:w-1/3 lg:w-1/4 rounded-lg":
                !small && type !== "news",
              "sm:w-1/2 md:w-1/3 lg:w-card-offset rounded-lg":
                !small && type === "news",
            })}
          >
            <SingleCardImage
              image={image ? { ...image, filter } : defaultImage}
              type={type}
              short={small}
            />
          </div>
        )}

        <div
          className={classNames({
            "flex flex-col p-4 sm:p-6": true,
            "flex-1": !small,
            "flex-auto": small,
            "bg-gray-1": type === "news" || hideImage,
            "bg-white": type !== "news" && !hideImage,
          })}
        >
          <div className="flex flex-wrap items-center mb-4">
            {categories.map(({ id, title: catTitle }) => (
              <div
                key={id}
                className={classNames({
                  "inline-flex items-center rounded-full h-5 px-2 my-1 mr-2": true,
                  "bg-gray-1": !hideImage,
                  "bg-white": hideImage,
                })}
              >
                <Text
                  preset="label"
                  variant="sm"
                  className="font-bold text-gray-5 capitalize"
                >
                  {catTitle}
                </Text>
              </div>
            ))}
            <Text
              preset="label"
              size="sm"
              className="font-bold text-blue uppercase my-1"
            >
              {date}
            </Text>
          </div>

          <Text
            preset="h3"
            variant={md && !forceSmall && type === "news" ? "lg" : "default"}
            className="flex-auto"
          >
            {title}
          </Text>

          {summary && md && !forceSmall && (
            <RichText html={summary.html} className="mt-4 truncate-3" />
          )}

          <div className="mt-4">
            <Button preset="empty" text={t(ctaText)} className="text-blue" />
          </div>
        </div>
      </AppLink>
    </div>
  );
};

SingleCard.propTypes = {
  slug: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["blog", "news", "videos", "research"]).isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }),
  date: PropTypes.string.isRequired,
  image: imageProps.props,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  ctaText: PropTypes.string.isRequired,
  forceSmall: PropTypes.bool,
  hideImage: PropTypes.bool,
  showBorder: PropTypes.bool,
  className: PropTypes.string,
};

SingleCard.defaultProps = {
  summary: null,
  image: null,
  categories: [],
  forceSmall: false,
  hideImage: false,
  showBorder: true,
  className: "",
};

export default SingleCard;
