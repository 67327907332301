import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Section is used to separate content vertically, often
// with a different background color

const Section = ({
  hasPaddingTop,
  hasPaddingBottom,
  size,
  allowOverflow,
  className: _className,
  children,
}) => {
  // if we passed a custom `className` string then use that,
  // else build it based on other props
  const className = classNames(
    {
      relative: true,
      "overflow-hidden": !allowOverflow,
      "pt-10 sm:pt-12 md:pt-24": hasPaddingTop && size === "lg",
      "pb-10 sm:pb-12 md:pb-24": hasPaddingBottom && size === "lg",
      "pt-6 sm:pt-10": hasPaddingTop && size === "sm",
      "pb-6 sm:pb-10": hasPaddingBottom && size === "sm",
    },
    _className
  );

  return <section className={className}>{children}</section>;
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  hasPaddingTop: PropTypes.bool,
  hasPaddingBottom: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "lg"]),
  allowOverflow: PropTypes.bool,
  className: PropTypes.string,
};

Section.defaultProps = {
  hasPaddingTop: true,
  hasPaddingBottom: true,
  size: "lg",
  allowOverflow: false,
  className: "",
};

export default Section;
