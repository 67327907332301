import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Text } from "@ui";

// Input -- form input layout + change/blur handling

const Input = ({
  type,
  placeholder,
  name,
  value,
  label,
  helpText,
  isRequired,
  isDisabled,
  autoComplete,
  onChange,
  onFocus,
  onBlur,
  onPressEnter,
  hasError,
  preset,
  bgColor: _bgColor,
  className: _className,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const input = useRef(null);

  const toggleFocus = event => {
    if (!isFocused && onFocus) {
      onFocus(event);
    } else if (isFocused && onBlur) {
      onBlur(event);
    }
    setIsFocused(!isFocused);
  };

  const handleChange = event => {
    if (onChange) {
      onChange(event);
    }
  };

  const onKeyDown = event => {
    if (onPressEnter && event.key === "Enter") {
      onPressEnter();
      input.current.blur();
    }
  };

  const className = classNames(
    {
      "w-full": true,
      "text-blue": preset === "blue",
      "text-white": preset === "white",
      "text-gray": preset === "gray",
      "opacity-50 pointer-events-none": isDisabled,
    },
    _className
  );

  const borderColor = preset === "gray" ? "border-gray-2" : "border-current";
  const bgColor = _bgColor || "bg-transparent";

  const inputClassName = classNames({
    "w-full h-10 block font-sans outline-none px-3 text-sm truncate": true,
    [bgColor]: true,
    "border-2 rounded-sm": preset !== "empty",
    [`${borderColor} text-current`]: !isFocused,
    "border-blue-2": isFocused && preset === "white",
    "border-white text-white": isFocused && preset === "blue",
    "border-red": hasError,
  });

  return (
    <div className={`Input ${className}`}>
      {label && (
        <Text
          preset="label"
          className="block font-bold text-current sentence-case mb-1"
        >
          {label}
        </Text>
      )}

      <input
        className={inputClassName}
        ref={input}
        type={type}
        name={name}
        title={label}
        value={value}
        placeholder={placeholder}
        required={isRequired}
        disabled={isDisabled}
        autoComplete={autoComplete}
        onChange={handleChange}
        onFocus={toggleFocus}
        onBlur={toggleFocus}
        onKeyDown={onKeyDown}
        size={placeholder.length + 1}
      />

      {helpText && (
        <Text preset="p" className="italic text-sm mt-1">
          {helpText}
        </Text>
      )}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  helpText: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autoComplete: PropTypes.oneOf(["on", "off"]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onPressEnter: PropTypes.func,
  hasError: PropTypes.bool,
  preset: PropTypes.oneOf(["blue", "white", "gray", "empty"]),
  bgColor: PropTypes.string,
  className: PropTypes.string,
};

Input.defaultProps = {
  type: "text",
  placeholder: "",
  label: null,
  helpText: null,
  isRequired: true,
  isDisabled: false,
  autoComplete: "on",
  onChange: null,
  onFocus: null,
  onBlur: null,
  onPressEnter: null,
  hasError: false,
  preset: "white",
  bgColor: null,
  className: "",
};

export default Input;
