import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Text, Icon, Button, RichText } from "@ui";
import { useAuthFormsData } from "@staticQueries";
import {
  states,
  countries,
  cityOverrides as _cityOverrides,
} from "./localities";
import { roles, subjects, levels } from "./profileOptions";
import Input from "../Input";
import FormSlider from "../FormSlider";
import ValidatedEmailInput from "../ValidatedEmailInput";
import Select from "../Select";
import Checkbox from "../Checkbox";
import { t } from "@translations";

const RegisterForm = ({ onSubmit, formData, setFormData, loading }) => {
  const { registration } = useAuthFormsData();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [emailIsValid, setEmailIsValid] = useState(false);

  // see if there's a set of city overrides for the selected country
  const cityOverrides = _cityOverrides[formData.country];

  // validate ready to move to step 2
  const canAdvance =
    formData.firstName &&
    formData.lastName &&
    formData.password &&
    emailIsValid;

  // Unset state if US not selected
  useEffect(() => {
    if (formData.country !== "US" && formData.state) {
      setFormData({ ...formData, state: "" });
    }
  }, [formData.country]);

  return (
    <form id="register-form" onSubmit={onSubmit}>
      <FormSlider
        currentSlide={currentSlide}
        scrollableId="ModalOverflowContainer"
      >
        {/* Slide 1 */}
        <div>
          <div className="flex items-center mb-6">
            <Icon className="w-12 h-12 text-red-dig mr-4 p-1" name="DIG" />
            <Text preset="h2" className="flex-1">
              {registration.heading}
            </Text>
          </div>

          <div className="md:flex md:items-start">
            <div className="md:w-1/2 flex flex-wrap items-center">
              <div className="w-full md:w-1/2 mb-6 md:pr-2">
                <Input
                  name="firstName"
                  label={t("first name")}
                  value={formData.firstName}
                  isRequired
                  onChange={event =>
                    setFormData({ ...formData, firstName: event.target.value })
                  }
                  preset="gray"
                />
              </div>

              <div className="w-full md:w-1/2 mb-6 md:pl-2">
                <Input
                  name="lastName"
                  label={t("last name")}
                  value={formData.lastName}
                  isRequired
                  onChange={event =>
                    setFormData({ ...formData, lastName: event.target.value })
                  }
                  preset="gray"
                />
              </div>

              <div className="w-full mb-6">
                <ValidatedEmailInput
                  name="email"
                  type="email"
                  label={t("email")}
                  value={formData.email}
                  isValid={emailIsValid}
                  setIsValid={setEmailIsValid}
                  isRequired
                  onChange={event =>
                    setFormData({ ...formData, email: event.target.value })
                  }
                  preset="gray"
                />
              </div>

              <div className="w-full mb-6">
                <Input
                  name="password"
                  type="password"
                  label={t("password")}
                  value={formData.password}
                  isRequired
                  onChange={event =>
                    setFormData({ ...formData, password: event.target.value })
                  }
                  preset="gray"
                />
              </div>

              <Checkbox
                name="newsletter"
                label={t("Subscribe to our newsetter")}
                checked={formData.subscribedToNewsletter}
                className="mt-2"
                onChange={() =>
                  setFormData({
                    ...formData,
                    subscribedToNewsletter: !formData.subscribedToNewsletter,
                  })
                }
              />

              <Button
                type="button"
                preset="blue"
                text={t("submit")}
                className="mt-6 capitalize"
                disabled={!canAdvance}
                onClick={() => setCurrentSlide(1)}
              />
            </div>

            <div className="mt-6 md:mt-0 md:w-1/2 md:pl-12">
              <RichText
                html={registration.footerNote.html}
                className="RichText RichText--legaleze max-w-sm"
              />
            </div>
          </div>
        </div>

        {/* Slide 2 */}
        <div>
          <div className="flex items-center mb-6">
            <Button
              text={t("back")}
              preset="bordered"
              size="sm"
              className="hover:bg-gray hover:text-white hover:border-gray mr-4"
              iconName="Arrow"
              iconClassName="w-4 h-3"
              onClick={() => setCurrentSlide(0)}
            />
            <Text preset="h2" className="flex-1">
              Tell us about yourself
            </Text>
          </div>
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-1/3 mb-6 md:pr-3">
              <Select
                name="role"
                label={t("role")}
                value={formData.role}
                isRequired
                options={[
                  {
                    id: "",
                    title: t("Select"),
                  },
                  ...roles,
                ]}
                onChange={event =>
                  setFormData({ ...formData, role: event.target.value })
                }
                inline={false}
              />
            </div>

            <div className="w-full md:w-1/3 mb-6 md:pl-1 md:pr-1">
              <Select
                name="subject"
                label={t("subject")}
                value={formData.subject}
                isRequired
                options={[
                  {
                    id: "",
                    title: t("Select"),
                  },
                  ...subjects,
                ]}
                onChange={event =>
                  setFormData({ ...formData, subject: event.target.value })
                }
                inline={false}
              />
            </div>

            <div className="w-full md:w-1/3 mb-6 md:pl-3">
              <Select
                name="level"
                label={t("level")}
                value={formData.level}
                isRequired
                options={[
                  {
                    id: "",
                    title: t("Select"),
                  },
                  ...levels,
                ]}
                onChange={event =>
                  setFormData({ ...formData, level: event.target.value })
                }
                inline={false}
              />
            </div>

            <div className="w-full md:w-1/3 mb-6 md:pr-3">
              <Select
                name="country"
                label={t("country")}
                value={formData.country}
                isRequired
                options={[
                  {
                    id: "",
                    title: t("Select"),
                  },
                  ...countries,
                ]}
                onChange={event =>
                  setFormData({ ...formData, country: event.target.value })
                }
                inline={false}
              />
            </div>

            <div className="w-full md:w-1/3 mb-6 md:pl-1 md:pr-1">
              <Select
                name="state"
                label={t("state")}
                value={formData.state}
                isRequired
                options={
                  formData.country === "US"
                    ? [
                        {
                          id: "",
                          title: t("Select"),
                        },
                        ...states,
                      ]
                    : []
                }
                onChange={event =>
                  setFormData({ ...formData, state: event.target.value })
                }
                inline={false}
                disabled={formData.country !== "US"}
              />
            </div>

            <div className="w-full md:w-1/3 mb-6 md:pl-3">
              {cityOverrides && cityOverrides.length > 0 ? (
                <Select
                  name="city"
                  label={t("city")}
                  value={formData.city}
                  isRequired
                  options={[
                    {
                      id: "",
                      title: t("Select"),
                    },
                    ...cityOverrides,
                  ]}
                  onChange={event =>
                    setFormData({ ...formData, city: event.target.value })
                  }
                  inline={false}
                />
              ) : (
                <Input
                  name="city"
                  label={t("city")}
                  value={formData.city}
                  isRequired={!cityOverrides || cityOverrides.length > 0}
                  isDisabled={cityOverrides && cityOverrides.length === 0}
                  onChange={event =>
                    setFormData({ ...formData, city: event.target.value })
                  }
                  preset="gray"
                />
              )}
            </div>
          </div>

          <Button
            type="submit"
            preset="blue"
            text={t("Create new account")}
            className="mr-2 mt-2 mb-6 capitalize"
            disabled={loading}
          />

          <RichText
            html={registration.footerNote.html}
            className="RichText RichText--legaleze max-w-sm"
          />
        </div>
      </FormSlider>
    </form>
  );
};

RegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    subscribedToNewsletter: PropTypes.bool.isRequired,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default RegisterForm;
