import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveDocumentEdges from "../dataResolvers/resolveDocumentEdges";
import resolveEmbeddedDocument from "../dataResolvers/resolveEmbeddedDocument";
import resolveSlices from "../dataResolvers/resolveSlices";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query allCurriculumQuery {
    allPrismicCollections {
      edges {
        node {
          id
          uid
          type
          data {
            title
            summary {
              html
            }
            description {
              text
            }
            illustration {
              text
            }
            tags {
              tag
            }
          }
        }
      }
    }
    allPrismicLessons {
      edges {
        node {
          id
          uid
          type
          data {
            illustration {
              text
            }
            title
            search_key_words
            tags {
              tag
            }
            summary {
              html
            }
            body {
              ... on PrismicLessonsBodyCopy {
                id
                slice_type
                primary {
                  rich_text {
                    text
                  }
                }
              }
            }
            cor_question {
              document {
                ... on PrismicCorQuestions {
                  id
                  data {
                    color
                    title
                  }
                }
              }
            }
            disciplines {
              discipline {
                document {
                  ... on PrismicDisciplines {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicAssessments {
      edges {
        node {
          id
          uid
          type
          data {
            illustration {
              text
            }
            title
            search_key_words
            tags {
              tag
            }
            summary {
              html
            }
            body {
              ... on PrismicAssessmentsBodyCopy {
                id
                slice_type
                primary {
                  rich_text {
                    text
                  }
                }
              }
            }
            cor_question {
              document {
                ... on PrismicCorQuestions {
                  id
                  data {
                    color
                    title
                  }
                }
              }
            }
            disciplines {
              discipline {
                document {
                  ... on PrismicDisciplines {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicCorQuestions {
      edges {
        node {
          data {
            color
            title
          }
          id
        }
      }
    }
  }
`;

const dataResolver = ({
  allPrismicCollections,
  allPrismicLessons,
  allPrismicAssessments,
  allPrismicCorQuestions,
}) => {
  const collections = resolveDocumentEdges(allPrismicCollections.edges);
  const lessons = resolveDocumentEdges(allPrismicLessons.edges).map(item => ({
    ...item,
    color: resolveEmbeddedDocument(item.cor_question).color,
    corQuestion: resolveEmbeddedDocument(item.cor_question).id,
    disciplines: resolveEmbeddedDocument(item.disciplines).map(({ id }) => id),
    body: resolveSlices(item.body),
  }));
  const assessments = resolveDocumentEdges(allPrismicAssessments.edges).map(
    item => ({
      ...item,
      color: resolveEmbeddedDocument(item.cor_question).color,
      corQuestion: resolveEmbeddedDocument(item.cor_question).id,
      disciplines: resolveEmbeddedDocument(item.disciplines).map(
        ({ id }) => id
      ),
    })
  );
  const corQuestions = resolveDocumentEdges(allPrismicCorQuestions.edges);

  const resolved = {
    content: [...collections, ...lessons, ...assessments],
    corQuestions,
  };

  return keysToCamel(resolved);
};

const useAllCurriculumData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useAllCurriculumData;
