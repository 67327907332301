// note: hard-coding these because they need to match options
// in the Drupal database, and thus cannot be controlled in Prismic
export const roles = [
  {
    id: "teacher",
    title: "Teacher",
  },
  {
    id: "teacher_educator",
    title: "Teacher Educator",
  },
  {
    id: "media_specialist",
    title: "Media Specialist",
  },
  {
    id: "administrator",
    title: "Administrator",
  },
  {
    id: "other",
    title: "Other",
  },
];

export const subjects = [
  {
    id: "history",
    title: "History/Social Studies",
  },
  {
    id: "art",
    title: "Art",
  },
  {
    id: "bilingual",
    title: "ESL/Bilingual",
  },
  {
    id: "english",
    title: "Language Arts/English",
  },
  {
    id: "language",
    title: "World Languages",
  },
  {
    id: "math",
    title: "Math",
  },
  {
    id: "music",
    title: "Music",
  },
  {
    id: "science",
    title: "Science",
  },
  {
    id: "special_education",
    title: "Special Education",
  },
  {
    id: "Other",
    title: "Other",
  },
];

export const levels = [
  {
    id: "high",
    title: "High School",
  },
  {
    id: "elementary",
    title: "Elementary",
  },
  {
    id: "middle",
    title: "Middle School",
  },
  {
    id: "university",
    title: "College/University",
  },
  {
    id: "adult",
    title: "Adult Education",
  },
  {
    id: "other",
    title: "Other",
  },
];

export default {
  roles,
  subjects,
  levels,
};
