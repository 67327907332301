import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// SVG -- renders an SVG passed as an HTML string
/* eslint react/no-danger: 0 */

const SVG = ({ svg, setExplicitHeight, className }) => {
  if (!svg) {
    return null;
  }

  const style = {};

  if (setExplicitHeight) {
    // Use the viewBox to get the width/height ratio,
    // then apply the paddingBottom trick
    const regex = new RegExp(/viewBox="([A-Za-z0-9_. ]*)"/);
    const viewBoxMatch = regex.exec(svg);

    if (viewBoxMatch) {
      const viewBox = viewBoxMatch[1].split(" ");
      const w = parseInt(viewBox[2], 10);
      const h = parseInt(viewBox[3], 10);

      style.paddingBottom = `${(h / w) * 100}%`;
    }
  }

  return (
    <div className={`SVG relative ${className}`} style={style}>
      <span
        className={classNames({
          "block w-full h-full overflow-hidden": true,
          "absolute inset-0": setExplicitHeight,
        })}
        dangerouslySetInnerHTML={{ __html: svg }}
        style={style}
      />
    </div>
  );
};

SVG.propTypes = {
  svg: PropTypes.string,
  setExplicitHeight: PropTypes.bool,
  className: PropTypes.string,
};

SVG.defaultProps = {
  svg: null,
  setExplicitHeight: false,
  className: "",
};

export default SVG;
