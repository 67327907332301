import { createAppState } from "@designbycosmic/cosmic-react-state-management";
import layout from "./reducers/layout";
import breakpoints from "./reducers/breakpoints";
import theme from "./reducers/theme";
import search from "./reducers/search";
import cards from "./reducers/cards";
import modal from "./reducers/modal";
import messages from "./reducers/messages";
import indexPages from "./reducers/indexPages";
import user from "./reducers/user";

const reducer = {
  layout: layout.reducer,
  breakpoints: breakpoints.reducer,
  theme: theme.reducer,
  search: search.reducer,
  cards: cards.reducer,
  modal: modal.reducer,
  messages: messages.reducer,
  indexPages: indexPages.reducer,
  user: user.reducer,
};

const initialState = {
  layout: layout.initialState,
  breakpoints: breakpoints.initialState,
  theme: theme.initialState,
  search: search.initialState,
  cards: cards.initialState,
  modal: modal.initialState,
  messages: messages.initialState,
  indexPages: indexPages.initialState,
  user: user.initialState,
};

export const {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
} = createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
