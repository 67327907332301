import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveEmbeddedDocument from "../dataResolvers/resolveEmbeddedDocument";

const query = graphql`
  query AnnouncementQuery {
    prismicGlobals {
      data {
        current_announcement {
          document {
            ... on PrismicAnnouncements {
              id
              data {
                copy {
                  html
                }
                cta_text {
                  html
                }
                cta_link {
                  url
                  target
                }
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { current_announcement } = prismicGlobals.data;

  const resolved = {
    currentAnnouncement: resolveEmbeddedDocument(current_announcement),
  };

  return keysToCamel(resolved);
};

const useAnnouncementData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useAnnouncementData;
