import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Text, SVG, AppLink } from "@ui";
import curriculumProps from "./propTypes";
import { t } from "@translations";

const CurriculumCard = ({
  uid,
  title,
  type,
  illustration,
  tags,
  color,
  collectionUid,
  disableHoverStyle,
  enableCardSelect,
  className,
  style,
}) => {
  const [rendered, setRendered] = useState(false);
  const [{ cards }, dispatch] = useAppState();

  let url = `/curriculum/${type}/${uid}`;
  if (collectionUid) {
    url += `?cuid=${collectionUid}`;
  }

  const pillColorClass = type === "collections" ? "bg-blue-1" : "bg-gray-1";

  const handleClick = () => {
    if (enableCardSelect) {
      dispatch({
        type: "selectCard",
        cardId: `${type}_${uid}`,
      });
    }
  };

  useEffect(() => {
    setRendered(true);
  }, []);

  return (
    <AppLink
      id={`${type}_${uid}`}
      to={url}
      className={`block p-2 sm:p-3 transition-opacity ${className}`}
      style={{ opacity: rendered ? 1 : 0, ...style }}
      onClick={handleClick}
    >
      <div
        className={classNames({
          "w-full h-full flex flex-col shadow-card": true,
          "hover:scale-105": !disableHoverStyle,
          "transition-fast": cards.selectedCard !== `${type}_${uid}`,
          "opacity-50 scale-125 transition":
            enableCardSelect && cards.selectedCard === `${type}_${uid}`,
        })}
      >
        <div className="relative flex-auto bg-white p-4 pb-8">
          <div className="flex mb-2">
            <div
              className={`${pillColorClass} inline-flex items-center h-5 px-2 rounded-full`}
            >
              <Text
                preset="label"
                variant="sm"
                className="font-bold text-gray-5 capitalize"
              >
                {t(type.slice(0, -1))}
              </Text>
            </div>

            {tags
              .filter(item => item.tag)
              .map(({ tag }, idx) => (
                <div
                  key={idx}
                  className={`bg-${color.toLowerCase()}-1 inline-flex items-center h-5 px-2 rounded-l-sm ml-auto -mr-4`}
                >
                  <Text
                    preset="label"
                    variant="sm"
                    className="font-bold text-gray-5 capitalize"
                  >
                    {tag}
                  </Text>
                </div>
              ))}
          </div>

          <Text preset="h3">{title}</Text>
        </div>

        <div
          className={`relative bg-${color.toLowerCase()}`}
          style={{ paddingBottom: "100%" }}
        >
          <span className="absolute bottom-0 right-0 left-0">
            <SVG svg={illustration.text} setExplicitHeight />
          </span>
        </div>
      </div>
    </AppLink>
  );
};

CurriculumCard.propTypes = curriculumProps.props.isRequired;

CurriculumCard.defaultProps = curriculumProps.defaults;

export default CurriculumCard;
