import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Container } from "@ui";
import { t } from "@translations";

const Paginator = ({ items, page, limit, loadMore, render }) => {
  const [canLoadMore, setCanLoadMore] = useState(false);

  let endIdx = page * limit + limit;
  if (endIdx >= items.length) {
    endIdx = items.length;
  }
  const paginatedResults = items.slice(0, endIdx);

  useEffect(() => {
    setCanLoadMore(endIdx < items.length);
  }, [items, page, limit]);

  const doLoadMore = () => {
    if (canLoadMore) {
      loadMore();
    }
  };

  return (
    <>
      {render(paginatedResults)}

      {canLoadMore && (
        <Container className="flex justify-center mt-6 sm:mt-8">
          <Button preset="blue" onClick={doLoadMore} text={t("view more")} />
        </Container>
      )}
    </>
  );
};

Paginator.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.string),
    ])
  ).isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  loadMore: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
};

export default Paginator;
