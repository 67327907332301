import { useStaticQuery, graphql } from "gatsby";
import mergePrismicPreview from "../mergePrismicPreview";
import { keysToCamel } from "@utils";

const query = graphql`
  query PosterQuery {
    prismicGlobals {
      data {
        poster_heading
        poster_pdf_blue {
          kind
          url
        }
        poster_cta_text_blue
        poster_pdf_white {
          kind
          url
        }
        poster_cta_text_white
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const {
    poster_heading,
    poster_pdf_blue,
    poster_cta_text_blue,
    poster_pdf_white,
    poster_cta_text_white,
  } = prismicGlobals.data;

  const posterThumbnail =
    poster_pdf_blue.kind === "document"
      ? poster_pdf_blue.url.replace(
          "sheg-cor.cdn.prismic.io",
          "images.prismic.io"
        )
      : null;

  const resolved = {
    thumbnail: posterThumbnail,
    heading: poster_heading,
    links: [
      {
        url: poster_pdf_blue.url,
        ctaText: poster_cta_text_blue,
      },
      {
        url: poster_pdf_white.url,
        ctaText: poster_cta_text_white,
      },
    ],
  };

  return keysToCamel(resolved);
};

const usePosterData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default usePosterData;
