// This function accepts the current filters from state,
// and returns a new function that can be used to filter
// an array of results

const filterCurriculum = (filters, options) => {
  // get the options for the currently selected type (singles or collections)
  const opts = options[filters.type];

  // Swapping in the array of types defined in options
  // E.g., replacing "singles" with ["lessons", "assessments"];
  const _filters = {
    ...filters,
    type: opts.filters.type,
  };

  // Then return a function that will check each member of the search results
  // against the options for the currently selected type
  // E.g., at launch collections are not filterable by COR questions
  return item => {
    return Object.entries(_filters).every(([key, val]) => {
      // if val exits (meaning the user has selected something for this filter),
      // AND the search options don't tell us to skip this filter,
      // then check if the associated key is a match on the item.
      const skip = opts.filters[key] === false;
      const match = Array.isArray(item[key])
        ? val.some(_val => item[key].includes(_val))
        : val.includes(item[key]);
      return val.length === 0 || skip || match;
    });
  };
};

export default filterCurriculum;
