/* eslint-disable import/prefer-default-export */
/* eslint react/prop-types: 0 */

import React from "react";
import "./src/pcss/index.pcss";
import wrapWithState from "@state/wrapWithState";
import { SearchConfig } from "@modules/search/index";
import {
  DefaultLayout,
  Modal,
  Announcement,
  Messages,
  LayoutUpdater,
  HeaderWrap,
  PreviewModeBug,
  Lang,
  AuthenticationCheck,
} from "@layout";

export const wrapRootElement = wrapWithState;

export const wrapPageElement = ({ element, props }) => {
  const { location } = props;

  const intersitials = ["/Preview", "/AuthCallback/"];

  return (
    <>
      <Lang currentPath={location.href} />
      <AuthenticationCheck location={location} />
      <LayoutUpdater />
      {!intersitials.includes(location.pathname) && (
        <>
          <SearchConfig />
          <Announcement />
          <Modal />
          <Messages />
          <HeaderWrap currentPath={location.href} />
          <PreviewModeBug />
        </>
      )}
      <DefaultLayout {...props}>{element}</DefaultLayout>
    </>
  );
};
