import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveImageData from "../dataResolvers/resolveImageData";
import resolveEmbeddedDocument from "../dataResolvers/resolveEmbeddedDocument";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query HomeCurriculumQuery {
    prismicHomePage {
      data {
        curriculum_heading
        curriculum_description {
          html
        }
        curriculum_image_group {
          filter
          focal_point_x
          focal_point_y
          image {
            alt
            dimensions {
              height
              width
            }
            url
          }
        }
        curriculum_cta_text
        curriculum_cta_link {
          url
          target
        }
        curriculum_featured {
          curriculum {
            document {
              ... on PrismicLessons {
                id
                uid
                type
                data {
                  title
                  illustration {
                    text
                  }
                  tags {
                    tag
                  }
                  cor_question {
                    document {
                      ... on PrismicCorQuestions {
                        id
                        data {
                          color
                          title
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicCollections {
                id
                uid
                type
                data {
                  title
                  illustration {
                    text
                  }
                  tags {
                    tag
                  }
                }
              }
              ... on PrismicAssessments {
                id
                uid
                type
                data {
                  title
                  illustration {
                    text
                  }
                  tags {
                    tag
                  }
                  cor_question {
                    document {
                      ... on PrismicCorQuestions {
                        id
                        data {
                          color
                          title
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicHomePage }) => {
  const { curriculum } = resolveByPrefix(prismicHomePage.data, [
    "curriculum_",
  ]).prefixed;
  const { image_group, featured, ...rest } = curriculum;

  const resolved = {
    image: resolveImageData(image_group)[0],
    featuredCurriculum: resolveEmbeddedDocument(featured).map(item => ({
      ...item,
      color: item.cor_question
        ? resolveEmbeddedDocument(item.cor_question).color
        : undefined,
      corQuestion: item.cor_question
        ? resolveEmbeddedDocument(item.cor_question).id
        : undefined,
    })),
    ...rest,
  };

  return keysToCamel(resolved);
};

const useHomeCurriculumData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useHomeCurriculumData;
