import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveImageData from "../dataResolvers/resolveImageData";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query NewsIndexQuery {
    prismicNewsIndex {
      data {
        meta_title
        meta_description
        meta_image {
          filter
          focal_point_x
          focal_point_y
          image {
            alt
            url
            dimensions {
              height
              width
            }
          }
        }
        hero_heading
        hero_description {
          html
        }
        prefooter_heading
        prefooter_description {
          html
        }
        prefooter_cta_link {
          url
          target
        }
        prefooter_cta_text
        prefooter_background_image {
          filter
          focal_point_x
          focal_point_y
          image {
            alt
            url
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicNewsIndex }) => {
  const prefixes = ["meta_", "hero_", "prefooter_"];

  const { prefixed, unPrefixed } = resolveByPrefix(
    prismicNewsIndex.data,
    prefixes
  );
  const { meta, prefooter } = prefixed;

  const resolved = {
    ...prefixed,
    ...unPrefixed,
    meta: {
      ...meta,
      image: resolveImageData(meta.image)[0],
    },
    prefooter: {
      ...prefooter,
      backgroundImage: resolveImageData(prefooter.background_image)[0],
    },
  };

  return keysToCamel(resolved);
};

const useNewsIndexData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useNewsIndexData;
