import Cookies from "universal-cookie";
import { registerMiddleware } from "@designbycosmic/cosmic-react-state-management";

const cookies = new Cookies();

const reducer = (state, action) => {
  switch (action.type) {
    case "toggleLogin":
      return {
        ...state,
        loggedIn: action.loggedIn,
      };

    default:
      return state;
  }
};

const initialState = {
  loggedIn: !!cookies.get("COR_login_ticket"),
};

registerMiddleware({
  actionType: "toggleLogin",
  func: ({ ticket }) => {
    if (ticket) {
      cookies.set("COR_login_ticket", ticket, {
        path: "/",
      });
    } else {
      cookies.remove("COR_login_ticket", { path: "/" });
    }
  },
});

export default {
  reducer,
  initialState,
};
