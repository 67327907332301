import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import resolveEmbeddedDocument from "../dataResolvers/resolveEmbeddedDocument";
import resolveImageData from "../dataResolvers/resolveImageData";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query AboutPageQuery {
    prismicAboutPage {
      data {
        meta_title
        meta_description
        meta_image {
          filter
          focal_point_x
          focal_point_y
          image {
            alt
            url
            dimensions {
              height
              width
            }
          }
        }
        hero_heading
        hero_description {
          html
        }
        origin_heading
        origin_copy {
          html
        }
        fact_heading
        fact_copy {
          html
        }
        fact_cor_question_heading
        fact_cor_questions {
          cor_question {
            document {
              ... on PrismicCorQuestions {
                uid
                data {
                  title
                  color
                }
              }
            }
          }
        }
        media_heading
        media_copy {
          html
        }
        media_partner_logo {
          url
          alt
          dimensions {
            width
            height
          }
        }
        media_publication_logos {
          logo {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        curriculum_heading
        curriculum_copy {
          html
        }
        curriculum_cta_text
        curriculum_video {
          background_image {
            url
            alt
            dimensions {
              width
              height
            }
          }
          cta_text
          video {
            document {
              ... on PrismicVideos {
                id
                uid
                data {
                  title
                  youtube_video_id
                }
              }
            }
          }
        }
        team_heading
        team_copy {
          html
        }
        team_members {
          name
          bio {
            html
          }
          email
          photo {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        prefooter_heading
        prefooter_description {
          html
        }
        prefooter_cta_link {
          url
          target
        }
        prefooter_cta_text
        prefooter_background_image {
          filter
          focal_point_x
          focal_point_y
          image {
            alt
            url
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicAboutPage }) => {
  const prefixes = [
    "meta_",
    "hero_",
    "origin_",
    "fact_",
    "media_",
    "curriculum_",
    "team_",
    "prefooter_",
  ];

  const { prefixed, unPrefixed } = resolveByPrefix(
    prismicAboutPage.data,
    prefixes
  );

  const {
    meta,
    fact,
    media,
    curriculum,
    team,
    prefooter,
    ...restPrefixed
  } = prefixed;

  const resolved = {
    ...restPrefixed,
    ...unPrefixed,
    meta: {
      ...meta,
      image: resolveImageData(meta.image)[0],
    },
    fact: {
      ...fact,
      corQuestions: resolveEmbeddedDocument(fact.cor_questions),
    },
    media: {
      ...media,
      publicationLogos: resolveImageData(
        media.publication_logos.map(photo => ({ image: photo.logo }))
      ),
      partnerLogo: resolveImageData({ image: media.partner_logo }),
    },
    curriculum: {
      ...curriculum,
      video: {
        ...curriculum.video[0],
        video: resolveEmbeddedDocument(curriculum.video[0].video),
        backgroundImage: resolveImageData({
          image: curriculum.video[0].background_image,
        }),
      },
    },
    team: {
      ...team,
      members: team.members.map(member => ({
        ...member,
        photo: resolveImageData({ image: member.photo }),
      })),
    },
    prefooter: {
      ...prefooter,
      backgroundImage: resolveImageData(prefooter.background_image)[0],
    },
  };

  return keysToCamel(resolved);
};

const useAboutPageData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useAboutPageData;
