import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import classNames from "classnames";
import { Text, Button, AppLink } from "@ui";
import { t } from "@translations";

/* eslint react/prop-types: 0 */

const SingleHeader = ({ uid, backUrl, backText, title, date, categories }) => {
  const [{ breakpoints }] = useAppState();

  const { lg } = breakpoints;

  return (
    <>
      <div
        className={classNames({
          "mb-6": !lg,
          "absolute -left-16 -translate-x-100": lg,
        })}
      >
        <AppLink to={`${backUrl}#${uid}`}>
          <Button
            text={t(backText)}
            preset="bordered"
            size="sm"
            iconName="Arrow"
            className="text-blue hover:bg-blue hover:text-white hover:border-blue"
            iconClassName="w-4 h-3"
          />
        </AppLink>
      </div>
      <div
        className={classNames({
          "flex flex-wrap items-center": true,
          "mb-4": categories.length > 0 || date,
        })}
      >
        {categories.map(({ id, title: catTitle }) => (
          <div
            key={id}
            className="inline-flex items-center rounded-full h-5 bg-gray-1 px-2 my-1 mr-2"
          >
            <Text
              preset="label"
              variant="sm"
              className="font-bold text-gray-5 capitalize"
            >
              {catTitle}
            </Text>
          </div>
        ))}
        {date && (
          <Text
            preset="label"
            size="sm"
            className="font-bold text-blue uppercase my-1"
          >
            {date}
          </Text>
        )}
      </div>
      <Text preset="h2" tag="h1">
        {title}
      </Text>
    </>
  );
};

SingleHeader.propTypes = {
  backUrl: PropTypes.string.isRequired,
  backText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
    })
  ),
};

SingleHeader.defaultProps = {
  date: null,
  categories: [],
};

export default SingleHeader;
