import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Image, Text, Button, imageProps, AppLink } from "@ui";

const SingleCTA = ({
  image,
  link,
  publication,
  medium,
  overrideHeadingText,
  overrideLinkText,
}) => {
  const { width = 1, height = 1 } = image || {};
  const isSquarish = width / height < 1.5;
  const imageWidth = isSquarish ? 100 : ((width - height) / width) * 100;

  const headingTextMap = {
    Article: "Full article available at",
    Video: "Full video available at",
    Podcast: "Full audio available at",
  };

  const ctaTextMap = {
    Article: "Read the article",
    Video: "Watch the video",
    Podcast: "Listen to the podcast",
  };

  return (
    <AppLink
      to={link.url}
      target={link.target}
      className="bg-white shadow-card p-4 md:p-8 flex items-center"
    >
      <div
        className={classNames({
          "flex items-center justify-center pr-4 md:pr-8": true,
          "flex-1": !isSquarish,
          "w-24 sm:w-36": isSquarish,
        })}
      >
        <div style={{ width: `${imageWidth}%` }}>
          <Image image={image} objectFit="contain" showLQIP={false} />
        </div>
      </div>

      <div className="flex-1">
        <Text preset="h3">
          {overrideHeadingText || `${headingTextMap[medium]} ${publication}`}
        </Text>

        <Button
          preset="empty"
          className="text-blue"
          text={overrideLinkText || ctaTextMap[medium]}
        />
      </div>
    </AppLink>
  );
};

SingleCTA.propTypes = {
  image: imageProps.props,
  link: PropTypes.shape({
    url: PropTypes.string.isRequired,
    target: PropTypes.string,
  }).isRequired,
  publication: PropTypes.string.isRequired,
  medium: PropTypes.oneOf(["Article", "Video", "Podcast"]),
  overrideHeadingText: PropTypes.string,
  overrideLinkText: PropTypes.string,
};

SingleCTA.defaultProps = {
  image: imageProps.defaultProps,
  medium: "Article",
  overrideHeadingText: null,
  overrideLinkText: null,
};

export default SingleCTA;
