import { useStaticQuery, graphql } from "gatsby";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query HeaderQuery {
    prismicHeader {
      data {
        menu {
          link {
            url
            target
          }
          title
          in_desktop
        }
      }
    }
  }
`;

const dataResolver = response => {
  const { prismicHeader } = response;
  const { menu } = prismicHeader.data;

  return {
    menu: menu.map(({ title, link, in_desktop }, idx) => ({
      id: idx,
      title,
      url: link.url,
      target: link.target,
      showOnDesktop: in_desktop === "Yes",
    })),
  };
};

const useHeaderData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useHeaderData;
