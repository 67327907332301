import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Text, SVG, RichText, AppLink } from "@ui";
import curriculumProps from "./propTypes";
import { t } from "@translations";

const CurriculumListItem = ({
  uid,
  title,
  type,
  illustration,
  tags,
  color,
  collectionUid,
  summary,
  enableCardSelect,
  className,
  style,
}) => {
  const [rendered, setRendered] = useState(false);
  const [{ breakpoints, cards }, dispatch] = useAppState();

  const pillColorClass = type === "collections" ? "bg-blue-1" : "bg-gray-1";

  let url = `/curriculum/${type}/${uid}`;
  if (collectionUid) {
    url += `?cuid=${collectionUid}`;
  }

  const handleClick = () => {
    if (enableCardSelect) {
      dispatch({
        type: "selectCard",
        cardId: `${type}_${uid}`,
      });
    }
  };

  useEffect(() => {
    setRendered(true);
  }, []);

  return (
    <AppLink
      id={`${type}_${uid}`}
      to={url}
      className={`flex transition-opacity py-2 sm:py-3 ${className}`}
      style={{ opacity: rendered ? 1 : 0, ...style }}
      onClick={handleClick}
    >
      <div
        className={classNames({
          "w-full flex shadow-card hover:scale-102": true,
          "transition-fast": cards.selectedCard !== `${type}_${uid}`,
          "opacity-50 scale-110 transition":
            cards.selectedCard === `${type}_${uid}`,
        })}
      >
        <div
          className={`relative flex flex-col justify-end w-24 sm:w-36 bg-${color.toLowerCase()}`}
        >
          <div className="w-full" style={{ paddingBottom: "100%" }}>
            <span className="absolute right-0 left-0 bottom-0">
              <SVG svg={illustration.text} />
            </span>
          </div>
        </div>
        <div className="relative flex-1 bg-white p-4">
          <div className="flex">
            <div className="flex-1">
              <Text preset="h4" tag="h3" className="font-bold pr-4">
                {title}
              </Text>
            </div>
            <div className="flex flex-col md:block ml-auto">
              <div
                className={`${pillColorClass} inline-flex items-center h-5 px-2 rounded-full mb-2 md:mb-0`}
              >
                <Text
                  preset="label"
                  variant="sm"
                  className="font-bold text-gray-5 capitalize"
                >
                  {t(type.slice(0, -1))}
                </Text>
              </div>

              {tags
                .filter(item => item.tag)
                .map(({ tag }, idx) => (
                  <div
                    key={idx}
                    className={`bg-${color.toLowerCase()}-1 inline-flex items-center h-5 px-2 rounded-l-sm mb-2 md:mb-0 md:ml-2 -mr-4`}
                  >
                    <Text
                      preset="label"
                      variant="sm"
                      className="font-bold text-gray-5 capitalize"
                    >
                      {tag}
                    </Text>
                  </div>
                ))}
            </div>
          </div>
          {summary.html && breakpoints.sm && (
            <RichText
              className="RichText--sm truncate-4 mt-4"
              html={summary.html}
            />
          )}
        </div>
      </div>
    </AppLink>
  );
};

CurriculumListItem.propTypes = curriculumProps.props.isRequired;

CurriculumListItem.defaultProps = curriculumProps.defaults;

export default CurriculumListItem;
