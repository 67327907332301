import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";

const query = graphql`
  query AuthFormsQuery {
    prismicGlobals {
      data {
        login_message {
          html
        }
        registration_heading
        registration_footer_note {
          html
        }
        registration_success_message {
          html
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { prefixed } = resolveByPrefix(prismicGlobals.data, [
    "login_",
    "registration_",
  ]);
  return keysToCamel({
    ...prefixed,
  });
};

const useAuthFormsData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useAuthFormsData;
