import React from "react";
import PropTypes from "prop-types";
import List from "@svg/icon-list-view.svg";
import Grid from "@svg/icon-grid-view.svg";
import RedAccent from "@svg/red-shape-accent.svg";
import GreenAccent from "@svg/green-shape-accent.svg";
import YellowAccent from "@svg/yellow-shape-accent.svg";
import DIG from "@svg/dig-logo-icon.svg";
import ExternalLink from "@svg/icon-external-link.svg";
import VideoPlay from "@svg/video-play.svg";
import VideoPlayLg from "@svg/video-play-lg.svg";
import Document from "@svg/icon-document.svg";
import Close from "@svg/icon-close.svg";
import X from "@svg/icon-x.svg";
import Hamburger from "@svg/hamburger.svg";
import MagnifyingGlass from "@svg/magnifying-glass.svg";
import NavArrow from "@svg/nav-arrow.svg";
import Email from "@svg/icon-email.svg";
import Location from "@svg/icon-location.svg";
import Social from "@svg/icon-social.svg";
import Facebook from "@svg/facebook.svg";
import Twitter from "@svg/twitter.svg";
import Youtube from "@svg/youtube.svg";
import Arrow from "@svg/arrow.svg";
import Validated from "@svg/validated.svg";
import Loading from "@svg/loading.svg";

const Icon = ({ name, className: _className, style }) => {
  const SVG = ({ _name }) => {
    switch (_name) {
      case "list":
        return <List />;
      case "cards":
        return <Grid />;
      case "grid":
        return <Grid />;
      case "RedAccent":
        return <RedAccent />;
      case "GreenAccent":
        return <GreenAccent />;
      case "YellowAccent":
        return <YellowAccent />;
      case "DIG":
        return <DIG />;
      case "externalLink":
        return <ExternalLink />;
      case "videoPlay":
        return <VideoPlay />;
      case "videoPlayLg":
        return <VideoPlayLg />;
      case "document":
        return <Document />;
      case "close":
        return <Close />;
      case "x":
        return <X />;
      case "hamburger":
        return <Hamburger />;
      case "magnifyingGlass":
        return <MagnifyingGlass />;
      case "navArrow":
        return <NavArrow />;
      case "email":
        return <Email />;
      case "location":
        return <Location />;
      case "social":
        return <Social />;
      case "Facebook":
        return <Facebook />;
      case "Twitter":
        return <Twitter />;
      case "Youtube":
        return <Youtube />;
      case "Arrow":
        return <Arrow />;
      case "validated":
        return <Validated />;
      case "loading":
        return <Loading />;

      default:
        return null;
    }
  };

  SVG.propTypes = {
    _name: PropTypes.string.isRequired,
  };

  return (
    <span
      className={`relative inline-block overflow-hidden ${_className}`}
      style={style}
    >
      <SVG _name={name} />
    </span>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Icon.defaultProps = {
  className: "w-4 h-4",
  style: {},
};

export default Icon;
