import React from "react";
import PropTypes from "prop-types";
import { Image, imageProps } from "@ui";

const SingleCardImage = ({ type, image, short }) => {
  const { width, height } = image;
  const ratio = width / height;
  const imageHeight = Math.min(200 / ratio, 90);

  const showLQIP = type !== "news";
  const forceHeight = type === "news" ? imageHeight : null;
  const objectFit = type === "news" ? "contain" : "cover";

  return (
    <div
      className="relative h-full"
      style={{ paddingBottom: short ? "67%" : 0 }}
    >
      <div className="absolute inset-0 flex items-center justify-center">
        <Image
          image={image}
          objectFit={objectFit}
          forceHeight={forceHeight}
          showLQIP={showLQIP}
          className="block"
        />
      </div>
    </div>
  );
};

SingleCardImage.propTypes = {
  type: PropTypes.oneOf(["blog", "news", "videos"]).isRequired,
  image: imageProps.props.isRequired,
  short: PropTypes.bool.isRequired,
};

export default SingleCardImage;
