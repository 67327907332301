import { getTailwindUtility } from "@utils";

// use Tailwind config to get color hex values
// that map to dropdown selections in Prismic
const colors = getTailwindUtility("colors");
const filterHexMap = {
  Blue: colors.blue["3"].replace("#", ""),
  White: colors.blue["0"].replace("#", ""),
};

const parseImageData = (
  { image, focal_point_x, focal_point_y, filter, caption },
  idx
) => {
  if (!image) {
    return null;
  }

  const { url, alt, dimensions } = image;

  if (!url) {
    return null;
  }

  return {
    id: idx || 0,
    url,
    alt,
    width: dimensions.width,
    height: dimensions.height,
    fpx: focal_point_x,
    fpy: focal_point_y,
    filter: filter ? filterHexMap[filter] : null,
    caption,
  };
};

const resolveImageData = data => {
  if (Array.isArray(data)) {
    return data.map(parseImageData);
  }
  return parseImageData(data);
};

export default resolveImageData;
