import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Text } from "@ui";
import { applyFilters } from "@utils";

// SearchSuggestions -- auto-suggestions of a search term provided by
// the MiniSearch library

// Disabling some eslint validations so we can click on list items
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

const SearchSuggestions = ({
  isActive,
  term,
  onSelectSuggestion,
  searchOnType,
}) => {
  const [suggestedTerms, setSuggestedTerms] = useState([]);
  const [{ search }] = useAppState();
  const { searcher, filters: _filters, options } = search;

  // Use the type passed in props.
  // This allows us to make the SearchInput in the Header always
  // search singles, while still leaving open the possibility that we
  // may want to search collections at some point in the future
  const filters = {
    ..._filters,
    type: searchOnType || _filters.type,
  };

  useEffect(() => {
    if (term && term.length > 2) {
      const autoSuggest = searcher.autoSuggest(term, {
        filter: applyFilters(filters, options),
      });
      setSuggestedTerms(autoSuggest.slice(0, 5));
    } else {
      setSuggestedTerms([]);
    }
  }, [term]);

  const showSuggestions = suggestedTerms.length > 0 && isActive;

  return (
    <ul
      className={classNames({
        "absolute bottom-0 left-0 right-0 shadow translate-y-100 pt-1": true,
        "opacity-1 pointer-events-auto": showSuggestions,
        "opacity-0 pointer-events-none": !showSuggestions,
      })}
    >
      {suggestedTerms.map(({ suggestion }, idx) => (
        <li
          key={suggestion.split("").join("-")}
          className={classNames({
            "block p-2 text-gray text-left bg-white hover:bg-blue-1 transition-fast cursor-pointer": true,
            "border-blue-2 border-b-1": idx < suggestedTerms.length - 1,
          })}
          onClick={() => onSelectSuggestion(suggestion)}
        >
          <Text preset="label" className="cursor-pointer">
            {suggestion}
          </Text>
        </li>
      ))}
    </ul>
  );
};

SearchSuggestions.propTypes = {
  isActive: PropTypes.bool.isRequired,
  term: PropTypes.string.isRequired,
  onSelectSuggestion: PropTypes.func.isRequired,
  searchOnType: PropTypes.string,
};

SearchSuggestions.defaultProps = {
  searchOnType: null,
};

export default SearchSuggestions;
