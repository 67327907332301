import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveDocumentEdges from "../dataResolvers/resolveDocumentEdges";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveImageData from "../dataResolvers/resolveImageData";

const query = graphql`
  query AllVideosQuery {
    prismicVideoIndex {
      data {
        sort_order {
          item {
            uid
          }
        }
      }
    }
    allPrismicVideos {
      edges {
        node {
          uid
          data {
            title
            display_date(formatString: "MMM DD, YYYY")
            description {
              html
            }
            categories {
              category
            }
            topic {
              uid
            }
            thumbnail {
              focal_point_x
              focal_point_y
              image {
                url
                dimensions {
                  width
                  height
                }
                url
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ allPrismicVideos, prismicVideoIndex }) => {
  const videos = resolveDocumentEdges(allPrismicVideos.edges);
  const sorted = prismicVideoIndex.data.sort_order.map(({ item }) =>
    videos.find(video => video.uid === item.uid)
  );

  const resolved = {
    videos: sorted.map(
      ({ thumbnail, categories, display_date, topic, ...rest }) => ({
        ...rest,
        date: display_date,
        categories: categories.map(({ category }) => ({
          title: category.toLowerCase(),
          id: category
            .toLowerCase()
            .split(" ")
            .join("-"),
        })),
        topic: topic.uid,
        image: resolveImageData(thumbnail[0]),
      })
    ),
  };

  return keysToCamel(resolved);
};

const useAllVideosData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useAllVideosData;
