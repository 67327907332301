/* eslint-disable react/prop-types */
import React from "react";
import clsx from "clsx";
import LockupLarge from "@svg/lockup-large.svg";
import { Text, RichText } from "@ui";

const GlobalNotification = ({
  heading = "Site Under Maintenance",
  copy = "We'll be back soon!",
}) => {
  return (
    <div className={clsx("fixed z-max inset-0 bg-blue transition p-12")}>
      <div
        className={clsx(
          "relative bg-white shadow text-center p-12 rounded flex flex-col items-center justify-center mt-20 mx-auto max-w-md transition-fast"
        )}
      >
        <LockupLarge className="mb-6 w-32 lockup-blue" />
        <Text preset="h2" className="mb-2">
          {heading}
        </Text>
        <Text preset="p" className="mb-6">
          <RichText html={copy} />
        </Text>
      </div>
    </div>
  );
};

export default GlobalNotification;
