import { useStaticQuery, graphql } from "gatsby";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query GlobalsQuery {
    prismicGlobals {
      data {
        global_announcement_heading
        global_announcement_copy {
          html
        }
        global_announcement_button_text
        maintenance_mode_heading
        maintenance_mode_copy {
          html
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const {
    global_announcement_heading,
    global_announcement_copy,
    global_announcement_button_text,
    maintenance_mode_heading,
    maintenance_mode_copy,
  } = prismicGlobals.data;

  return {
    globalAnnouncement: {
      enabled:
        !!global_announcement_heading && global_announcement_heading.length,
      heading: global_announcement_heading,
      copy: global_announcement_copy.html,
      buttonText: global_announcement_button_text,
    },
    maintenanceMode: {
      enabled: !!maintenance_mode_heading && maintenance_mode_heading.length,
      heading: maintenance_mode_heading,
      copy: maintenance_mode_copy.html,
    },
  };
};

const useAnnouncementData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useAnnouncementData;
