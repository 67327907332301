import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import SearchIcon from "@svg/magnifying-glass.svg";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Input } from "@forms";
import SearchSuggestions from "./SearchSuggestions";
import { t } from "@translations";

// SearchInput
/* eslint jsx-a11y/no-noninteractive-tabindex: 0 */

const SearchInput = ({
  searchOnType,
  initialTerm,
  onSearch,
  activeClass,
  showSuggestions,
  focusIfTerm,
  className: _className,
}) => {
  const [{ search }, dispatch] = useAppState();
  const { searcher, content, term, filters, options } = search;

  const [searchTerm, setSearchTerm] = useState(term);
  const [isFocused, setIsFocused] = useState(false);

  const el = useRef(null);

  const doSearch = _term => {
    const type = searchOnType || filters.type;
    const canSearch = options[type].searchable;
    const newTerm = _term || searchTerm || "";

    const results =
      newTerm && canSearch
        ? searcher
          .search(newTerm)
          .map(({ id: _id }) => content.find(item => item.id === _id))
        : content;

    dispatch({
      type: "setSearchResults",
      newTerm,
      results,
    });

    if (el.current) {
      el.current.blur();
    }
    setIsFocused(false);

    if (onSearch) {
      onSearch(searchTerm);
    }
  };

  const handleKeyPress = () => {
    if (isFocused) {
      doSearch(searchTerm);
    }
  };

  const handleSelectSuggestion = suggestion => {
    doSearch(suggestion);
    setSearchTerm(suggestion);
  };

  useEffect(() => {
    if (term === "" && searchTerm) {
      setSearchTerm("");
    }
  }, [term]);

  useEffect(() => {
    if (initialTerm && initialTerm !== term && searcher) {
      setSearchTerm(initialTerm);
      doSearch(initialTerm);
    }
  }, [term, initialTerm, !!searcher]);

  const className = classNames(
    {
      [`relative flex items-center transition pl-4 rounded-full hover:${activeClass}`]: true,
      [`${activeClass}`]: isFocused || (term && focusIfTerm),
    },
    _className
  );

  return (
    <div
      ref={el}
      tabIndex="0"
      className={className}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <span className="w-3 h-3">
        <SearchIcon />
      </span>
      <Input
        value={searchTerm}
        onChange={event => setSearchTerm(event.currentTarget.value)}
        onPressEnter={handleKeyPress}
        name="search"
        placeholder={t("Search curriculum")}
        preset="empty"
        autoComplete="off"
      />

      <button
        type="button"
        className={classNames({
          "outline-none focus:outline-none transition-opacity mr-2 transition-fast": true,
          "bg-white text-blue rounded-full px-3 py-1": true,
          "opacity-0 pointer-events-none": !isFocused || !searchTerm,
          "opacity-100 pointer-events-auto": isFocused && searchTerm,
        })}
        onClick={() => doSearch(searchTerm)}
      >
        <span className="font-sans font-bold text-xs capitalize">
          {t("go")}
        </span>
      </button>

      {showSuggestions && (
        <SearchSuggestions
          isActive={isFocused}
          term={searchTerm}
          searchOnType={searchOnType}
          onSelectSuggestion={handleSelectSuggestion}
        />
      )}
    </div>
  );
};

SearchInput.propTypes = {
  searchOnType: PropTypes.string,
  initialTerm: PropTypes.string,
  onSearch: PropTypes.func,
  showSuggestions: PropTypes.bool,
  activeClass: PropTypes.string,
  focusIfTerm: PropTypes.bool,
  className: PropTypes.string,
};

SearchInput.defaultProps = {
  searchOnType: null,
  initialTerm: "",
  onSearch: null,
  showSuggestions: true,
  activeClass: "bg-blue-4",
  focusIfTerm: false,
  className: "",
};

export default SearchInput;
