const getDupes = arr => {
  const uniques = [];
  const dupes = [];
  arr.forEach(item => {
    if (uniques.includes(item)) {
      dupes.push(item);
    } else {
      uniques.push(item);
    }
  });
  return dupes;
};

export default getDupes;
