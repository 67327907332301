import React from "react";
import PropTypes from "prop-types";
import useScrollPosition from "@designbycosmic/cosmic-react-scroll-hook";
import Header from "./Header";

const hasWindow = typeof window !== "undefined";

const HeaderWrap = ({ currentPath }) => {
  const { scrollY } = hasWindow ? useScrollPosition() : { scrollY: 0 };

  return (
    <>
      <Header currentPath={currentPath} scrollY={scrollY} />
      <Header currentPath={currentPath} isScroller scrollY={scrollY} />
    </>
  );
};

HeaderWrap.propTypes = {
  currentPath: PropTypes.string,
};

HeaderWrap.defaultProps = {
  currentPath: "",
};

export default HeaderWrap;
