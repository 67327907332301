/**
 * Opens a popup with given url and this popup will be polled by parent window for 'value' property on its window object.
 * Once the 'value' property is set to true, the parent window will close the popup and trigger the given callback function.
 * @param {string} url - url for popup
 * @param {number} [pollInterval] - Interval in which to poll the popup window. (Default: 1000ms)
 */
function OauthPopup(url, pollInterval) {
  this.url = url;
  this.pollInterval = pollInterval || 500;
}

/**
 * @param {function} [callbackFn] - function to be triggered once the 'value' property of popup is set to true.
 */
OauthPopup.prototype.show = function OauthPopupShow(callbackFn) {
  const st =
    "toolbar=0,location=0,directories=0,status=0,menubar=0,scrollbars=1,resizable=0,";
  const left = window.screen.width / 2 - 300;
  const top = window.screen.height / 2 - 350;

  if (this.popup) {
    this.popup.close();
  }

  this.popup = window.open(
    this.url,
    "",
    `${st}top=${top},left=${left},width=450,height=700`
  );

  if (this.popupInterval) {
    clearInterval(this.popupInterval);
  }

  this.popupInterval = setInterval(() => {
    try {
      if (this.popup && (this.popup.closed || this.popup.value)) {
        clearInterval(this.popupInterval);
        if (!this.popup.closed) {
          this.popup.close();
        }
        if (this.popup.value && callbackFn) {
          callbackFn(this.popup.value);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, this.pollInterval);
};

export default OauthPopup;
