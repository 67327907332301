const mapArrayToObject = (keys, obj, prefix) => {
  return keys.reduce((acc, cur) => {
    let key = cur;
    if (prefix) {
      key = cur.replace(prefix, "");
    }
    acc[key] = obj[cur];
    return acc;
  }, {});
};

const getPrefixed = (obj, prefix) => {
  const len = prefix.length;
  const keys = Object.keys(obj).filter(key => {
    return key.substring(0, len) === prefix;
  });

  return mapArrayToObject(keys, obj, prefix);
};

const getUnPrefixed = (obj, prefixes) => {
  const keys = Object.keys(obj).filter(key => {
    return prefixes.every(item => {
      const len = item.length;
      return key.substring(0, len) !== item;
    });
  });

  return mapArrayToObject(keys, obj);
};

const resolveByPrefix = (obj, match) => {
  const prefixes = Array.isArray(match) ? match : [match];

  const prefixed = prefixes.reduce((acc, cur) => {
    const key = cur.replace("_", "");
    acc[key] = getPrefixed(obj, cur);
    return acc;
  }, {});

  const unPrefixed = getUnPrefixed(obj, prefixes);

  return { prefixed, unPrefixed };
};

export default resolveByPrefix;
