import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query RegisterCTAQuery {
    prismicCurriculumIndex {
      data {
        register_prompt
        register_cta_text
      }
    }
  }
`;

const dataResolver = ({ prismicCurriculumIndex }) => ({
  ...keysToCamel(prismicCurriculumIndex.data),
});

const useRegisterCTAData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useRegisterCTAData;
