import React, { useState, useEffect } from "react";
import { useAppState } from "@state/state";
import { Text } from "@ui";

const PreviewModeBug = () => {
  const [{ layout }] = useAppState();
  const { previewMode } = layout;
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const { __PRISMIC_PREVIEW_DATA__: previewData } = window;
    // need timeout for errors to get set to previewData
    setTimeout(() => {
      const { errors: _errors = [] } = previewData || {};
      setErrors(_errors);
    }, 1000);
  }, [previewMode]);

  if (!previewMode) {
    return null;
  }

  return (
    <div className="fixed z-max left-0 bottom-0 right-0 bg-red px-6 h-12 flex flex-col justify-center">
      <Text preset="label" className="block text-white">
        You are viewing a content preview.
      </Text>

      {errors.length > 0 && (
        <div className="mt-1">
          <Text
            preset="label"
            variant="sm"
            className="font-bold text-white mr-1"
          >
            Preview error on:
          </Text>

          {errors.map((error, idx) => (
            <Text key={idx} preset="label" variant="sm" className="text-white">
              {error}
              {idx < errors.length - 1 && <span>, </span>}
            </Text>
          ))}
        </div>
      )}
    </div>
  );
};

export default PreviewModeBug;
