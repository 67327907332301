import { useStaticQuery, graphql } from "gatsby";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveImageData from "../dataResolvers/resolveImageData";
import { keysToCamel } from "@utils";

const query = graphql`
  query MetaDataQuery {
    prismicGlobals {
      data {
        default_meta_title
        default_meta_description
        default_meta_image {
          filter
          focal_point_x
          focal_point_y
          image {
            alt
            url
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { default_meta_image, ...rest } = prismicGlobals.data;

  const resolved = {
    ...rest,
    defaultMetaImage: resolveImageData(default_meta_image)[0],
  };

  return keysToCamel(resolved);
};

const useMetaData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useMetaData;
