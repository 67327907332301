import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveDocumentEdges from "../dataResolvers/resolveDocumentEdges";
import resolveEmbeddedDocument from "../dataResolvers/resolveEmbeddedDocument";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query allCollectionsQuery {
    allPrismicCollections {
      edges {
        node {
          id
          uid
          type
          data {
            title
            illustration {
              text
            }
            tags {
              tag
            }
            materials {
              material {
                document {
                  ... on PrismicLessons {
                    id
                    uid
                    type
                    data {
                      title
                      illustration {
                        text
                      }
                      cor_question {
                        document {
                          ... on PrismicCorQuestions {
                            uid
                            data {
                              color
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicAssessments {
                    id
                    uid
                    type
                    data {
                      title
                      illustration {
                        text
                      }
                      cor_question {
                        document {
                          ... on PrismicCorQuestions {
                            uid
                            data {
                              color
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ allPrismicCollections }) => {
  const collections = resolveDocumentEdges(allPrismicCollections.edges);

  const resolved = collections.map(item => ({
    ...item,
    materials: resolveEmbeddedDocument(item.materials).map(
      ({ cor_question, ...rest }) => ({
        ...rest,
        corQuestion: resolveEmbeddedDocument(cor_question),
      })
    ),
  }));

  return keysToCamel(resolved);
};

const useAllCollectionsData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useAllCollectionsData;
