import { useEffect } from "react";
import PropTypes from "prop-types";
import { t, setTranslations } from "@translations";

const Lang = ({ currentPath }) => {
  setTranslations("en-us");

  useEffect(() => {
    // TODO: when new langs are rolled out, we'll
    // use this spot to parse the url and determine
    // the current language

    // For now, hard-coding English
    const lang = "en-us";

    if (t.name !== lang) {
      setTranslations(lang);
    }
  }, [currentPath]);

  return null;
};

Lang.propTypes = {
  currentPath: PropTypes.string,
};

Lang.defaultProps = {
  currentPath: undefined,
};

export default Lang;
