import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveDocumentEdges from "../dataResolvers/resolveDocumentEdges";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveImageData from "../dataResolvers/resolveImageData";

const query = graphql`
  query AllBlogPostsQuery {
    allPrismicBlogPosts(sort: { fields: data___display_date, order: DESC }) {
      edges {
        node {
          uid
          data {
            title
            display_date(formatString: "MMM DD, YYYY")
            summary {
              html
            }
            categories {
              category
            }
            image_group {
              focal_point_x
              focal_point_y
              image {
                url
                dimensions {
                  width
                  height
                }
                url
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ allPrismicBlogPosts }) => {
  const posts = resolveDocumentEdges(allPrismicBlogPosts.edges);

  const resolved = {
    posts: posts.map(({ image_group, categories, display_date, ...rest }) => ({
      ...rest,
      date: display_date,
      categories: categories.map(({ category }) => ({
        title: category.toLowerCase(),
        id: category
          .toLowerCase()
          .split(" ")
          .join("-"),
      })),
      image: resolveImageData(image_group[0]),
    })),
  };

  return keysToCamel(resolved);
};

const useAllBlogPostsData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useAllBlogPostsData;
