import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Helmet } from "react-helmet";

import Maintenance from "@organisms/Maintenance";
import GlobalNotification from "@organisms/GlobalNotification";
import useGlobalsData from "../../prismic/staticQueries/GlobalsQuery";
import getParam from "../../utils/getParam";
import Footer from "./Footer";

const DefaultLayout = ({ children }) => {
  const {
    globalAnnouncement,
    maintenanceMode: maintenanceModeContent,
  } = useGlobalsData();

  const [{ layout }] = useAppState();
  const [maintenanceMode, setMaintenanceMode] = useState(
    maintenanceModeContent.enabled
  );

  useEffect(() => {
    try {
      if ("caches" in window) {
        // eslint-disable-next-line func-names
        caches.keys().then(function(keyList) {
          return Promise.all(
            // eslint-disable-next-line func-names
            keyList.map(function(key) {
              return caches.delete(key);
            })
          );
        });
      }

      if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker
          .getRegistrations()
          // eslint-disable-next-line func-names
          .then(function(registrations) {
            // eslint-disable-next-line no-restricted-syntax
            for (const registration of registrations) {
              registration.unregister();
            }
          });
      }
      if (window.location.hostname === "cor.stanford.edu") {
        window.location.hostname = "cor.inquirygroup.org";
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    const mm = getParam("mm");
    if (mm === "true") {
      setMaintenanceMode(true);
    }
  }, []);

  return (
    <div className="relative bg-white lg:m-2">
      {!maintenanceMode && globalAnnouncement.enabled && (
        <GlobalNotification {...globalAnnouncement} key="globalNotification" />
      )}
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&family=Spectral:ital,wght@0,400;0,700;1,400;1,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {maintenanceMode && (
        <Maintenance
          key="maintenance"
          {...maintenanceModeContent}
          className={classNames({
            relative: true,
            "pb-12": layout.previewMode,
          })}
        >
          <div className="min-h-100vh">{children}</div>
          <Footer />
        </Maintenance>
      )}
      {!maintenanceMode && (
        <main
          key="main"
          className={classNames({
            relative: true,
            "pb-12": layout.previewMode,
          })}
        >
          <div className="min-h-100vh">{children}</div>
          <Footer />
        </main>
      )}
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
  }).isRequired,
};

export default DefaultLayout;
