import React from "react";
import PropTypes from "prop-types";
import Square from "@svg/square.svg";
import Triangle from "@svg/triangle.svg";
import Circle from "@svg/circle.svg";

const HeadingAccent = ({ className: _className }) => (
  <div className={`inline-flex items-center mb-6 ${_className}`}>
    <span className="text-blue-1" style={{ width: "9px", height: "9px" }}>
      <Circle />
    </span>
    <span className="ml-1 text-blue-1" style={{ width: "9px", height: "9px" }}>
      <Square />
    </span>
    <span className="ml-1 text-blue-1" style={{ width: "9px", height: "9px" }}>
      <Triangle />
    </span>
  </div>
);

HeadingAccent.propTypes = {
  className: PropTypes.string,
};

HeadingAccent.defaultProps = {
  className: "",
};

export default HeadingAccent;
