const reducer = (state, action) => {
  switch (action.type) {
    case "setRendered":
      return {
        ...state,
        rendered: action.rendered,
      };
    case "setPreviewMode":
      return {
        ...state,
        previewMode: action.previewMode,
      };
    case "setMobileNav":
      return {
        ...state,
        showingMobileNav: action.showingMobileNav,
      };

    default:
      return state;
  }
};

const initialState = {
  rendered: false,
  previewMode: false,
  showingMobileNav: false,
};

export default {
  reducer,
  initialState,
};
