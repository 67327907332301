import React from "react";
import { useAppState } from "@state/state";
import Message from "./Message";

const Messages = () => {
  const [{ messages }] = useAppState();

  return (
    <>
      {messages.messages.map((message, idx) => (
        <Message key={message.id} {...message} idx={idx} />
      ))}
    </>
  );
};

export default Messages;
