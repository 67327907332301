import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import qs from "query-string";
import { useAppState } from "@state/state";
import axios from "axios";
import CORLogo from "@svg/logo-footer.svg";
import { Text } from "@ui";
import { t } from "@translations";

// This component handles CAS Authentication redirects from the DIG Drupal site.
// It looks for a `ticket` query parameter, and if present, calls a Netlify
// function to validate that ticket. If valid, then it shows the logged-in state.
// If not valid, it shows an error message.

const cookies = new Cookies();

const AuthenticationCheck = ({ location }) => {
  const [madeRequest, setMadeRequest] = useState();
  const [loading, setLoading] = useState();
  const [, dispatch] = useAppState();
  const params = qs.parse(location.search);
  const { ticket } = params;

  const openMessage = (message, color) => {
    dispatch({
      type: "openMessage",
      copy: { html: `<p>${message}</p>` },
      bgColorClass: color,
      timeout: 5000,
    });
  };

  const handleError = error => {
    setLoading(false);
    // setting the error to window.value tells the parent window
    // to close this popup
    window.value = { error };
    // on large screens open the message in the same window
    // also calling onError in case we're on a small screen that
    // didn't open in a popup
    setTimeout(() => {
      openMessage(`Unable to login - ${error}`, "bg-red");
    }, 500);
  };

  const handleSuccess = response => {
    setLoading(false);
    const { ticketIsValid } = response.data;
    if (ticketIsValid) {
      // setting the ticket to window.value tells the parent window
      // to close this popup
      window.value = { ticket };
      // On large screens the parent window shows the success message after reading
      // the window.value we just set. But on small screens, there is no parent window
      // so triggering the success message in this window after a timeout in case
      // we're on a small screen. The timeout ensures the message doesn't also display
      // in the popup window on large screens.
      setTimeout(() => {
        dispatch({
          type: "toggleLogin",
          ticket,
          loggedIn: true,
        });
        openMessage("You have been successfully logged-in.", "bg-blue-5");
      }, 500);
    } else {
      handleError("Ticket not valid");
    }
  };

  // Don't make the request if we don't have a ticket, or if we've already
  // made the request in this session, or if we already have a login cookie,
  // which means the user is already logged-in.
  useEffect(() => {
    if (!ticket || madeRequest || cookies.get("COR_login_ticket")) {
      return;
    }

    // If the ticket equals `showRegistrationForm` then pop open the register modal.
    // But if we're on the authCallback page, then set `window.value`, which will
    // close this window and tell the parent window to pop open the register modal.
    // See `OauthPopup` code in AuthButton for how using window.value works.
    if (ticket === "showRegistrationForm") {
      if (location.pathname === "/AuthCallback/") {
        window.value = { ticket: "showRegistrationForm" };
      } else {
        dispatch({
          type: "openModal",
          name: "registerModal",
          tabs: [{ title: t("Create new account"), name: "registerModal" }],
        });
      }
      return;
    }

    setMadeRequest(true);
    setLoading(true);
    axios
      .post("/.netlify/functions/validate-cas-ticket", {
        ticket,
        path: location.pathname,
      })
      .then(handleSuccess)
      .catch(handleError);
  }, [ticket]);

  if (loading) {
    return (
      <div className="fixed inset-0 flex flex-col justify-center items-center bg-blue z-max">
        <div className="mb-6">
          <CORLogo />
        </div>
        <Text preset="label" className="text-white capitalize">
          {t("authenticating")}...
        </Text>
      </div>
    );
  }

  return null;
};

AuthenticationCheck.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default AuthenticationCheck;
