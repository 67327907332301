import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveDocumentEdges from "../dataResolvers/resolveDocumentEdges";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query AllContentTopicsQuery {
    allPrismicContentTopics {
      edges {
        node {
          uid
          data {
            name
          }
        }
      }
    }
  }
`;

const dataResolver = ({ allPrismicContentTopics }) => {
  const resolved = {
    topics: resolveDocumentEdges(allPrismicContentTopics.edges),
  };

  return keysToCamel(resolved);
};

const useAllContentTopicData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useAllContentTopicData;
