import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveDocumentEdges from "../dataResolvers/resolveDocumentEdges";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveImageData from "../dataResolvers/resolveImageData";

const query = graphql`
  query AllResearchArticlesQuery {
    allPrismicResearchArticles(
      sort: { fields: data___display_date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title
            image {
              url
              dimensions {
                width
                height
              }
              alt
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ allPrismicResearchArticles }) => {
  const articles = resolveDocumentEdges(allPrismicResearchArticles.edges);

  const resolved = {
    articles: articles.map(({ image, ...rest }) => ({
      ...rest,
      image: resolveImageData({ image }),
    })),
  };

  return keysToCamel(resolved);
};

const useAllResearchArticlesData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useAllResearchArticlesData;
