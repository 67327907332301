import React from "react";
import PropTypes from "prop-types";

// Update these presets with project typography rules
const presets = {
  h1: "font-sans font-bold text-3xl md:text-4xl leading-clamped tracking-tight",
  h2: "font-sans font-bold text-2xl md:text-3xl leading-tight tracking-tight",
  h3: {
    default: "font-sans font-bold text-xl leading-firm",
    lg: "font-sans font-bold text-2xl leading-firm",
  },
  h4: "font-sans font-semibold text-sm leading-wiggly",
  p: "font-serif leading-firm opacity-90",
  button: {
    default: "font-sans font-bold text-sm",
    sm: "font-sans font-bold text-xs",
    xs: "font-sans font-bold text-xxs",
  },
  label: {
    default: "font-sans text-xs",
    sm: "font-sans text-xxs",
  },
};

const Text = ({ tag, preset, variant, className: _className, children }) => {
  const Tag = tag || preset;

  let presetClassName;
  const hasVariants = typeof presets[preset] === "object";
  if (hasVariants) {
    if (presets[preset][variant]) {
      presetClassName = presets[preset][variant];
    } else {
      presetClassName = presets[preset].default;
    }
  } else {
    presetClassName = presets[preset];
  }

  return (
    <Tag className={`${presetClassName} ${_className} whitespace-pre-wrap`}>
      {children}
    </Tag>
  );
};

Text.propTypes = {
  preset: PropTypes.oneOf(Object.keys(presets)).isRequired,
  tag: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Text.defaultProps = {
  tag: null,
  variant: "",
  className: "",
};

export default Text;
