import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveImageData from "../dataResolvers/resolveImageData";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import resolveEmbeddedDocument from "../dataResolvers/resolveEmbeddedDocument";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query CurriculumIndexQuery {
    prismicCurriculumIndex {
      data {
        meta_title
        meta_description
        meta_image {
          filter
          focal_point_x
          focal_point_y
          image {
            alt
            url
            dimensions {
              height
              width
            }
          }
        }
        hero_heading
        hero_description {
          html
        }
        collections_cta_text
        collections_description {
          html
        }
        collections_heading
        collections_featured {
          collection {
            document {
              ... on PrismicCollections {
                id
                uid
                type
                data {
                  title
                  illustration {
                    text
                  }
                }
              }
            }
          }
        }
        filter_lessons_assessments {
          filter_cor_question
          filter_disciplines
          searchable
        }
        filter_collections {
          filter_cor_question
          filter_disciplines
          searchable
        }
        filter_cor_question_prompt
        filter_cor_questions {
          cor_question {
            document {
              ... on PrismicCorQuestions {
                id
                type
                data {
                  title
                  color
                }
              }
            }
          }
        }
        filter_disciplines {
          discipline {
            document {
              ... on PrismicDisciplines {
                id
                type
                data {
                  name
                }
              }
            }
          }
        }
        filter_null_results_message {
          html
        }
        register_prompt
        register_cta_text
      }
    }
  }
`;

const dataResolver = ({ prismicCurriculumIndex }) => {
  const prefixes = ["meta_", "hero_", "collections_", "filter_", "register_"];

  const { prefixed, unPrefixed } = resolveByPrefix(
    prismicCurriculumIndex.data,
    prefixes
  );
  const { meta, collections, filter } = prefixed;
  const { cor_questions, disciplines } = filter;

  const resolved = {
    ...prefixed,
    ...unPrefixed,
    meta: {
      ...meta,
      image: resolveImageData(meta.image)[0],
    },
    collections: {
      ...collections,
      featuredCollections: resolveEmbeddedDocument(collections.featured),
    },
    filter: {
      ...filter,
      cor_questions: resolveEmbeddedDocument(cor_questions),
      disciplines: resolveEmbeddedDocument(disciplines),
    },
  };

  return keysToCamel(resolved);
};

const useCurriculumIndexData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useCurriculumIndexData;
