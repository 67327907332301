import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Seedrandom from "seedrandom";
import RandomShapes from "./RandomShapes";

const RandomShapesWrap = ({ color, opacity, seed, numShapes: _numShapes }) => {
  const [rendered, setRendered] = useState();
  const [size, setSize] = useState(0);
  const el = useRef({});

  useEffect(() => {
    const { clientWidth, clientHeight } = el.current;
    if (clientWidth && clientHeight) {
      setSize(clientWidth * clientHeight);
    }
  });

  const dist = 25 / (1000 * 1000);
  const numShapes = _numShapes || Math.floor(size * dist);

  // create random numbers from seed
  const rn = new Seedrandom(seed);

  useEffect(() => setRendered(true));

  return (
    <div
      ref={el}
      className="RandomShapes absolute inset-0 overflow-visible"
      style={{ opacity }}
    >
      {rendered > 0 && numShapes > 1 && (
        <RandomShapes color={color} rn={rn} numShapes={numShapes} />
      )}
    </div>
  );
};

RandomShapesWrap.defaultProps = {
  seed: 9,
  color: "white",
  opacity: 0.1,
  numShapes: undefined,
};

RandomShapesWrap.propTypes = {
  seed: PropTypes.number,
  color: PropTypes.string,
  opacity: PropTypes.number,
  numShapes: PropTypes.number,
};

export default React.memo(RandomShapesWrap);
