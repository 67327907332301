import React, { useState } from "react";
import { useAppState } from "@state/state";
import { Text, Icon, Button, RichText } from "@ui";
import { useAuthFormsData } from "@staticQueries";
import Input from "./Input";
import { t } from "@translations";

const ResetPasswordForm = () => {
  const {
    resetPasswordHeading,
    resetPasswordSuccessMessage,
    footerNote,
  } = useAuthFormsData();
  const [{ modal }, dispatch] = useAppState();
  const [formData, setFormData] = useState({ email: "" });
  const [loading, setLoading] = useState(false);

  const handleFormSubmission = event => {
    event.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      dispatch({ type: "closeModal" });
      setTimeout(() => {
        dispatch({ type: "clearModal" });
      }, 300);
      dispatch({
        type: "openMessage",
        copy: resetPasswordSuccessMessage,
        bgColorClass: "bg-green",
        timeout: 7000,
      });
    }, 1000);
  };

  return (
    <form id="signin-form" onSubmit={handleFormSubmission}>
      <div className="flex items-center mb-6">
        <Icon className="w-12 h-12 text-red-dig mr-4 p-1" name="DIG" />
        <Text preset="h2" className="flex-1">
          {resetPasswordHeading}
        </Text>
      </div>

      <div className="max-w-sm mb-8">
        <Input
          name="email"
          label={t("email")}
          type="email"
          placeholder="text"
          helpText={t(
            "Password reset instructions will be sent to your registered email address"
          )}
          value={formData.email}
          isRequired
          onChange={event =>
            setFormData({ ...formData, email: event.target.value })
          }
          preset="gray"
          className="mb-4"
        />
      </div>

      <div className="flex mb-8">
        <Button
          type="submit"
          preset="blue"
          text={t("submit")}
          className="w-32"
        />
        <Button
          preset="empty"
          text={t("back")}
          className="text-blue ml-8"
          onClick={() =>
            dispatch({
              type: "openModal",
              name: "signInModal",
              tabs: modal.tabs,
            })
          }
        />
      </div>

      <RichText
        html={footerNote.html}
        className="RichText RichText--legaleze max-w-sm"
      />
    </form>
  );
};

export default ResetPasswordForm;
