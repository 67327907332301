import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "@ui";

const Checkbox = ({
  name,
  label,
  checked,
  onChange,
  className: _className,
}) => {
  const className = classNames(
    {
      "relative w-full flex items-center": true,
    },
    _className
  );

  const handleChange = event => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={`Checkbox ${className}`}>
      <div className="border-2 border-gray-2 w-6 h-6 focus:border-gray p-1">
        <input
          id={name}
          type="checkbox"
          name={name}
          checked={checked}
          onChange={handleChange}
          className="appearance-none cursor-pointer outline-none w-full h-full"
        />
      </div>

      {checked && (
        <div className="absolute w-6 h-6 top-0 left-0 flex items-center justify-center pointer-events-none">
          <Icon
            name="x"
            className="w-3 h-3 text-gray"
            style={{ width: "14px", height: "14px" }}
          />
        </div>
      )}

      <label
        htmlFor={name}
        className="block font-sans font-bold text-current text-sm cursor-pointer pl-2"
      >
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  onChange: null,
  className: "",
};

export default Checkbox;
