import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query Page404Query {
    prismic404Page {
      data {
        hero_heading
        hero_description {
          html
        }
        cta_link {
          url
          target
        }
        cta_text
        search_description {
          html
        }
        search_heading
      }
    }
  }
`;

const dataResolver = ({ prismic404Page }) => {
  const prefixes = ["hero_", "cta_", "search_"];

  const { prefixed, unPrefixed } = resolveByPrefix(
    prismic404Page.data,
    prefixes
  );

  const resolved = {
    ...prefixed,
    ...unPrefixed,
  };

  return keysToCamel(resolved);
};

const use404PageData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default use404PageData;
