export default {
  _name: "en-us",
  lesson: "lesson",
  lessons: "lessons",
  assessment: "assessment",
  assessments: "assessments",
  collection: "collection",
  access: "Access",
  "Found in these collections": "Found in these collections",
  "View the collection": "View the collection",
  "View collection": "View collection",
  "All lessons & assessments": "All lessons & assessments",
  delivery: "delivery",
  "COR Question": "COR Question",
  next: "next",
  view: "view",
  "Make a copy of assessment": "Make a copy of assessment",
  "View assessment": "View assessment",
  "View rubric": "View rubric",
  "all collections": "all collections",
  "lessons & assessments": "lessons & assessments",
  collections: "collections",
  "Search curriculum": "Search curriculum",
  cards: "cards",
  list: "list",
  "view more": "view more",
  go: "go",
  clear: "clear",
  "clear results": "clear results",
  "Sign in": "Sign in",
  "Sign up": "Sign up",
  "Sign in/up": "Sign in/up",
  logout: "logout",
  "Create new account": "Create new account",
  Select: "Select",
  close: "close",
  menu: "menu",
  username: "username",
  email: "email",
  "first name": "first name",
  "last name": "last name",
  role: "role",
  subject: "subject",
  level: "level",
  country: "country",
  state: "state",
  city: "city",
  "Subscribe to our newsetter": "Subscribe to our newsetter",
  "email or username": "email or username",
  "Enter your DIG email or username": "Enter your DIG email or username",
  password: "password",
  "confirm password": "confirm password",
  "Enter your DIG password": "Enter your DIG password",
  "reset password": "reset password",
  "Password reset instructions will be sent to your registered email address":
    "Password reset instructions will be sent to your registered email address",
  submit: "submit",
  back: "back",
  "view the assessment": "view the assessment",
  "view rubric": "view rubric",
  "learn more": "learn more",
  "watch the video": "watch the video",
  "Full Article Available at": "Full Article Available at",
  "Full Video Available at": "Full Video Available at",
  "Full Podcast Available at": "Full Podcast Available at",
  "read the post": "read the post",
  "read the article": "read the article",
  "view the research": "view the research",
  all: "all",
  "thought leadership": "thought leadership",
  updates: "updates",
  "teaching tips": "teaching tips",
  "Filter by category": "Filter by category",
  "Filter by topic": "Filter by topic",
  "all posts": "all posts",
  "all articles": "all articles",
  "all videos": "all videos",
  "related curriculum": "related curriculum",
  "Email us at": "Email us at",
  "Developed by": "Developed by",
  address: "address",
  "follow us": "follow us",
  By: "By",
  "Parallel Assessment": "Parallel Assessment",
  authenticating: "authenticating",
  "Passwords do not match": "Passwords do not match",
  "Level 1": "Level 1",
  "Level 2": "Level 2",
  "Email not valid": "Email not valid",
  Discipline: "Discipline",
  Disciplines: "Disciplines",
  "Please enter a valid email address": "Please enter a valid email address",
  "An account has already been created with this email address":
    "An account has already been created with this email address",
  "Click here to login": "Click here to login",
};
