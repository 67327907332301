const initialState = {
  name: null,
  tabs: [],
  context: {},
  showing: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "openModal":
      return {
        ...state,
        name: action.name,
        tabs: action.tabs || [],
        context: action.context || {},
        showing: true,
      };
    case "closeModal":
      return {
        ...state,
        showing: false,
      };
    case "clearModal":
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default {
  reducer,
  initialState,
};
