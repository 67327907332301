import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Button, AppLink, RandomShapes } from "@ui";
import { useHeaderData } from "@staticQueries";
import { t } from "@translations";

const firstSegment = path => {
  const idx = path.includes("http") ? 3 : 1;
  const split = path.split("/");
  return split[idx];
};

const Nav = ({ posTop, isScroller, currentPath }) => {
  const [rendered, setRendered] = useState();
  const [{ layout, search, theme, breakpoints }, dispatch] = useAppState();
  const { lg } = breakpoints;
  const { menu } = useHeaderData();

  // set some color/position classes based on the current theme
  const { bgColor: _bgColor } = theme.header;
  const bgColor = _bgColor === "transparent" ? "blue" : _bgColor;
  const textColor =
    bgColor === "white" || bgColor === "yellow" ? "text-gray" : "text-white";

  const goToSearch = () => {
    dispatch({
      type: "setFilters",
      filters: {
        ...search.filters,
        type: "singles",
      },
    });
    navigate("curriculum#search-top");
  };

  useEffect(() => {
    if (currentPath) {
      dispatch({ type: "setMobileNav", showingMobileNav: false });
    }
  }, [currentPath]);

  useEffect(() => setRendered(true));
  if (!rendered) {
    return null;
  }

  if (isScroller && !lg) {
    return null;
  }

  return (
    <nav
      className={classNames({
        "z-penult lg:z-10 flex": true,
        [`bg-${bgColor} ${textColor} fixed inset-0 flex-col items-center justify-center transition`]: true,
        "lg:bg-transparent lg:static lg:flex-row lg:flex-1 lg:mr-auto lg:justify-start": true,
        "opacity-0 pointer-events-none scale-90": !layout.showingMobileNav,
        "opacity-100 pointer-events-auto scale-100": layout.showingMobileNav,
        "lg:opacity-100 lg:pointer-events-auto lg:scale-100": true,
      })}
      style={{ top: lg ? "auto" : `${posTop}px` }}
    >
      <div className="absolute inset-0 lg:hidden">
        <RandomShapes
          seed={18}
          opacity={bgColor === "yellow" ? 0.2 : undefined}
        />
      </div>

      {menu.map(({ id, url, title, showOnDesktop }) => (
        <AppLink
          key={id}
          to={`${url}`}
          className={classNames({
            "relative z-10 mb-4 lg:mb-0": true,
            "lg:hidden": !showOnDesktop,
          })}
        >
          <span
            className={classNames({
              [`font-sans font-bold text-sm py-1 px-2 lg:mr-1 rounded-sm transition hover:bg-${bgColor}-4 text-lg lg:text-sm`]: true,
              [`bg-${bgColor}-4`]:
                firstSegment(currentPath) === firstSegment(url),
            })}
          >
            {title}
          </span>
        </AppLink>
      ))}

      <Button
        size="sm"
        preset="bordered"
        onClick={goToSearch}
        iconName="magnifyingGlass"
        iconClassName="w-3 h-3"
        text={t("Search curriculum")}
        className={`absolute bottom-6 pos-center-x z-10 lg:hidden ${textColor}`}
      />
    </nav>
  );
};

Nav.propTypes = {
  posTop: PropTypes.number.isRequired,
  isScroller: PropTypes.bool.isRequired,
  currentPath: PropTypes.string,
};

Nav.defaultProps = {
  currentPath: false,
};

export default Nav;
