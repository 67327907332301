const resolveEmbeddedDocument = obj => {
  if (Array.isArray(obj)) {
    // first filter out any empty docs
    const nonEmpty = obj.filter(item => {
      const firstKey = Object.keys(item)[0];
      return item[firstKey].document;
    });

    // then map them recursively
    return nonEmpty.map(item => {
      const firstKey = Object.keys(item)[0];
      return resolveEmbeddedDocument(item[firstKey]);
    });
  }

  if (!obj.document) {
    return null;
  }

  const { id, uid, type, data } = obj.document || {};
  return {
    id,
    uid,
    type,
    ...data,
  };
};

export default resolveEmbeddedDocument;
