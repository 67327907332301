import React, { useEffect } from "react";
import PropTypes from "prop-types";

// FormSlider - horizontal slider for multi-step forms

const FormSlider = ({ currentSlide, children, scrollableId }) => {
  const slides = React.Children.map(children, (child, idx) =>
    React.cloneElement(child, {
      className: `${child.className} transition`,
      style: {
        ...child.props.style,
        width: `${100 / children.length}%`,
        opacity: idx === currentSlide ? 1 : 0,
      },
    })
  );

  useEffect(() => {
    const el = scrollableId ? document.getElementById(scrollableId) : null;
    if (el) {
      el.scrollTop = 0;
    }
  }, [currentSlide]);

  return (
    <div
      className="flex transition-md-expo"
      style={{
        width: `${children.length * 100}%`,
        transform: `translateX(${currentSlide * -(100 / children.length)}%)`,
      }}
    >
      {slides}
    </div>
  );
};

FormSlider.propTypes = {
  currentSlide: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  scrollableId: PropTypes.string,
};

FormSlider.defaultProps = {
  scrollableId: null,
};

export default FormSlider;
