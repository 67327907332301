import { useStaticQuery, graphql } from "gatsby";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import { keysToCamel } from "@utils";

const query = graphql`
  query DownloadsQuery {
    prismicGlobals {
      data {
        downloads_logged_in_heading
        downloads_logged_in_description_single {
          html
        }
        downloads_logged_in_description_multi {
          html
        }
        downloads_logged_out_heading
        downloads_logged_out_description {
          html
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { prefixed } = resolveByPrefix(prismicGlobals.data, ["downloads_"]);
  return keysToCamel(prefixed.downloads);
};

const useDownloadsData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useDownloadsData;
