/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import LockupSmall from "@svg/lockup-small.svg";
import { Button, Icon, Text, RichText } from "@ui";

const GlobalNotification = ({ heading, copy, buttonText }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div
      className={clsx(
        "fixed z-max inset-0 bg-blue-screen-full transition p-12",
        {
          "pointer-events-none opacity-0": !open,
        }
      )}
    >
      <div
        className={clsx(
          "relative bg-white shadow text-center p-12 rounded flex flex-col items-center justify-center mt-20 mx-auto max-w-md transition-fast",
          { "scale-0": !open }
        )}
      >
        <LockupSmall className="w-24 h-auto mb-6 lockup-blue" />
        <Text preset="h2" className="mb-2">
          {heading}
        </Text>
        <Text preset="p" className="mb-6">
          <RichText html={copy} />
        </Text>
        <Button text={buttonText || "Dismiss"} onClick={() => setOpen(false)} />
        <button
          type="button"
          className="absolute top-0 right-0 w-10 h-10 sm:w-12 sm:h-12 z-20 outline-none focus:outline-none"
          onClick={() => setOpen(false)}
        >
          <Icon
            name="close"
            className="w-full h-full text-white hover:text-gray-1 transition"
          />
        </button>
      </div>
    </div>
  );
};

export default GlobalNotification;
