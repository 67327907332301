import React, { useEffect } from "react";
import PropTypes from "prop-types";
import lottie from "lottie-web";

const SVGAnimation = ({ id, json, loopAt, onLoaded, className }) => {
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: document.getElementById(id),
      renderer: "svg",
      loop: !loopAt,
      autoplay: true,
      animationData: JSON.parse(json),
    });

    if (loopAt) {
      animation.addEventListener("complete", () => {
        animation.goToAndPlay(loopAt, true);
      });
    }

    animation.addEventListener("DOMLoaded", onLoaded);
  }, []);

  return <div id={id} className={className} />;
};

SVGAnimation.propTypes = {
  id: PropTypes.string.isRequired,
  json: PropTypes.string.isRequired,
  loopAt: PropTypes.number,
  onLoaded: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SVGAnimation.defaultProps = {
  loopAt: null,
  className: "",
};

export default SVGAnimation;
