import React, { useEffect } from "react";
import { useAppState } from "@state/state";
import Cookies from "universal-cookie";
import { useAnnouncementData } from "@staticQueries";

const cookies = new Cookies();
const hiddenRoutes = ["Preview", "AuthCallback"];

const Announcement = () => {
  const [, dispatch] = useAppState();
  const { currentAnnouncement } = useAnnouncementData();
  const { id, copy = {}, ctaLink, ctaText } = currentAnnouncement || {};

  const wasClosed = cookies.get("COR_announcement") === id;

  useEffect(() => {
    const isHiddenRoute =
      hiddenRoutes.filter(route => window.location.pathname.includes(route))
        .length > 0;

    if (copy.html && !wasClosed && !isHiddenRoute) {
      dispatch({
        type: "openMessage",
        copy,
        ctaLink,
        ctaText,
        isStatic: true,
        onClose: () =>
          cookies.set("COR_announcement", id, {
            maxAge: 60 * 60 * 24 * 7,
            path: "/",
          }),
      });
    }
  }, [copy.html]);

  return null;
};

export default React.memo(Announcement);
