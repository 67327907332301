import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import VideoPlay from "@svg/video-play.svg";
import { Image, imageProps } from "@ui";

const VideoThumbnail = ({ title, thumbnail, youtubeVideoId, className }) => {
  const [{ breakpoints }, dispatch] = useAppState();
  const { sm } = breakpoints;

  if (thumbnail) {
    return (
      <div className={className}>
        <button
          type="button"
          onClick={() =>
            dispatch({
              type: "openModal",
              name: "videoEmbed",
              context: {
                title,
                youtubeVideoId,
              },
            })
          }
          className="relative block w-full group outline-none focus:outline-none"
          style={{ paddingBottom: sm ? "75%" : `${(9 / 16) * 100}%` }}
        >
          <span className="absolute pos-center z-50 w-10 h-10 text-white group-hover:text-gray-1 transition">
            <VideoPlay />
          </span>
          <Image image={thumbnail} />
        </button>
      </div>
    );
  }

  return null;
};

VideoThumbnail.propTypes = {
  title: PropTypes.string.isRequired,
  youtubeVideoId: PropTypes.string.isRequired,
  thumbnail: imageProps.props,
  className: PropTypes.string,
};

VideoThumbnail.defaultProps = {
  thumbnail: null,
  className: "",
};

export default React.memo(VideoThumbnail);
