import en_us from "./en_us";

const translations = {};

export const setTranslations = lang => {
  switch (lang) {
    case "en-us":
      Object.assign(translations, en_us);
      break;
    default:
  }
};

export const t = key => {
  if (key === "_all") {
    return translations;
  }
  return translations[key] || "";
};

export default {
  t,
  setTranslations,
};
