import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import useWindowSize from "@designbycosmic/cosmic-react-resize-hook";
import { SignInForm, RegisterForm, ResetPasswordForm } from "@forms";
import { VideoEmbed } from "@common";

const Inner = ({ modalName, modalContext }) => {
  switch (modalName) {
    case "signInModal":
      return <SignInForm {...modalContext} />;
    case "registerModal":
      return <RegisterForm {...modalContext} />;
    case "resetPasswordForm":
      return <ResetPasswordForm {...modalContext} />;
    case "videoEmbed":
      return <VideoEmbed {...modalContext} />;
    default:
      return null;
  }
};

const ModalInner = ({ modalName, modalContext }) => {
  const [height, setHeight] = useState(0);
  const lastModalName = useRef({});
  const el = useRef({});
  const { innerWidth } = useWindowSize();

  useEffect(() => {
    if (!modalName) {
      return;
    }

    const newHeight = el.current.scrollHeight;
    setHeight(newHeight);
    lastModalName.current = modalName;
  }, [modalName, innerWidth]);

  const opacity = modalName === lastModalName.current ? 1 : 0;

  return (
    <div
      className={classNames({
        "relative transition-expo overflow-hidden": true,
        "mb-8": modalName !== "videoEmbed",
      })}
      style={{ minHeight: `${height}px`, opacity }}
    >
      <div ref={el} className="absolute top-0 left-0 right-0">
        <Inner modalName={modalName} modalContext={modalContext} />
      </div>
    </div>
  );
};

const propTypes = {
  modalName: PropTypes.string,
  modalContext: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
  modalName: null,
};

ModalInner.propTypes = propTypes;
ModalInner.defaultProps = defaultProps;
Inner.propTypes = propTypes;
Inner.defaultProps = defaultProps;

export default ModalInner;
