import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveDocumentEdges from "../dataResolvers/resolveDocumentEdges";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveImageData from "../dataResolvers/resolveImageData";

const query = graphql`
  query AllNewsArticlesQuery {
    allPrismicNewsArticles(sort: { fields: data___display_date, order: DESC }) {
      edges {
        node {
          uid
          data {
            title
            display_date(formatString: "MMM DD, YYYY")
            description {
              text
              html
            }
            image {
              url
              dimensions {
                width
                height
              }
              alt
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ allPrismicNewsArticles }) => {
  const articles = resolveDocumentEdges(allPrismicNewsArticles.edges);

  const resolved = {
    articles: articles.map(({ image, description, display_date, ...rest }) => ({
      ...rest,
      date: display_date,
      summary: {
        html: `<p>${description.text}</p>`,
      },
      image: resolveImageData({ image }),
    })),
  };

  return keysToCamel(resolved);
};

const useAllNewsArticlesData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useAllNewsArticlesData;
