import React from "react";
import PropTypes from "prop-types";

const VideoEmbed = ({ title, youtubeVideoId }) => {
  return (
    <div
      className="w-full relative"
      style={{ paddingBottom: `${(9 / 16) * 100}%` }}
    >
      <iframe
        title={title}
        className="absolute inset-0"
        width="100%"
        height="100%"
        src={`https://www.youtube-nocookie.com/embed/${youtubeVideoId}`}
        frameBorder="0"
        allowFullScreen
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  );
};

VideoEmbed.propTypes = {
  title: PropTypes.string.isRequired,
  youtubeVideoId: PropTypes.string.isRequired,
};

export default VideoEmbed;
