import PropTypes from "prop-types";

const props = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["lessons", "assessments", "collections"]).isRequired,
  illustration: PropTypes.shape({
    text: PropTypes.string,
  }),
  isPopular: PropTypes.bool,
  summary: PropTypes.shape({
    html: PropTypes.string,
  }),
  color: PropTypes.string,
  collectionUid: PropTypes.string,
  disableHoverStyle: PropTypes.bool,
  enableCardSelect: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
});

const defaults = {
  isPopular: false,
  color: "blue",
  collectionUid: null,
  illustration: {
    text: null,
  },
  tags: [],
  summary: {},
  disableHoverStyle: false,
  enableCardSelect: false,
  className: "",
  style: {},
};

export default {
  props,
  defaults,
};
