// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-page-templates-home-page-home-page-js": () => import("/opt/build/repo/src/pageTemplates/HomePage/HomePage.js" /* webpackChunkName: "component---src-page-templates-home-page-home-page-js" */),
  "component---src-page-templates-curriculum-index-js": () => import("/opt/build/repo/src/pageTemplates/CurriculumIndex.js" /* webpackChunkName: "component---src-page-templates-curriculum-index-js" */),
  "component---src-page-templates-about-page-about-page-js": () => import("/opt/build/repo/src/pageTemplates/AboutPage/AboutPage.js" /* webpackChunkName: "component---src-page-templates-about-page-about-page-js" */),
  "component---src-page-templates-contact-page-js": () => import("/opt/build/repo/src/pageTemplates/ContactPage.js" /* webpackChunkName: "component---src-page-templates-contact-page-js" */),
  "component---src-prismic-dynamic-queries-lesson-query-js": () => import("/opt/build/repo/src/prismic/dynamicQueries/LessonQuery.js" /* webpackChunkName: "component---src-prismic-dynamic-queries-lesson-query-js" */),
  "component---src-prismic-dynamic-queries-assessment-query-js": () => import("/opt/build/repo/src/prismic/dynamicQueries/AssessmentQuery.js" /* webpackChunkName: "component---src-prismic-dynamic-queries-assessment-query-js" */),
  "component---src-prismic-dynamic-queries-collection-query-js": () => import("/opt/build/repo/src/prismic/dynamicQueries/CollectionQuery.js" /* webpackChunkName: "component---src-prismic-dynamic-queries-collection-query-js" */),
  "component---src-prismic-dynamic-queries-blog-single-query-js": () => import("/opt/build/repo/src/prismic/dynamicQueries/BlogSingleQuery.js" /* webpackChunkName: "component---src-prismic-dynamic-queries-blog-single-query-js" */),
  "component---src-page-templates-blog-index-js": () => import("/opt/build/repo/src/pageTemplates/BlogIndex.js" /* webpackChunkName: "component---src-page-templates-blog-index-js" */),
  "component---src-page-templates-news-index-js": () => import("/opt/build/repo/src/pageTemplates/NewsIndex.js" /* webpackChunkName: "component---src-page-templates-news-index-js" */),
  "component---src-prismic-dynamic-queries-news-single-query-js": () => import("/opt/build/repo/src/prismic/dynamicQueries/NewsSingleQuery.js" /* webpackChunkName: "component---src-prismic-dynamic-queries-news-single-query-js" */),
  "component---src-prismic-dynamic-queries-video-single-query-js": () => import("/opt/build/repo/src/prismic/dynamicQueries/VideoSingleQuery.js" /* webpackChunkName: "component---src-prismic-dynamic-queries-video-single-query-js" */),
  "component---src-page-templates-video-index-js": () => import("/opt/build/repo/src/pageTemplates/VideoIndex.js" /* webpackChunkName: "component---src-page-templates-video-index-js" */),
  "component---src-page-templates-research-index-js": () => import("/opt/build/repo/src/pageTemplates/ResearchIndex.js" /* webpackChunkName: "component---src-page-templates-research-index-js" */),
  "component---src-prismic-dynamic-queries-research-single-query-js": () => import("/opt/build/repo/src/prismic/dynamicQueries/ResearchSingleQuery.js" /* webpackChunkName: "component---src-prismic-dynamic-queries-research-single-query-js" */),
  "component---src-prismic-dynamic-queries-page-query-js": () => import("/opt/build/repo/src/prismic/dynamicQueries/PageQuery.js" /* webpackChunkName: "component---src-prismic-dynamic-queries-page-query-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-callback-js": () => import("/opt/build/repo/src/pages/AuthCallback.js" /* webpackChunkName: "component---src-pages-auth-callback-js" */),
  "component---src-pages-preview-js": () => import("/opt/build/repo/src/pages/Preview.js" /* webpackChunkName: "component---src-pages-preview-js" */)
}

